import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../services/authService";
import { setUser, setToken, setProfile } from "../../store/slices/userSlice";
import axios from "axios";
import {
  AuthContainer,
  AuthForm,
  AuthInput,
  AuthButton,
  AuthLink,
  ErrorMessage,
  AuthImageContainer,
  AuthFormContainer,
  PhoneInputHint,
  PhoneInputGroup,
  AreaCodeSelect,
  BenefitsContainer,
} from "../styles/Auth.styled";
import AuthVideo from "../../components/AuthVideo";
import eyeSlash from "../../assets/images/eye-slash.svg";
import RegisterModal from "../../components/RegisterModal";
import { Link } from "react-router-dom";

// Function to record metrics
const recordMetric = async (event, data) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/internalMetrics/registration-metrics`,
      {
        event,
        data: {
          ...data,
          version: "B",
        },
      }
    );
  } catch (error) {
    console.error("Failed to record metric:", error);
  }
};

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    phoneNumber: "",
    platform: "shopify", // Default platform
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [areaCode, setAreaCode] = useState("+1");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Record registration attempt
    recordMetric("registration_attempt", {
      email: formData.email,
    });

    try {
      const { user, token, profile } = await register(
        formData.email,
        formData.password
      );

      dispatch(setUser(user));
      dispatch(setToken(token));
      if (profile) {
        dispatch(setProfile(profile));
      }

      // Record successful registration
      recordMetric("registration_success", {
        email: formData.email,
        userId: user.id,
      });

      navigate("/social-manager/stats?step=1");
    } catch (error) {
      console.error("Registration error:", error);

      // Handle Firebase specific errors
      if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else if (error.message) {
        setError(error.message);
      } else {
        setError("Registration failed. Please try again.");
      }

      //if error is already connection then show "Email already in use login?"
      if (error.message.includes("already in use")) {
        setError(
          <span>
            Email already in use. <Link to="/login">Login instead?</Link>
          </span>
        );
      }

      // Record registration failure
      recordMetric("registration_failure", {
        email: formData.email,
        error: error.message || "Unknown error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContainer $isRegister>
      <AuthFormContainer $isRegister>
        <RegisterModal />
        <BenefitsContainer>
          <h1>Money back Guarantee</h1>
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            <li>• Schedule Posting</li>
            <li>• 24/7 Customer Support</li>
            <li>• Rapid Ad Testing</li>
            <li>• Smart AI Data Analysis</li>
          </ul>
          <div className="benefits-footer">
            <p>Increase revenue in 90 days</p>
            <p>... or full refund</p>
          </div>
        </BenefitsContainer>
      </AuthFormContainer>
    </AuthContainer>
  );
};

{
  /* <AuthForm onSubmit={handleSubmit}>
  <div className="header">
    <h2>Create your account</h2>
    <h3>Please enter your details for creating account.</h3>
  </div>
  <div className="form-inputs">
    <div className="form-input">
      <p>Email</p>
      <AuthInput
        type="email"
        placeholder="Email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        required
      />
    </div>
    <div className="form-input">
      <p>Phone Number</p>
      <PhoneInputGroup>
        <AreaCodeSelect value={areaCode} onChange={handleAreaCodeChange}>
          {areaCodes.map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </AreaCodeSelect>
        <AuthInput
          type="tel"
          placeholder="(555) 555-5555"
          value={getDisplayPhoneNumber()}
          onChange={handlePhoneNumberChange}
          required
          style={{ flex: 1 }}
        />
      </PhoneInputGroup>
    </div>
    <div className="form-input">
      <p>Ecommerce Platform</p>
      <AreaCodeSelect
        value={formData.platform}
        onChange={(e) => setFormData({ ...formData, platform: e.target.value })}
        style={{ width: "100%" }}
      >
        {ecommercePlatforms.map((platform) => (
          <option key={platform.value} value={platform.value}>
            {platform.label}
          </option>
        ))}
      </AreaCodeSelect>
    </div>
    <div className="form-input">
      <p>Password</p>
      <div style={{ position: "relative" }}>
        <AuthInput
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          required
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          {showPassword ? "👁️" : <img src={eyeSlash} alt="show password" />}
        </button>
      </div>
    </div>
    <div className="form-input">
      <p>Confirm Password</p>
      <div style={{ position: "relative" }}>
        <AuthInput
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={(e) =>
            setFormData({
              ...formData,
              confirmPassword: e.target.value,
            })
          }
          required
        />
        <button
          type="button"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          {showConfirmPassword ? "👁️" : <img src={eyeSlash} alt="show password" />}
        </button>
      </div>
    </div>
  </div>
  {error && <ErrorMessage>{error}</ErrorMessage>}
  <AuthButton type="submit" disabled={loading}>
    {loading ? "Creating Account..." : "Register"}
  </AuthButton>
  <AuthLink to="/login">
    Already have an account?&nbsp;&nbsp;<span>Login</span>
  </AuthLink>
</AuthForm>; */
}

export default Register;
