import styled, { css } from "styled-components";
import theme from "./global/theme";
import { motion } from "framer-motion";

// Customization section
export const Section = styled(motion.div)`
  border-radius: 12px;
  background: ${theme.color.gray[200]};
  display: flex;
  width: 100%;
  padding: 28px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-sizing: border-box;
  max-width: 550px;
  height: fit-content;
`;

export const SectionTitle = styled.h2`
  font-size: ${theme.font.size.lg};
  color: ${theme.color.dark};
  margin-bottom: 24px;
`;

// Upload logo area
export const UploadArea = styled(motion.div)`
  border: 2px dashed ${theme.color.primary};
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  background: ${theme.color.gray[300]};
`;

export const UploadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease-in-out;
  margin-bottom: 12px;
  svg {
    width: 32px;
    height: 32px;
    color: ${theme.color.gray[500]};
  }

  &:hover {
    svg {
      color: ${theme.color.primary};
    }

    img {
      transition: 0.2s ease-in-out;
      box-shadow: 0px 0px 10px ${theme.color.gray[500]};
    }
  }
`;

export const UploadText = styled.p`
  color: ${theme.color.gray[600]};
  font-size: ${theme.font.size.sm};
  margin: 0;

  color: ${theme.color.dark};
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  ${(props) =>
    props.$isSmall &&
    css`
      color: ${theme.color.gray[500]};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    `}
`;

// Form elements
export const FormGroup = styled(motion.div)`
  margin-bottom: 15px;
  width: 100%;
`;

export const Input = styled(motion.input)`
  width: 100%;
  padding: 12px;
  border: 1px solid ${theme.color.gray[300]};
  border-radius: 6px;
  background: #e8eaef;
  box-sizing: border-box;

  color: #595959;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
  }
`;

export const ColorPicker = styled.div`
  width: 100%;
  padding: 12px;
  border: 1px solid ${theme.color.gray[300]};
  border-radius: ${theme.border.radiusSmall};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Toggle = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ToggleInput = styled.input`
  display: none;
`;

export const ToggleSlider = styled.div`
  width: 48px;
  height: 24px;
  background: ${(props) =>
    props.checked ? theme.color.primary : theme.color.gray[300]};
  border-radius: 12px;
  position: relative;
  transition: ${theme.transition.default};

  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    top: 2px;
    left: ${(props) => (props.checked ? "26px" : "2px")};
    transition: ${theme.transition.default};
  }
`;

export const SettingsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 350px;
  width: 100%;
`;

export const ColorInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  #brand-color-input-preview {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background: transparent;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: none;
      border-radius: 7px;
    }
    &::-moz-color-swatch {
      border: none;
      border-radius: 7px;
    }
  }
`;

export const ColorPreview = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  border: 1px solid #ddd;
`;

export const ColorInput = styled.input`
  padding: 8px;
  border: none;
  border-radius: 8px;

  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 40px;
  box-sizing: border-box;
  background: ${theme.color.gray[300]};

  &[type="color"] {
    width: 50px;
    height: 40px;
    padding: 0;
    cursor: pointer;
  }

  &[type="text"] {
    width: 100px;
  }
`;
