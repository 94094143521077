import styled, { keyframes } from "styled-components";
import theme from "../../pages/styles/global/theme";
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const SkeletonText = styled.div`
  height: 16px;
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(to right, #e8eaef 8%, #f3f4f6 18%, #e8eaef 33%);
  background-size: 2000px 100%;
  animation: ${shimmer} 2s linear infinite;
`;

export const SkeletonValue = styled(SkeletonText)`
  height: 24px;
  margin-top: 4px;
  width: 80%;
`;

export const BaseSkeletonBlock = styled.div`
  border-radius: 16px;
  background: ${theme.color.gray[100]};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: ${(props) => props.$width || "100%"};
`;

export const SkeletonSmallBlock = styled(BaseSkeletonBlock)`
  min-width: 240px;
  height: 140px;

  @media (max-width: 1200px) {
    width: ${(props) => props.$width || "calc(33.33% - 11px)"};
  }

  @media (max-width: 900px) {
    width: ${(props) => props.$width || "calc(50% - 8px)"};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SkeletonLargeBlock = styled(BaseSkeletonBlock)`
  height: 200px;
  width: ${(props) => props.$width || "100%"};

  @media (max-width: 900px) {
    width: ${(props) => props.$width || "100%"};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
