import { RegisterConnectBlockContainer } from "../pages/styles/Auth.styled";
import good from "../assets/images/good.svg";

const RegisterConnectBlock = (props) => {
  const {
    img_url,
    name,
    description,
    isOutlined,
    isConnected,
    handleConnect,
    isLoading,
    isComingSoon,
  } = props;

  return (
    <RegisterConnectBlockContainer
      onClick={isComingSoon ? null : handleConnect}
      disabled={isLoading || isComingSoon || isConnected}
    >
      <img src={img_url} alt={name} />

      <p id="name">{name === "Gmail" ? "Coming Soon" : name}</p>

      {isConnected && <img src={good} alt="good" />}
    </RegisterConnectBlockContainer>
  );
};

export default RegisterConnectBlock;
