import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../config/firebase";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { api } from "../services/authService";
import theme from "./styles/global/theme";
import { setUserDocument, logout } from "../store/slices/userSlice";
import { Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { showSuccess, showError } from "../utils/toast";
import {
  Header,
  Logo,
  FormGroup,
  ErrorMessage,
  SuccessMessage,
  MyAccountContainer,
  FormsContainer,
  FormContainer,
} from "./styles/MyAccount.styled";
import profileUser from "../assets/images/profile-user.svg";
import {
  H1,
  H2,
  H4,
  MainContainer,
  NavButton,
} from "./styles/global/main.style";
import { useLocation } from "react-router-dom";
import axios from "axios";

const MyAccount = () => {
  const dispatch = useDispatch();
  const { userDocument, loading: userLoading } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const [shopifyData, setShopifyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const location = useLocation();

  const [formData, setFormData] = useState({
    name: userDocument?.name || "",
    email: userDocument?.email || "",
    webshopUrl: userDocument?.shopifyShop || "",
    userName: userDocument?.userName || "",
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Wait for Firebase Auth to initialize
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const fetchShopifyData = async () => {
      try {
        const response = await api.get("/api/store-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.data) {
          setShopifyData(response.data);
        }
      } catch (error) {
        console.error("Error fetching Shopify data:", error);
        if (error.response?.status === 404) {
          setShopifyData(null);
        }
      }
    };

    fetchShopifyData();
  }, []);

  useEffect(() => {
    const checkAuth = () => {
      if (auth.currentUser || userDocument) {
        setAuthChecked(true);
      }
    };

    // Check immediately
    checkAuth();

    // Also set up a listener for auth state changes
    const unsubscribe = onAuthStateChanged(auth, checkAuth);

    return () => unsubscribe();
  }, [userDocument]);

  // Show loading state while checking auth
  if (!authChecked || userLoading) {
    return <div>Loading...</div>;
  }

  // If no user is found after auth check, redirect to login
  if (!auth.currentUser) {
    return <Navigate to="/login" />;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setProfileLoading(true);

    try {
      if (formData.email !== userDocument.email) {
        const user = auth.currentUser;
        if (user) {
          const credential = EmailAuthProvider.credential(
            user.email,
            passwordData.currentPassword
          );
          await reauthenticateWithCredential(user, credential);
        }
      }

      const response = await api.put("/api/user/profile", formData);
      showSuccess("Profile updated successfully");

      dispatch(setUserDocument(response.data));
      localStorage.setItem("userDocument", JSON.stringify(response.data));
    } catch (error) {
      showError(
        error.response?.data?.message ||
          "Failed to update profile. Please try again."
      );
    } finally {
      setProfileLoading(false);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setPasswordLoading(true);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("Please log in again to update your password");
      }

      if (passwordData.newPassword !== passwordData.confirmPassword) {
        throw new Error("New passwords do not match");
      }

      if (!passwordData.currentPassword) {
        throw new Error("Current password is required");
      }

      const credential = EmailAuthProvider.credential(
        currentUser.email,
        passwordData.currentPassword
      );

      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, passwordData.newPassword);

      showSuccess("Password updated successfully");
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("Password update error:", error);
      // Provide more user-friendly error messages
      if (error.code === "auth/wrong-password") {
        showError("Current password is incorrect");
      } else if (error.code === "auth/requires-recent-login") {
        showError("Please log out and log in again to update your password");
      } else {
        showError(error.message || "Failed to update password");
      }
    } finally {
      setPasswordLoading(false);
    }
  };

  const handleLogout = () => {
    // Clear localStorage metrics
    localStorage.removeItem("storeMetrics");
    dispatch(logout());
    navigate("/login");
  };

  const handleSubscription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/stripe/portal/create-portal-session`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.url) {
        window.open(response.data.url, "_blank");
      }
    } catch (error) {
      console.error("Failed to open subscription portal:", error);
      navigate("/subscription");
    }
  };

  const handleResetPassword = async (email) => {
    if (!email) {
      showError("Please enter your email address");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      showSuccess("Password reset email sent! Please check your inbox.");
    } catch (error) {
      showError("Failed to send reset email: " + error.message);
    }
  };

  return (
    <MainContainer>
      <div id="header">
        <H1>My Account</H1>
        <div id="buttons">
          <NavButton onClick={handleLogout}>Logout</NavButton>
          <NavButton
            onClick={handleSubscription}
            $isFilled={location.pathname === "/subscription"}
          >
            Subscription
          </NavButton>
        </div>
      </div>
      <MyAccountContainer>
        <Header>
          <div className="logo-container">
            <Logo src={profileUser} alt="Profile" />
            <div className="header-text">
              <H2>{formData.userName || formData.name || "User"}</H2>
              <H4>{formData.email}</H4>
            </div>
          </div>
          <NavButton
            type="button"
            onClick={() => handleResetPassword(formData.email)}
            style={{ marginTop: "10px" }}
          >
            Forgot Password?
          </NavButton>
        </Header>

        <FormsContainer>
          <FormContainer>
            <H2>Profile Information</H2>
            <FormGroup onSubmit={handleUpdateProfile}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <label>Username</label>
              <input
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
              />
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <label>Webshop URL</label>
              <input
                type="text"
                name="webshopUrl"
                value={formData.webshopUrl}
                onChange={handleInputChange}
              />
              <NavButton type="submit" $isFilled disabled={profileLoading}>
                {profileLoading ? "Updating..." : "Update Profile"}
              </NavButton>
            </FormGroup>
          </FormContainer>

          <FormContainer>
            <H2>Change Password</H2>
            <FormGroup onSubmit={handleUpdatePassword}>
              <label>Current Password</label>
              <input
                type="password"
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={handlePasswordChange}
              />
              <label>New Password</label>
              <input
                type="password"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
              />
              <label>Confirm New Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
              />
              <NavButton type="submit" $isFilled disabled={passwordLoading}>
                {passwordLoading ? "Updating..." : "Update Password"}
              </NavButton>
            </FormGroup>
          </FormContainer>
        </FormsContainer>
      </MyAccountContainer>
    </MainContainer>
  );
};

export default MyAccount;
