import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  CaptionInput,
  ModalFooter,
  DatePickerWrapper,
  SelectWrapper,
  SelectItem,
  PreviewSection,
  EditButton,
  ModalLeft,
  ModalRight,
  Button,
  Toggle,
  ToggleInput,
  ToggleSlider,
  SliderSection,
  PreviewEditButton,
  CloseButton,
} from "./styles/PostModal.styled";
import { H2, H4, NavButton } from "../pages/styles/global/main.style";
import { showError } from "../utils/toast";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import Instagram from "../assets/images/ig-logo.png";
import Facebook from "../assets/images/fb-logo.png";
import plusIcon from "../assets/images/plus-circle-icon.svg";
import editIconWhite from "../assets/images/edit-icon-white.svg";
import plusIconGreen from "../assets/images/plus-circle-icon-green.svg";
import { motion } from "framer-motion";
import {
  postModalSelectItemVariants,
  postModalCheckIconVariants,
  boostSectionVariants,
  boostSliderVariants,
  itemVariants as fadeInUpVariants,
} from "../pages/styles/global/framer-motion-variants";

import { Slider, TextField } from "@mui/material";
import clockIcon from "../assets/images/clock.svg";
import axios from "axios";
import { PostBlockButtonSmall } from "./styles/PostBlock.styled";
import MagicPen from "../assets/images/magicpen-mini.svg";
import Lottie from "lottie-react";
import processingAnimation from "../assets/lottie/processing.json";
import styled from "styled-components";
import {
  ProductSelect,
  SearchInput,
  SelectedProductContainer,
} from "./styles/TestAdModal.styled";
import editIcon from "../assets/images/edit-icon.svg";
import { Input } from "./styles/ChatBot.styled";

const LottieWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PostModal = ({
  open,
  onClose,
  onCancel,
  channelId,
  setChannelId,
  scheduledDate,
  setScheduledDate,
  caption,
  setCaption,
  postType,
  setPostType,
  onSubmit,
  previewUrl,
  onEdit,
  boost,
  setBoost,
  boostDisabled,
  days,
  setDays,
  budget,
  setBudget,
  renderDatePicker,
  renderPreview,
  isUploading,
  isPosting,
  selectedAdAccount,
  setSelectedAdAccount,
}) => {
  const userDocument = useSelector((state) => state.user.userDocument);
  const [scheduleClicked, setScheduleClicked] = useState(false);
  const [isGeneratingCaption, setIsGeneratingCaption] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productUrl, setProductUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Update product URL when selected product changes
  useEffect(() => {
    if (selectedProduct?.url) {
      setProductUrl(selectedProduct.url);
    }
  }, [selectedProduct]);

  // Prevent body scrolling behind the modal
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  // Gather connected channels from userDocument
  const getConnectedChannels = () => {
    const channels = [];

    if (userDocument?.facebookPages) {
      userDocument.facebookPages.forEach((page) => {
        channels.push({
          id: page.id,
          name: `${page.name}`,
          platform: "facebook",
        });
      });
    }

    if (userDocument?.instagramBusinessAccountId) {
      channels.push({
        id: userDocument.instagramBusinessAccountId,
        name: `${userDocument.instagramUsername}`,
        platform: "instagram",
      });
    }

    return channels;
  };

  const connectedChannels = getConnectedChannels();

  // Add product fetching
  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/products`,
        {
          params: {
            shop: userDocument.shopifyShop,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (open && userDocument?.shopifyShop) {
      fetchProducts();
    }
  }, [open, userDocument]);

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const parseHtmlContent = (html) => {
    if (!html) return "";
    const temp = document.createElement("div");
    temp.innerHTML = html;
    const text = temp.textContent || temp.innerText;
    return text
      .replace(/\s+/g, " ")
      .trim()
      .split(/\n+/)
      .filter((line) => line.trim())
      .join("\n");
  };

  const handleConfirm = () => {
    setIsLoading(true);
    if (!channelId) {
      showError("Please select a channel");
      return;
    }

    const requestPayload = {
      channelId,
      caption,
      scheduledDate:
        scheduledDate?.toISOString() ||
        new Date(Date.now() + 10 * 60 * 1000).toISOString(),
      platform: channelId.includes("instagram") ? "instagram" : "facebook",
      postType,
      isScheduled: !!scheduledDate,
      userStoreUrl: userDocument.shopifyShop,
      product: selectedProduct, // Keep this for other functionality
    };

    // Add designId if the URL contains it (from EditCreative.js)
    if (previewUrl && previewUrl.includes("editorPosts")) {
      requestPayload.designId = previewUrl.split("/").pop().split("?")[0];
    } else {
      // Add mediaUrl for direct uploads (from Stats.js)
      requestPayload.mediaUrl = previewUrl;
    }

    // Only add boost-related fields if boost is enabled
    if (boost) {
      requestPayload.boost = boost;
      requestPayload.boostDays = days;
      requestPayload.boostBudget = budget;
      requestPayload.adAccountId = selectedAdAccount;
      requestPayload.productUrl = productUrl;
    }

    console.log(
      "Sending request payload:",
      JSON.stringify(requestPayload, null, 2)
    );

    // Call back to handleSubmit in parent component
    onSubmit && onSubmit(requestPayload);
    setIsLoading(false);
  };

  const postTypes = [
    { id: "post", name: "Post" },
    { id: "story", name: "Story" },
  ];

  // Add AI caption generation handler
  const handleGenerateCaption = async () => {
    if (!previewUrl) {
      showError("Please add an image first");
      return;
    }

    try {
      setIsGeneratingCaption(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post/generate-caption`,
        { imageUrl: previewUrl },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.caption) {
        setCaption(response.data.caption);
      }
    } catch (error) {
      console.error("Error generating caption:", error);
      showError(
        error.response?.data?.details ||
          "Failed to generate caption. Please try again."
      );
    } finally {
      setIsGeneratingCaption(false);
    }
  };

  // Add ad accounts from userDocument
  const adAccounts = userDocument?.facebookAdAccounts || [];

  return (
    <AnimatePresence>
      {open && (
        <ModalOverlay
          variants={fadeInUpVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={onClose}
        >
          <ModalContent
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={(e) => e.stopPropagation()}
          >
            {(isUploading || isPosting) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(255, 255, 255, 0.9)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1000,
                }}
              >
                <Lottie
                  animationData={processingAnimation}
                  loop={true}
                  style={{ width: 100, height: 100 }}
                />
                <H4 style={{ marginTop: "20px" }}>
                  {isUploading ? "Uploading..." : "Creating post..."}
                </H4>
              </motion.div>
            )}
            {boost && (
              <ModalLeft>
                <H2>Product URL (Optional)</H2>
                <Input
                  fullWidth
                  type="url"
                  placeholder="Enter product URL"
                  value={productUrl}
                  onChange={(e) => setProductUrl(e.target.value)}
                  style={{ marginBottom: "20px" }}
                />
                <H2>Select Product</H2>
                {!selectedProduct ? (
                  <>
                    <SearchInput
                      type="text"
                      placeholder="Search products..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    <ProductSelect>
                      {filteredProducts.map((product) => (
                        <SelectItem
                          $isTestAd
                          variants={postModalSelectItemVariants}
                          initial="hidden"
                          whileHover="hover"
                          whileTap="tap"
                          animate={{
                            backgroundColor:
                              selectedProduct?.id === product.id
                                ? "#E8F5E9" 
                                : "#ffffff",
                            transition: { duration: 0.2 },
                          }}
                          selected={selectedProduct?.id === product.id}
                          key={product.id}
                          onClick={() => setSelectedProduct(product)}
                        >
                          <div className="select-item-container">
                            <img src={product.image.src} alt={product.title} />
                            <div className="product-info">
                              <span id="product-title">{product.title}</span>
                              <span id="product-price">
                                ${product.variants[0]?.price}
                              </span>
                            </div>
                          </div>

                          <motion.img
                            variants={postModalCheckIconVariants}
                            initial="hidden"
                            animate="visible"
                            custom={selectedProduct?.id === product.id}
                            src={
                              selectedProduct?.id === product.id
                                ? plusIconGreen
                                : plusIcon
                            }
                            alt="Check"
                          />
                        </SelectItem>
                      ))}
                      {filteredProducts.length === 0 && (
                        <SelectItem>No products found</SelectItem>
                      )}
                    </ProductSelect>
                  </>
                ) : (
                  <SelectedProductContainer>
                    <img
                      id="product-image"
                      src={selectedProduct?.image?.src}
                      alt={selectedProduct?.title}
                    />
                    <div className="product-title-price">
                      <span id="product-title">{selectedProduct?.title}</span>
                      <span id="product-price">
                        ${selectedProduct?.variants[0]?.price}
                      </span>
                    </div>
                    <span id="product-description">
                      {parseHtmlContent(selectedProduct?.body_html)}
                    </span>
                    {selectedProduct && (
                      <Button
                        id="switch-product-button"
                        secondary
                        onClick={() => setSelectedProduct(null)}
                      >
                        <img src={editIcon} alt="Switch Product" /> Switch
                        Product
                      </Button>
                    )}
                  </SelectedProductContainer>
                )}
                <H2>Select Ad Account</H2>
                <SelectWrapper>
                  {adAccounts.map((account) => (
                    <SelectItem
                      variants={postModalSelectItemVariants}
                      initial="hidden"
                      whileHover="hover"
                      whileTap="tap"
                      animate={{
                        backgroundColor:
                          selectedAdAccount === account.account_id
                            ? "#E8F5E9"
                            : "#ffffff",
                        transition: { duration: 0.2 },
                      }}
                      selected={selectedAdAccount === account.account_id}
                      key={account.id}
                      onClick={() => setSelectedAdAccount(account.account_id)}
                    >
                      <span>{account.name}</span>

                      <motion.img
                        variants={postModalCheckIconVariants}
                        initial="hidden"
                        animate="visible"
                        custom={selectedAdAccount === account.account_id}
                        src={
                          selectedAdAccount === account.account_id
                            ? plusIconGreen
                            : plusIcon
                        }
                        alt="Check"
                      />
                    </SelectItem>
                  ))}
                </SelectWrapper>
              </ModalLeft>
            )}
            <ModalLeft>
              <CloseButton onClick={onClose}>×</CloseButton>
              <H2>Select Channel</H2>
              <SelectWrapper>
                {connectedChannels.map((channel) => (
                  <SelectItem
                    variants={postModalSelectItemVariants}
                    initial="hidden"
                    whileHover="hover"
                    whileTap="tap"
                    animate={{
                      backgroundColor:
                        channelId === channel.id ? "#E8F5E9" : "#ffffff",
                      transition: { duration: 0.2 },
                    }}
                    selected={channelId === channel.id}
                    key={channel.id}
                    onClick={() => setChannelId(channel.id)}
                  >
                    <img
                      src={
                        channel.platform === "facebook" ? Facebook : Instagram
                      }
                      alt={channel.platform}
                    />
                    <span>{channel.name}</span>

                    <motion.img
                      variants={postModalCheckIconVariants}
                      initial="hidden"
                      animate="visible"
                      custom={channelId === channel.id}
                      src={channelId === channel.id ? plusIconGreen : plusIcon}
                      alt="Check"
                    />
                  </SelectItem>
                ))}
              </SelectWrapper>

              <H2>Select Post Type</H2>
              <SelectWrapper>
                {postTypes.map((type) => (
                  <SelectItem
                    variants={postModalSelectItemVariants}
                    initial="hidden"
                    whileHover="hover"
                    whileTap="tap"
                    animate={{
                      backgroundColor:
                        postType === type.id ? "#E8F5E9" : "#ffffff",
                      transition: { duration: 0.2 },
                    }}
                    selected={postType === type.id}
                    key={type.id}
                    onClick={() => setPostType(type.id)}
                  >
                    <span>{type.name}</span>

                    <motion.img
                      variants={postModalCheckIconVariants}
                      initial="hidden"
                      animate="visible"
                      custom={postType === type.id}
                      src={postType === type.id ? plusIconGreen : plusIcon}
                      alt="Check"
                    />
                  </SelectItem>
                ))}
              </SelectWrapper>

              <H2>Create a Caption</H2>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                <CaptionInput
                  placeholder="Write a caption..."
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                />
                <PostBlockButtonSmall
                  style={{
                    position: "absolute",
                    bottom: "36px",
                    right: "16px",
                  }}
                  onClick={handleGenerateCaption}
                  disabled={isGeneratingCaption}
                >
                  {isGeneratingCaption ? (
                    <LottieWrapper>
                      <Lottie
                        animationData={processingAnimation}
                        loop={true}
                        style={{
                          width: 20,
                          height: 20,
                          filter: "brightness(0.7)",
                        }}
                      />
                    </LottieWrapper>
                  ) : (
                    <img src={MagicPen} alt="Magic Pen" />
                  )}
                </PostBlockButtonSmall>
              </div>

              <Toggle>
                <H2>Boost Post</H2>
                <ToggleInput
                  type="checkbox"
                  checked={boost}
                  onChange={() => setBoost(!boost)}
                  id="boost-toggle"
                />
                <ToggleSlider htmlFor="boost-toggle" checked={boost} />
              </Toggle>

              <AnimatePresence mode="wait">
                {boost && (
                  <motion.div
                    variants={boostSectionVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="boost-section"
                  >
                    <motion.div
                      variants={boostSliderVariants}
                      className="boost-slider"
                    >
                      <SliderSection>
                        <div className="slider-label">
                          <H4>Days</H4>
                          <span className="slider-value">{days}</span>
                        </div>
                        <Slider
                          value={days}
                          onChange={(_, value) => setDays(value)}
                          min={0}
                          max={14}
                          marks={[
                            { value: 0, label: "0" },
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                            { value: 3, label: "3" },
                            { value: 4, label: "4" },
                            { value: 5, label: "5" },
                            { value: 6, label: "6" },
                            { value: 7, label: "7" },
                            { value: 8, label: "8" },
                            { value: 9, label: "9" },
                            { value: 10, label: "10" },
                            { value: 11, label: "11" },
                            { value: 12, label: "12" },
                            { value: 13, label: "13" },
                            { value: 14, label: "14" },
                          ]}
                          valueLabelDisplay="off"
                        />
                      </SliderSection>
                    </motion.div>

                    <motion.div
                      variants={boostSliderVariants}
                      className="boost-slider"
                    >
                      <SliderSection>
                        <div className="slider-label">
                          <H4>Budget</H4>
                          <span className="slider-value">${budget}</span>
                        </div>
                        <Slider
                          value={budget}
                          onChange={(_, value) => setBudget(value)}
                          min={0}
                          max={500}
                          step={1}
                          marks={[
                            { value: 0, label: "$0" },
                            { value: 100, label: "$100" },
                            { value: 200, label: "$200" },
                            { value: 300, label: "$300" },
                            { value: 400, label: "$400" },
                            { value: 500, label: "$500" },
                          ]}
                          valueLabelDisplay="off"
                        />
                      </SliderSection>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>

              <DatePickerWrapper>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Schedule Date & Time (optional)"
                    value={scheduledDate}
                    onChange={(newValue) => setScheduledDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </DatePickerWrapper>

              <div className="button-wrapper">
                <Button
                  primary
                  onClick={handleConfirm}
                  disabled={!channelId || isGeneratingCaption || isLoading}
                >
                  {scheduledDate ? (
                    <>
                      <img src={clockIcon} alt="Schedule" /> Schedule Post
                    </>
                  ) : isLoading ? (
                    <LottieWrapper>
                      <Lottie
                        animationData={processingAnimation}
                        loop={true}
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                    </LottieWrapper>
                  ) : (
                    "Post Now"
                  )}
                </Button>
              </div>
            </ModalLeft>
            <ModalRight>
              <PreviewSection>
                {renderPreview
                  ? renderPreview()
                  : previewUrl && (
                      <>
                        <img
                          id="preview-image"
                          src={previewUrl}
                          alt="Post preview"
                        />
                        {/* <PreviewEditButton alt="Edit" onClick={onEdit}>
                        <img src={editIconWhite} alt="Edit" />
                      </PreviewEditButton> */}
                      </>
                    )}
              </PreviewSection>
            </ModalRight>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default PostModal;
