import styled, { css } from "styled-components";
import theme from "../../pages/styles/global/theme";

// Main sidebar container
export const SidebarContainer = styled.div`
  width: fit-content;
  max-width: 270px;
  height: 100vh;
  background-color: ${theme.color.white};
  padding: 24px 16px;
  border-right: 1px solid ${theme.color.gray[200]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid #e2e2e2;
  background: #f3f4f6;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    transform: ${({ $isOpen }) =>
      $isOpen ? "translateX(0)" : "translateX(-100%)"};
    max-width: 280px;
    width: 75%;
    padding: 24px 16px;
  }
`;

// Mobile toggle button
export const MobileToggle = styled.button`
  display: none;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 1001;
  border: none;
  width: 24px;
  height: 60px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  background: ${theme.color.gray[400]};
  border-radius: 0 8px 8px 0;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 16px;
    height: 16px;
    color: white;
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0)")};
    transition: transform 0.3s ease;
  }

  &:hover {
    background: ${theme.color.primary};
    width: 28px;
  }
`;

// Overlay for mobile
export const MobileOverlay = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(2px);
    transition: all 0.3s ease;
    opacity: ${({ $isOpen }) => ($isOpen ? "1" : "0")};
  }
`;

export const SidebarHeader = styled.div`
  padding: 0 8px 24px 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.color.gray[200]};

  h1 {
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: ${theme.font.size.xl};
    font-weight: ${theme.font.weight.semibold};
    color: ${theme.color.dark};
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
    margin-top: 30px;
    h1 {
      margin: 0;
    }
  }
`;

// Navigation item
export const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: ${theme.border.radiusSmall};

  ${({ $isActive }) => css`
    background: ${$isActive ? theme.color.primary : "transparent"};

    img {
      width: 20px;
      height: 20px;
      opacity: ${$isActive ? 1 : 0.7};
      filter: ${$isActive ? "brightness(0) invert(1)" : "none"};
    }

    &:hover {
      background: ${$isActive ? theme.color.primary : theme.color.gray[200]};
      img {
        opacity: 1;
      }
    }
  `}
`;

// Nav item text
export const NavText = styled.span`
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${({ $isActive }) => css`
    color: ${$isActive ? theme.color.white : theme.color.gray[600]};
  `}
`;

export const TrialEndingBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin-top: auto;
  background: ${theme.color.gray[300]};

  .text {
    color: var(--eb-dark-sidebar, #525460);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  span {
    background: linear-gradient(91deg, #0385ff 32.84%, #025099 63.1%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;
