import { H2, H3, H4, NavButton } from "../pages/styles/global/main.style";
import {
  ConnectChannelDescription,
  ConnectChannelImage,
  ConnectChannelName,
  ConnectChannelsContainer,
} from "./styles/ConnectChannelsBlock.styled";

const ConnectChannelBlock = ({
  img_url,
  name,
  description,
  isOutlined,
  isConnected,
  handleConnect,
  isLoading,
  isComingSoon,
}) => {
  return (
    <ConnectChannelsContainer $isOutlined={isOutlined}>
      <ConnectChannelImage src={img_url} alt={name} />
      <ConnectChannelName>{name}</ConnectChannelName>
      <ConnectChannelDescription>{description}</ConnectChannelDescription>
      <NavButton
        style={{ background: isConnected && "#10B981" }}
        $isFilled={true}
        onClick={() => handleConnect(name)}
        $isDisabled={isLoading || isComingSoon}
        disabled={isLoading || isComingSoon}
      >
        {isLoading
          ? "Connecting..."
          : isComingSoon
          ? "Coming Soon"
          : isConnected
          ? "Reconnect " + name
          : "Connect " + name}
      </NavButton>
    </ConnectChannelsContainer>
  );
};

export default ConnectChannelBlock;
