import styled, { css } from "../../config/styled";
import { Link } from "react-router-dom";
import theme from "./global/theme";
import registerBg from "../../assets/images/register-bg.png";

export const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url(${registerBg}) no-repeat;
  background-position: center;
  background-size: 140%;
  overflow: hidden;
  position: relative;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.47);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 1024px) {
    background-size: 300%;
  }

  @media (max-width: 768px) {
    background: none;
    &::before {
      display: none;
    }
    height: fit-content;
  }

  ${({ $isRegister }) =>
    $isRegister &&
    css`
      @media (max-width: 768px) {
        height: fit-content;
        padding: 20px;
      }
    `}

  ${({ $isRegisterA }) =>
    $isRegisterA &&
    css`
      background: none;
      &::before {
        display: none;
      }
      padding: 40px 20px;
      align-items: flex-start;

      gap: 50px;

      justify-content: space-between;
    `}
`;

export const AuthFormContainer = styled.div`
  width: 50%;
  min-width: 375px;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: fit-content;
    gap: 20px;
    width: 100%;
    min-width: 0;
  }

  h4 {
    color: var(--Gray-v1, #595959);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;

    a {
      color: var(--Gray-v1, #595959);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-decoration-line: underline;
    }
  }
`;

export const RegisterCreativeContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 50%;
  border-radius: 12px;
  background: var(
    --eb-primary-gradient-v,
    linear-gradient(180deg, #0385ff 0%, #025099 100%)
  );
  position: relative;
  padding: 40px;
  overflow: hidden;

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
    pointer-events: none;
    z-index: 2;
    border-radius: 12px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    h1 {
      color: #fff;
      font-family: ${theme.font.fontFamilyPrimary};
      font-size: 38px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
      width: 100%;
    }

    h2 {
      color: #fff;
      font-family: ${theme.font.fontFamilyPrimary};
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    img {
      width: 1200px;
      margin-top: 30px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  background: linear-gradient(90deg, #3399ff 0%, #0066cc 31.49%);

  border-radius: 8px;

  color: #fff;

  width: 375px;

  padding: 24px;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    text-align: center;
  }
`;

export const AuthImageContainer = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${theme.color.gray[200]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthForm = styled.form`
  display: flex;
  max-width: 375px;
  padding: 17px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;

  border-radius: 8px;
  border: 0.5px solid var(--EB-Icon, #a3a3a3);
  background: #fff;

  .form-input {
    width: 100%;

    .connected-shopify {
      display: flex;
      padding: 12px 10px;
      gap: 10px;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
      }

      img {
        width: 25px;
        height: 25px;
      }

      border-radius: 8px;
      background: var(--EB-Background, #f3f4f6);
    }
  }

  h2 {
    color: ${theme.color.dark};
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    margin: 0;
  }

  h3 {
    color: ${theme.color.gray[600]};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 26px;
  }

  p {
    color: ${theme.color.dark};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: 6px;
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 26px;
  }

  .forgot-password {
    margin-top: 16px;
    transition: 0.2s;
    color: #535353;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: none;
    border: none;
    cursor: pointer;
    text-align: right;
    width: 100%;

    &:hover {
      filter: brightness(1.2);
    }
  }

  .channels-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .connected-channels-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 30vh;
    overflow-y: auto;
    margin-top: 20px;

    /* Customize scrollbar for webkit browsers */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #d1d5db;
      border-radius: 3px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #d1d5db transparent;
  }

  ${({ $isRegisterA }) =>
    $isRegisterA &&
    css`
      width: 80%;
      padding: 20px;
      max-width: 800px;
      text-align: start;
      border: none;

      @media (max-width: 768px) {
        width: 100%;
        padding: 0;
      }

      .header {
        align-items: flex-start;
        width: 100%;

        h1 {
          color: ${theme.color.dark};
          font-family: ${theme.font.fontFamilyPrimary};
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 30px;
          }
        }

        h2 {
          color: ${theme.color.gray[600]};
          font-family: ${theme.font.fontFamilyPrimary};
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        span {
          color: ${theme.color.primary};
          font-family: ${theme.font.fontFamilyPrimary};
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }

      .form-input {
        position: relative;

        .icon {
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          width: 35px;
          height: 35px;
          padding: 5.5px 5px 5.5px 6px;
          justify-content: center;
          align-items: center;
        }
      }
    `}
`;

export const ConnectedStore = styled.div`
  width: 100%;
  border-radius: 8px;
  background: var(--EB-Background, #f3f4f6);
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  color: var(--black, #1b1a1a);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  p {
    margin: 0;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const AuthInput = styled.input`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  transition: 0.2s;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1.154px solid rgba(18, 18, 18, 0.2);

  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
    box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.1);
  }

  &::placeholder {
    color: ${theme.color.gray[400]};
  }

  ${({ $isRegisterA }) =>
    $isRegisterA &&
    css`
      padding: 16px 16px 16px 45px;
    `}
`;

export const AuthButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: ${theme.color.primary};
  border: none;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;

  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${theme.color.info};
  }

  &:disabled {
    background: ${theme.color.gray[400]};
    cursor: not-allowed;
  }
`;

export const OtherOptionsContainer = styled.div`
  display: flex;

  gap: 10px;
  width: 100%;
`;

export const OtherOptionsButton = styled.button`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: solid 1px ${theme.color.gray[400]};
  background: #fff;
  transition: 0.2s;

  p {
    margin: 0;
  }

  &:hover {
    background: ${theme.color.gray[100]};
    border-color: ${theme.color.gray[400]};
    cursor: pointer;
  }
`;

export const OrSeparator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  gap: 10px;

  p {
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.gray[400]};

    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }

  .separator {
    width: 100%;
    height: 1px;
    background: ${theme.color.gray[400]};
  }
`;

export const AuthLink = styled(Link)`
  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  text-decoration: none;
  display: flex;

  margin-top: 16px;

  span {
    color: #535353;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    transition: 0.2s;
  }

  &:hover {
    span {
      color: ${theme.color.gray[600]};
      filter: brightness(1.2);
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${theme.color.danger};
  margin-bottom: ${theme.spacing.md};
  text-align: left;
  font-size: ${theme.font.size.sm};
  padding: ${theme.spacing.xs};
  background-color: rgba(239, 68, 68, 0.1);
  border-radius: 8px;
`;

export const Label = styled.span`
  color: ${theme.color.black};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const PhoneInputHint = styled.span`
  color: ${theme.color.gray[500]};
  font-size: 12px;
  margin-top: 4px;
  display: block;
  font-family: Poppins;
`;

export const PhoneInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

export const AreaCodeSelect = styled.select`
  height: 48px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1.154px solid rgba(18, 18, 18, 0.2);
  background: white;
  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-size: 14px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
    box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.1);
  }
`;

export const AuthHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 26px;
  width: 100%;
  text-align: center;

  h1 {
    margin: 0;
    width: 100%;
    font-size: 20px;
  }

  h3 {
    margin: 0;
    width: 100%;
    font-size: 14px;
  }

  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 10px;

    #arrows {
      width: 15px;
      height: 15px;
      border: none;
      padding: 0;
    }

    img {
      width: 55px;
      height: 55px;
      border: 0.5px solid #a3a3a3;
      border-radius: 6px;
      padding: 2px;
    }
  }

  #stepper {
    width: 100%;
    color: ${theme.color.primary};
  }

  .MuiStepLabel-label {
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 157%;
    letter-spacing: 0.075px;
  }

  .MuiStepIcon-root.Mui-active {
    color: ${theme.color.primary};
  }

  ${({ $isRegister }) =>
    $isRegister &&
    css`
      margin-bottom: 0;
    `}
`;

export const RegisterConnectBlockContainer = styled.button`
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;

  width: 100%;
  border-radius: 8px;
  background: #f3f4f6;
  cursor: pointer;
  transition: 0.2s;
  border: none;

  #name {
    color: #1b1a1a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #e5e7eb;
  }

  &:disabled {
    cursor: not-allowed;
    background: #f3f4f6;
  }
`;

export const VideoContainer = styled.div`
  // Container for the video player and controls
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const VideoPlayer = styled.video`
  // Video player styling
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Controls = styled.div`
  // Controls container
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
`;

export const VolumeControl = styled.input`
  // Volume slider styling
  width: 100px;
  cursor: pointer;
`;
