import { PostsContainer } from "../pages/styles/Posts.styled";
import {
  PostDesignListContainer,
  PaginationContainer,
} from "./styles/PostDesignList.styled";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { animate } from "framer-motion";
import { Button } from "@blueprintjs/core";
import Lottie from "lottie-react";
import processingAnimation from "../assets/lottie/processing.json";

import PostBlock from "./PostBlock";

const PostDesignList = ({
  postDesigns,
  totalPages,
  currentPage,
  setCurrentPage,
  isLoading,
  fetchPostDesigns,
  onPost,
  onSchedule,
  onDelete,
}) => {
  const { userDocument } = useSelector((state) => state.user);

  useEffect(() => {
    fetchDesigns();
  }, [currentPage]);

  const fetchDesigns = async () => {
    if (userDocument) {
      fetchPostDesigns();
    }
  };

  const handlePageChange = (newPage) => {
    const listElement = document.querySelector(".post-design-list");
    if (listElement) {
      animate(listElement.scrollTop, 0, {
        duration: 0.3,
        onUpdate: (value) => listElement.scrollTo(0, value),
      });
    }

    setCurrentPage(newPage);
  };

  return (
    <PostDesignListContainer className="post-design-list">
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Lottie
            animationData={processingAnimation}
            loop={true}
            style={{ width: 100, height: 100 }}
          />
        </div>
      ) : (
        <>
          {postDesigns.map((design, index) => (
            <PostBlock
              key={design.id}
              post={design}
              index={index}
              onPost={() => onPost(design.id)}
              onSchedule={() => onSchedule(design.id)}
              onDelete={() => onDelete(design.id)}
              isPost={false}
            />
          ))}

          {postDesigns.length > 0 && totalPages > 1 && (
            <div
              className="pagination"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                style={{ borderRadius: "8px" }}
                icon="chevron-left"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              />

              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <Button
                    style={{ borderRadius: "8px", border: "none" }}
                    key={page}
                    active={currentPage === page}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Button>
                )
              )}

              <Button
                style={{ borderRadius: "8px" }}
                icon="chevron-right"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              />
            </div>
          )}

          {postDesigns.length === 0 && (
            <div
              style={{
                textAlign: "center",
                padding: "40px",
                color: "#666",
              }}
            >
              No drafts found
            </div>
          )}
        </>
      )}
    </PostDesignListContainer>
  );
};

export default PostDesignList;
