import styled, { keyframes } from "styled-components";
import theme from "./global/theme";
import { motion } from "framer-motion";

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const ContactBlock = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
  border-radius: 12px;
  background: ${theme.color.gray[300]};
  padding: 16px 20px;
  box-sizing: border-box;

  .name {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    color: ${theme.color.black};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    h3 {
      width: fit-content;
      margin: 0;
    }
  }

  .contact-details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .order-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  p {
    width: fit-content;
    color: ${theme.color.gray[700]};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .name-platform-icon {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const DropdownIcon = styled(motion.img)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const SkeletonBlock = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  height: 56px;
  border-radius: 12px;
  background: #e8eaef;
  padding: 16px 20px;
  box-sizing: border-box;
`;

export const SkeletonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const SkeletonIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(to right, #f0f0f0 8%, #f8f8f8 18%, #f0f0f0 33%);
  background-size: 2000px 100%;
  animation: ${shimmer} 2s linear infinite;
`;

export const SkeletonText = styled.div`
  height: ${(props) => props.$height || "16px"};
  width: ${(props) => props.$width || "200px"};
  border-radius: 4px;
  background: linear-gradient(to right, #f0f0f0 8%, #f8f8f8 18%, #f0f0f0 33%);
  background-size: 2000px 100%;
  animation: ${shimmer} 2s linear infinite;
`;
