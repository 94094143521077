import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ecommercebotVideo from "../assets/videos/ecommercebotVideo.mp4";
import theme from "../pages/styles/global/theme";

const VideoContainer = styled.div`
  width: 90%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${theme.shadow.boxShadow};
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const SoundButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 20px;
  transition: background 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const AuthVideo = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  useEffect(() => {
    let observer;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (!videoRef.current) return;

        try {
          if (entry.isIntersecting) {
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
              playPromise.catch((error) => {
                console.log("Video play error:", error);
              });
            }
          } else {
            if (videoRef.current.paused === false) {
              videoRef.current.pause();
            }
          }
        } catch (error) {
          console.log("Video control error:", error);
        }
      });
    };

    // Only set up observer if video element exists
    if (videoRef.current) {
      observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      });

      observer.observe(videoRef.current);
    }

    return () => {
      if (observer && videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <VideoContainer>
      <video
        ref={videoRef}
        playsInline
        loop
        muted={isMuted}
        preload="none"
        poster={ecommercebotVideo + "?frame=1"}
      >
        <source src={ecommercebotVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <SoundButton onClick={toggleSound}>{isMuted ? "🔇" : "🔊"}</SoundButton>
    </VideoContainer>
  );
};

export default AuthVideo;
