import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUser,
  setToken,
  setProfile,
  setUserDocument,
} from "../../store/slices/userSlice";
import { login } from "../../services/authService";
import {
  AuthContainer,
  AuthForm,
  AuthInput,
  AuthButton,
  AuthLink,
  ErrorMessage,
  AuthFormContainer,
  AuthImageContainer,
  Label,
} from "../styles/Auth.styled";
import { H1, H2, H3 } from "../styles/global/main.style";
import eyeSlash from "../../assets/images/eye-slash.svg";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase";
import AuthVideo from "../../components/AuthVideo";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const { user, token, profile } = await login(
        formData.email,
        formData.password
      );

      dispatch(setUser(user));
      dispatch(setToken(token));
      dispatch(setUserDocument(profile));

      // Send ID token to backend to create session cookie
      await axios.post("/api/auth/sessionLogin", { idToken: token });

      navigate("/dashboard");
    } catch (error) {
      setError(error.message || "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (email) => {
    if (!email) {
      setError("Please enter your email address");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent! Please check your inbox.");
    } catch (error) {
      setError("Failed to send reset email: " + error.message);
    }
  };

  return (
    <AuthContainer>
      <AuthFormContainer>
        <AuthForm onSubmit={handleSubmit}>
          <div className="header">
            <H1>Welcome back</H1>
          </div>

          <div className="form-input">
            <p>Email</p>
            <AuthInput
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required
            />
          </div>

          <div className="form-input">
            <p>Password</p>
            <div style={{ position: "relative" }}>
              <AuthInput
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword ? "👁️" : <img src={eyeSlash} />}
              </button>
            </div>
            <button
              type="button"
              onClick={() => handleResetPassword(formData.email)}
              className="forgot-password"
            >
              Forgot password?
            </button>
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <AuthButton type="submit" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </AuthButton>
          <AuthLink to="/register">
            Don't have an account?&nbsp;&nbsp;<span>Sign up</span>
          </AuthLink>
        </AuthForm>
      </AuthFormContainer>
    </AuthContainer>
  );
};

export default Login;
