import React from "react";
import { Navigate, Route } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ShopifyLogin from "../pages/auth/ShopifyLogin";
import Pricing from "../pages/Pricing";
import ConnectChannels from "../pages/ConnectChannels";
import MyAccount from "../pages/MyAccount";
import Inbox from "../pages/inbox/Inbox";
import InboxSettings from "../pages/inbox/Settings";
import InboxTraining from "../pages/inbox/Training";
import Stats from "../pages/social-manager/Stats";
import Posts from "../pages/social-manager/Posts";
import ComingSoon from "../pages/ComingSoon";
import Editor from "../pages/social-manager/EditCreative";
import Help from "../pages/Help";
import Tasks from "../pages/Tasks";
import Contacts from "../pages/Contacts";
import SignupBenefits from "../pages/SignupBenefits";
import Community from "../pages/community/Community";
import Advertising from "../pages/advertising/Advertising";
import RegisterA from "../pages/auth/RegisterA";
import { getRegistrationVersion } from "../utils/abTesting";
import TestAd from "../pages/advertising/TestAd";

const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Navigate to="/dashboard" replace />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: getRegistrationVersion() === "A" ? <RegisterA /> : <Register />,
  },
  {
    path: "/register-a",
    element: <RegisterA />,
  },
  {
    path: "/pricing",
    element: (
      <ProtectedRoute>
        <Pricing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/channels",
    element: (
      <ProtectedRoute>
        <ConnectChannels />
      </ProtectedRoute>
    ),
  },
  {
    path: "/shopify",
    element: (
      <ProtectedRoute>
        <ShopifyLogin />
      </ProtectedRoute>
    ),
  },
  {
    path: "/signup-benefits",
    element: (
      <ProtectedRoute>
        <SignupBenefits />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/inbox",
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Inbox />
          </ProtectedRoute>
        ),
      },
      {
        path: "training",
        element: (
          <ProtectedRoute>
            <InboxTraining />
          </ProtectedRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <ProtectedRoute>
            <InboxSettings />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/tasks",
    element: (
      <ProtectedRoute>
        <Tasks />
      </ProtectedRoute>
    ),
  },
  {
    path: "/social-manager",
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Navigate to="/social-manager/stats" replace />
          </ProtectedRoute>
        ),
      },
      {
        path: "stats",
        element: (
          <ProtectedRoute>
            <Stats />
          </ProtectedRoute>
        ),
      },
      {
        path: "posts",
        element: (
          <ProtectedRoute>
            <Posts />
          </ProtectedRoute>
        ),
      },
      {
        path: "generate",
        element: (
          <ProtectedRoute>
            <ComingSoon page="post-generator" pageType="post-generator" />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/advertising",
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <TestAd />
          </ProtectedRoute>
        ),
      },
      {
        path: "test",
        element: (
          <ProtectedRoute>
            <Posts isAdvertising={true} />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/contacts",
    element: (
      <ProtectedRoute>
        <Contacts />
      </ProtectedRoute>
    ),
  },
  {
    path: "/community",
    element: (
      <ProtectedRoute>
        <Community />
      </ProtectedRoute>
    ),
  },
  {
    path: "/account",
    element: (
      <ProtectedRoute>
        <MyAccount />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/editor",
  //   element: (
  //     <ProtectedRoute>
  //       <Editor />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/help",
    element: (
      <ProtectedRoute>
        <Help />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: (
      <ProtectedRoute>
        <Navigate to="/dashboard" replace />
      </ProtectedRoute>
    ),
  },
];

export default routes;
