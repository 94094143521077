export const initPendo = (user) => {
  if (window.pendo) {
    window.pendo.initialize({
      visitor: {
        id: user?.uid || "ANONYMOUS",
        email: user?.email,
        full_name: user?.displayName,
        role: user?.pricingPlan,
      },

      account: {
        id: user?.shopifyShop || "NO_ACCOUNT",
        name: user?.shopifyShopName,
        is_paying: Boolean(user?.pricingPlan),
        planLevel: user?.pricingPlan,
        planPrice: user?.planPrice,
        creationDate: user?.createdAt?.toDate()?.toISOString(),
      },
    });
  }
};
