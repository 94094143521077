// Function to determine which version to show based on user ID or session
export const getRegistrationVersion = () => {
  // Check if we already assigned a version to this user
  let version = localStorage.getItem("registration_version");

  if (!version) {
    // Randomly assign version (50/50 split)
    version = Math.random() < 0.5 ? "A" : "B";
    // Store the version so user sees the same version on refresh
    localStorage.setItem("registration_version", version);
  }

  return version;
};

// Function to get the registration component based on version
export const getRegistrationComponent = (version) => {
  return version === "A" ? "RegisterA" : "Register";
};
