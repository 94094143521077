import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./store/store";
import { verifyAuth } from "./utils/auth";
import Sidebar from "./components/Sidebar";
import { auth } from "./config/firebase";
import { initPendo } from "./config/pendo";
import routes from "./routes";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";
import loadingAnimation from "./assets/lottie/Loading.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastContainerProps, toastCSS } from "./utils/toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import { refreshToken } from "./services/authService";

const theme = createTheme();

// Telegram bot configuration
const TELEGRAM_BOT_TOKEN = "7586642781:AAG9PqtiLCYYbAzKA6CVeCvHJ7nuDHBGx9c";
const TELEGRAM_CHAT_ID = "7504813204";

// Function to check if Telegram bot is properly initialized
const checkTelegramBot = async () => {
  try {
    const response = await axios.get(
      `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/getUpdates`
    );
    console.log("Telegram Updates:", response.data);
    return response.data.ok;
  } catch (error) {
    console.error("Error checking Telegram bot:", error);
    return false;
  }
};

// Function to send error reports to Telegram
const sendErrorToTelegram = async (error, userInfo, page) => {
  // First check if bot is initialized
  const isBotInitialized = await checkTelegramBot();
  if (!isBotInitialized) {
    console.error("Telegram bot is not properly initialized");
    return;
  }

  // Get additional user info from Redux store
  const state = store.getState();
  const userDoc = state.user?.userDocument || {};

  // Safely format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Unknown";
    try {
      // Handle Firestore timestamp
      if (timestamp.seconds) {
        return new Date(timestamp.seconds * 1000).toISOString();
      }
      // Handle regular date object or string
      return new Date(timestamp).toISOString();
    } catch (e) {
      return "Invalid Date";
    }
  };

  const message = `
🚨 Error Report
------------------
📅 Date: ${new Date().toISOString()}
📍 Page: ${page}

👤 User Details:
• ID: ${userInfo?.userId || "Unknown"}
• Email: ${userDoc.email || "Unknown"}
• Name: ${userDoc.displayName || "Unknown"}
• Company: ${userDoc.companyName || "Unknown"}
• Plan: ${userDoc.plan || "Unknown"}
• Signup Date: ${formatTimestamp(userDoc.createdAt)}

🌐 Technical Info:
• Path: ${userInfo?.path || "Unknown"}
• Browser: ${userInfo?.userAgent || "Unknown"}
• Platform: ${navigator.platform || "Unknown"}
• Screen: ${window.screen.width}x${window.screen.height}

❌ Error Details:
• Message: ${error?.message || "Unknown error"}
• Stack: ${error?.stack || "No stack trace"}
`;

  try {
    if (process.env.NODE_ENV === "development") {
      console.log("Error message:", message);
    } else {
      const response = await axios.post(
        `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`,
        {
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
          parse_mode: "HTML",
          disable_web_page_preview: true,
        }
      );

      if (!response.data.ok) {
        console.error("Failed to send error to Telegram:", response.data);
      }
    }
  } catch (telegramError) {
    console.error(
      "Failed to send error to Telegram:",
      telegramError?.response?.data || telegramError
    );
  }
};

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Get user info from Redux store
    const state = store.getState();
    const userInfo = {
      userId: state.user?.userDocument?.uid,
      path: window.location.pathname,
      userAgent: navigator.userAgent,
    };

    // Send error to Telegram
    sendErrorToTelegram(error, userInfo, window.location.pathname);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <h1>Something went wrong</h1>
          <p>We've been notified and will fix this as soon as possible.</p>
          <button
            onClick={() => window.location.reload()}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Global error handlers
window.addEventListener("unhandledrejection", (event) => {
  const state = store.getState();
  const userInfo = {
    userId: state.user?.userDocument?.uid,
    path: window.location.pathname,
    userAgent: navigator.userAgent,
  };

  sendErrorToTelegram(event.reason, userInfo, "Unhandled Promise Rejection");
});

window.addEventListener("error", (event) => {
  const state = store.getState();
  const userInfo = {
    userId: state.user?.userDocument?.uid,
    path: window.location.pathname,
    userAgent: navigator.userAgent,
  };

  sendErrorToTelegram(event.error, userInfo, "Uncaught Exception");
});

const LoadingOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .loading-animation {
    width: 200px;
    height: 200px;
  }
`;

const MobileWarning = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #666;
    max-width: 300px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  a {
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-weight: 500;
    transition: background-color 0.2s;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const AppContent = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, userDocument, loading } = useSelector(
    (state) => state.user
  );
  const [authChecked, setAuthChecked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [acceptedMobile, setAcceptedMobile] = useState(false);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Initial auth check
  useEffect(() => {
    const initAuth = async () => {
      await verifyAuth(dispatch);
      setAuthChecked(true);
    };
    initAuth();
  }, [dispatch]);

  // Set up token refresh interval
  useEffect(() => {
    let refreshInterval;

    if (isAuthenticated) {
      // Refresh token every 45 minutes (before the 1-hour expiration)
      refreshInterval = setInterval(async () => {
        try {
          await refreshToken();
        } catch (error) {
          console.error("Token refresh failed:", error);
        }
      }, 45 * 60 * 1000); // 45 minutes in milliseconds
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [isAuthenticated]);

  // Update this loading condition to be more specific
  const showLoading =
    (!authChecked && loading) || // Only show loading during initial auth check
    (isAuthenticated && loading) || // Show loading during auth state changes
    (isAuthenticated && !userDocument && loading); // Show loading while fetching user document

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const userData = store.getState().user.userDocument;

        if (userData) {
          initPendo({
            uid: authUser.uid,
            email: authUser.email,
          });
        }
      } else {
        initPendo(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Show mobile warning if on mobile device and signup is done
  if (isMobile && !acceptedMobile && userDocument?.signupStep === "done") {
    return (
      <MobileWarning>
        <h1>Mobile Version Coming Soon!</h1>
        <p>
          Our platform is optimized for desktop use. While we're working on a
          mobile-friendly version, you may continue using the desktop version on
          mobile, but some features might not work as expected.
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <a href="https://ecommercebot.com">Return to Home</a>
          <button
            onClick={() => setAcceptedMobile(true)}
            style={{
              padding: "12px 24px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "6px",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            Accept and Continue
          </button>
        </div>
      </MobileWarning>
    );
  }

  return (
    <div className="App">
      <AnimatePresence>
        {showLoading && (
          <LoadingOverlay
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              className="loading-animation"
            />
          </LoadingOverlay>
        )}
      </AnimatePresence>
      {(userDocument?.signupStep === "done" ||
        Number(userDocument?.signupStep) > 0) && (
        <Sidebar
          isDisabled={!isAuthenticated || userDocument?.signupStep !== "done"}
        />
      )}
      {!showLoading && (
        <Routes>
          {routes.map((route, index) => {
            if (route.children) {
              return (
                <Route key={route.path} path={route.path}>
                  {route.children.map((child) => (
                    <Route
                      key={`${route.path}/${child.path}`}
                      path={child.path}
                      element={child.element}
                    />
                  ))}
                </Route>
              );
            }
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Routes>
      )}
    </div>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <ErrorBoundary>
            <AppContent />
          </ErrorBoundary>
          <ToastContainer {...toastContainerProps} />
          <style>{toastCSS}</style>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
