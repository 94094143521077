import { TestAdMetricContainer } from "./styles/TestAdModal.styled";
import goodIcon from "../assets/images/good.svg";
import badIcon from "../assets/images/bad.svg";

const TestAdMetric = ({ metric, good }) => {
  return (
    <TestAdMetricContainer good={good}>
      <div className="text-container">
        <h1 className="metric-name">{metric.metric}</h1>
        <h1 className="metric-value">{metric.value}</h1>
      </div>
      <img src={good ? goodIcon : badIcon} alt="metric" />
    </TestAdMetricContainer>
  );
};

export default TestAdMetric;
