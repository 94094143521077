import styled, { css } from "styled-components";
import theme from "./global/theme";
import { SkeletonText } from "./Contacts.styled";

// Main container for the dashboard
export const DashboardContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

// Main title for the dashboard
export const Title = styled.h1`
  font-size: 2rem;
  color: ${theme.color.dark};
  margin-bottom: 2rem;
`;

// Section container (Task Overview, Inbox Overview)
export const OverviewContainer = styled.div`
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 14px;
`;

// Section title
export const SectionTitle = styled.h2`
  color: ${theme.color.dark};
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

// Container for task items
export const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  box-sizing: border-box;

  .task-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }

  .task-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .task-list-header-buttons {
      display: flex;
      gap: 16px;
    }
  }
`;

// Individual task item
export const TaskItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: #e8eaef;
  box-sizing: border-box;
`;

export const DoButton = styled.button`
  background: ${theme.color.primary};

  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 166px;

  border: none;
  border-radius: 16px;

  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  transition: all 0.3s ease;

  &:hover {
    filter: brightness(0.9);
  }

  ${(props) =>
    props.$isDone &&
    css`
      background: none;
      border: 1.5px solid ${theme.color.primary};
      color: ${theme.color.primary};
      width: 122px;
    `}
`;

// Task text
export const TaskText = styled.span`
  color: ${theme.color.dark};
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

// Action button (Do Now)
export const ActionButton = styled.button`
  background: ${(props) =>
    props.primary ? theme.color.primary : theme.color.gray[200]};
  color: ${(props) =>
    props.primary ? theme.color.white : theme.color.gray[700]};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: ${theme.border.radiusSmall};
  cursor: pointer;
  font-family: ${theme.font.fontFamilyMundial};

  &:hover {
    background: ${(props) =>
      props.primary ? theme.color.info : theme.color.gray[300]};
  }
`;

// Individual inbox item
export const InboxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: ${theme.color.white};
  padding: 1rem;
  border-radius: ${theme.border.radiusSmall};
  margin-bottom: 0.75rem;
`;

// Icon container for inbox items
export const InboxIcon = styled.div`
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

// Content container for inbox messages
export const InboxContent = styled.div`
  flex: 1;
`;

// Inbox message text
export const InboxMessage = styled.p`
  color: ${theme.color.gray[700]};
  margin: 0;
`;

// Timestamp for inbox messages
export const InboxTime = styled.span`
  color: ${theme.color.gray[500]};
  font-size: 0.875rem;
  margin-left: auto;
`;

export const MetricsContainer = styled.div`
  display: flex;
  border-radius: 14px;
  background: #f3f4f6;
  gap: 24px;
  width: 100%;
  padding: 24px 20px;
  box-sizing: border-box;
`;

export const MetricList = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px 20px;
  align-items: flex-start;

  width: 100%;
  border-radius: 14px;
  background: #e8eaef;
  gap: 20px;

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  .metric-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const MetricItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MetricName = styled.h3`
  color: ${theme.color.black};
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const MetricValue = styled.h3`
  color: ${theme.color.black};
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const QuickLinksContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 16px;
  background: #e8eaef;
  max-height: 412px;
  box-sizing: border-box;
  span {
    color: #05bd67;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .quick-links-revenue {
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    background: #f3f4f6;
    padding: 24px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    margin-bottom: 12px;
    box-sizing: border-box;
    height: 78px;
  }

  h3 {
    margin: 0;
  }
`;

export const QuickLinkItem = styled.div`
  display: flex;
  padding: 8px 16px;

  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  width: 100%;
  background: #f3f4f6;
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;

  ${(props) =>
    props.$isAchievement &&
    css`
      height: 100%;
      cursor: default;
    `}
`;

export const SkeletonStatBlock = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
`;

export const SkeletonValue = styled(SkeletonText)`
  height: 24px;
`;
