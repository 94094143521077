import React, { useState } from "react";
import {
  AuthInput,
  AuthButton,
  ErrorMessage,
  AuthLink,
} from "../../pages/styles/Auth.styled";
import eyeSlash from "../../assets/images/eye-slash.svg";

// Component for the first step of registration - creating an account
const CreateAccountStep = ({
  formData,
  setFormData,
  loading,
  error,
  handleSubmit,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="form-input">
        <p>Email</p>
        <AuthInput
          type="email"
          placeholder="Enter email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
        />
      </div>
      <div className="form-input">
        <p>Password</p>
        <div style={{ position: "relative" }}>
          <AuthInput
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            required
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            {showPassword ? "👁️" : <img src={eyeSlash} alt="show password" />}
          </button>
        </div>
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <AuthButton
        type="submit"
        disabled={loading || !formData.email || !formData.password}
        onClick={handleSubmit}
      >
        {loading ? "Processing..." : "Continue"}
      </AuthButton>
      <AuthLink to="/login">
        Already have an account?&nbsp;&nbsp;<span>Login</span>
      </AuthLink>
    </>
  );
};

export default CreateAccountStep;
