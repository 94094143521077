import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import {
  PostsContainer,
  PostStatusSelection,
  StatusItem,
  ControlsContainer,
  EmptyState,
} from "../styles/Posts.styled";
import { useSelector } from "react-redux";
import { PostImage } from "../styles/Posts.styled";
import {
  H1,
  H2,
  H3,
  MainContainer,
  NavButton,
  Select,
} from "../styles/global/main.style";
import { useLocation, useNavigate } from "react-router-dom";
import facebookLogo from "../../assets/images/facebook.svg";
import instagramLogo from "../../assets/images/instagram.svg";
import likesIcon from "../../assets/images/likes.svg";
import commentsIcon from "../../assets/images/comments.svg";
import sharesIcon from "../../assets/images/shares.svg";
import PostBlock from "../../components/PostBlock";
import PostDesignList from "../../components/PostDesignList";
import { Button } from "@blueprintjs/core";
import TestAdModal from "../../components/TestAdModal";
import PostModal from "../../components/PostModal";
import { showSuccess, showError } from "../../utils/toast";
import Lottie from "lottie-react";
import processingAnimation from "../../assets/lottie/processing.json";

const Posts = ({ isAdvertising }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageId, setPageId] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const REFRESH_COOLDOWN = 60000; // 1 minute cooldown
  const [openTestAdModal, setOpenTestAdModal] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  // Add draft posts state
  const [postDesigns, setPostDesigns] = useState([]);
  const [loadingDrafts, setLoadingDrafts] = useState(false);
  // Add modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleMode, setIsScheduleMode] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  // Add pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const POSTS_PER_PAGE = 10;

  const userDocument = useSelector((state) => state.user.userDocument);
  const location = useLocation();
  const navigate = useNavigate();

  // Add modal state
  const [channelId, setChannelId] = useState("");
  const [caption, setCaption] = useState("");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [postType, setPostType] = useState("post");
  const [boost, setBoost] = useState(false);
  const [days, setDays] = useState(0);
  const [budget, setBudget] = useState(0);
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);

  // Add state for boost modal
  const [isBoostModalOpen, setIsBoostModalOpen] = useState(false);
  const [selectedBoostPost, setSelectedBoostPost] = useState(null);

  // Add fetchPostDesigns function for PostDesignList
  const fetchPostDesigns = useCallback(async () => {
    if (loadingDrafts) return;

    try {
      setLoadingDrafts(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/designs`,
        {
          params: {
            userId: userDocument.uid,
            page: currentPage,
            limit: 10,
          },
        }
      );

      // Filter draft posts here instead of in PostDesignList
      const drafts = response.data.designs.filter(
        (design) => design.status === "draft"
      );
      setPostDesigns(drafts);
      setTotalPages(Math.ceil(drafts.length / 10));
    } catch (error) {
      console.error("Error fetching designs:", error);
    } finally {
      setLoadingDrafts(false);
    }
  }, [userDocument?.uid, currentPage]);

  const handleDeletePost = async (designId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/designs/${designId}`,
        { params: { userId: userDocument.uid } }
      );
      fetchPostDesigns();
    } catch (error) {
      console.error("Error deleting design:", error);
      showError("Failed to delete design");
    }
  };

  const handlePostDraft = async (designId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/designs/${designId}`
      );
      setPreviewUrl(response.data.imageUrl);
      setIsScheduleMode(false);
      setIsModalOpen(true);
      setSelectedPostId(designId);
    } catch (error) {
      console.error("Error loading design:", error);
      showError("Error loading design. Please try again.");
    }
  };

  const handleScheduleDraft = async (designId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/designs/${designId}`
      );
      setPreviewUrl(response.data.imageUrl);
      setIsScheduleMode(true);
      setIsModalOpen(true);
      setSelectedPostId(designId);
    } catch (error) {
      console.error("Error loading design:", error);
      showError("Error loading design. Please try again.");
    }
  };

  const handleSubmit = async () => {
    if (!selectedPostId) {
      showError("No design found. Please save your design first.");
      return;
    }
    try {
      const requestPayload = {
        designId: selectedPostId,
        channelId,
        caption,
        scheduledDate: isScheduleMode
          ? scheduledDate?.toISOString()
          : new Date(Date.now() + 10 * 60 * 1000).toISOString(),
        platform: channelId.includes("instagram") ? "instagram" : "facebook",
        postType,
        isScheduled: isScheduleMode,
        userStoreUrl: userDocument.shopifyShop,
      };

      if (boost) {
        requestPayload.boost = boost;
        requestPayload.boostDays = days;
        requestPayload.boostBudget = budget;
        requestPayload.adAccountId = selectedAdAccount;
      }

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/social-manager/post`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        data: requestPayload,
      });

      showSuccess("Post created successfully!");
      setIsModalOpen(false);
      fetchPostDesigns(); // Refresh the drafts list
    } catch (error) {
      console.error("Error creating post:", error);
      showError("Failed to create post. Please try again.");
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setPreviewUrl(null);
  };

  // Update useEffect to fetch drafts when needed
  useEffect(() => {
    if (selectedStatus === "drafts" && userDocument?.uid) {
      fetchPostDesigns();
    }
  }, [selectedStatus, fetchPostDesigns, userDocument?.uid]);

  // Memoize currentPosts calculation
  const currentPosts = useMemo(() => {
    console.log("posts", posts);
    const filteredPosts = posts
      .filter((post) => {
        if (selectedStatus === "all") return true;
        if (selectedStatus === "boosted") return post.boost === true;
        return post.status === selectedStatus;
      })
      .filter((post) => post.mediaUrl);

    setTotalPages(Math.ceil(filteredPosts.length / POSTS_PER_PAGE));

    const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;

    return filteredPosts.slice(startIndex, endIndex);
  }, [posts, selectedStatus, currentPage]);

  const platform = posts[0]?.platform;

  // Memoize fetchPosts function
  const fetchPosts = useCallback(
    async (id) => {
      if (!id || loading) return;

      try {
        setLoading(true);
        const isInstagram =
          userDocument?.instagramPage?.instagram_business_account?.id === id;

        console.log("Fetching posts for:", {
          pageId: id,
          platform: isInstagram ? "instagram" : "facebook",
          userId: userDocument.uid,
        });

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/social-manager/post-data/${userDocument.uid}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              pageId: id,
              platform: isInstagram ? "instagram" : "facebook",
              refresh: true, // Always get fresh URLs to avoid expiration
            },
          }
        );

        console.log("Posts response:", {
          totalPosts: response.data.posts.length,
          sampleUrls: response.data.posts.slice(0, 2).map((p) => p.mediaUrl),
        });

        // Calculate engagement rate for each post
        const postsWithEngagement = response.data.posts.map((post) => ({
          ...post,
          engagementRate:
            post.impressions > 0
              ? (
                  ((post.likes + post.comments + post.shares) /
                    post.impressions) *
                  100
                ).toFixed(2)
              : 0,
        }));

        setPosts(postsWithEngagement);
      } catch (error) {
        console.error("Error fetching posts:", {
          error: error.message,
          response: error.response?.data,
          status: error.response?.status,
          pageId: id,
          userId: userDocument?.uid,
        });
      } finally {
        setLoading(false);
      }
    },
    [loading, userDocument]
  );

  // Memoize handler functions
  const handleChannelChange = useCallback(
    (e) => {
      const newPageId = e.target.value;

      // Set loading and update state synchronously
      setLoading(true);
      setPageId(newPageId);
      setCurrentPage(1);

      // Navigate after state updates
      navigate(`?channelId=${newPageId}`);

      // Check cache
      const lastFetchTime = localStorage.getItem(`lastFetch_${newPageId}`);
      const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
      const isCacheValid =
        lastFetchTime && Date.now() - parseInt(lastFetchTime) < CACHE_DURATION;

      if (isCacheValid) {
        const cachedPosts = JSON.parse(
          localStorage.getItem(`posts_${newPageId}`)
        );
        if (cachedPosts?.length > 0) {
          setPosts(cachedPosts);
          setLoading(false);
          return;
        }
      }

      // Fetch new posts if no valid cache
      fetchPosts(newPageId).finally(() => {
        setLoading(false);
        localStorage.setItem(`lastFetch_${newPageId}`, Date.now().toString());
        localStorage.setItem(`posts_${newPageId}`, JSON.stringify(posts));
      });
    },
    [fetchPosts, navigate]
  );

  // Add manual refresh function
  const handleRefresh = useCallback(async () => {
    const now = Date.now();
    if (!pageId || refreshing || now - lastRefreshTime < REFRESH_COOLDOWN)
      return;

    try {
      setRefreshing(true);
      const isInstagram =
        userDocument?.instagramPage?.instagram_business_account?.id === pageId;

      // First fetch fresh posts from social media
      await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/${
          isInstagram
            ? "instagram/fetch-instagram-posts"
            : "facebook/fetch-facebook-posts"
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Then fetch the updated posts from our database
      await fetchPosts(pageId);
      setLastRefreshTime(Date.now());
    } catch (error) {
      console.error("Error refreshing posts:", error);
    } finally {
      setRefreshing(false);
    }
  }, [pageId, refreshing, userDocument, fetchPosts, lastRefreshTime]);

  // Calculate time until next refresh is allowed
  const getRefreshButtonText = useCallback(() => {
    if (refreshing) return "Refreshing...";

    const timeUntilRefresh = REFRESH_COOLDOWN - (Date.now() - lastRefreshTime);
    if (timeUntilRefresh > 0) {
      return `Refresh available in ${Math.ceil(timeUntilRefresh / 1000)}s`;
    }

    return "Refresh Posts";
  }, [refreshing, lastRefreshTime]);

  // Add useEffect to handle URL params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const channelId = params.get("channelId");

    if (channelId) {
      setPageId(channelId);
      fetchPosts(channelId);
    } else if (userDocument?.facebookPages?.[0]?.id) {
      const defaultPageId = userDocument.facebookPages[0].id;
      navigate(`?channelId=${defaultPageId}`, { replace: true });
      setPageId(defaultPageId);
      fetchPosts(defaultPageId);
    }
  }, [userDocument, location.search]);

  // Add a simple retry mechanism for failed images
  const handleImageError = (postId) => {
    // Trigger a re-fetch of all posts when an image fails to load
    fetchPosts(pageId);
  };

  const renderMedia = (post) => {
    const isVideo =
      post.mediaUrl?.includes(".mp4") || post.mediaType === "VIDEO";

    if (isVideo) {
      return (
        <a
          href={post.mediaUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="media-container video-placeholder"
        >
          <div className="play-overlay">▶</div>
        </a>
      );
    }

    return (
      <PostImage
        src={post.mediaUrl}
        alt="Post media"
        onError={() => handleImageError(post.postId)}
        key={post.mediaUrl} // Add key to force re-render when URL changes
      />
    );
  };

  // Add pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Update status change to reset pagination
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1); // Reset to first page
  };

  // Add function to handle boost submission
  const handleBoostSubmit = async (postId, boostData) => {
    console.log("BOOSTING POST WITH HANDLE BOOST SUBMIT");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post/boost-existing`,
        {
          postId,
          boostDays: boostData.days,
          boostBudget: boostData.budget,
          adAccountId: boostData.adAccountId,
          product: boostData.product,
          productUrl: boostData.productUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      showSuccess("Post boosted successfully!");
      fetchPosts(pageId); // Refresh posts to show updated status
      navigate(0);
    } catch (error) {
      console.error("Error boosting post:", error);
      showError("Failed to boost post");
    }
  };

  const handlePostUpdate = async (updatedPost) => {
    // Update the post in the posts array
    setPosts((prevPosts) =>
      prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post))
    );
  };

  console.log("posts", posts);

  return (
    <MainContainer>
      {openTestAdModal && (
        <TestAdModal
          setOpenTestAdModal={setOpenTestAdModal}
          open={openTestAdModal}
          postId={selectedPostId}
        />
      )}

      {isModalOpen && (
        <PostModal
          open={isModalOpen}
          onClose={handleModalClose}
          onCancel={handleModalClose}
          channelId={channelId}
          setChannelId={setChannelId}
          scheduledDate={scheduledDate}
          setScheduledDate={setScheduledDate}
          caption={caption}
          setCaption={setCaption}
          postType={postType}
          setPostType={setPostType}
          onSubmit={handleSubmit}
          previewUrl={previewUrl}
          boost={boost}
          setBoost={setBoost}
          days={days}
          setDays={setDays}
          budget={budget}
          setBudget={setBudget}
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
        />
      )}

      <div id="header">
        <H1>{isAdvertising ? "Advertising" : "Posts"}</H1>

        {/* Add channel dropdown and refresh button */}
        <div className="channel-controls">
          <div className="channel-dropdown">
            {platform && (
              <img
                src={platform === "facebook" ? facebookLogo : instagramLogo}
                alt="Platform"
              />
            )}
            <Select onChange={handleChannelChange} value={pageId || ""}>
              {userDocument?.facebookPages?.map((page) => (
                <option key={page.id} value={page.id}>
                  {page.name} (Facebook)
                </option>
              ))}

              {userDocument?.instagramBusinessAccountId && (
                <option
                  key={userDocument.instagramBusinessAccountId}
                  value={userDocument.instagramBusinessAccountId}
                >
                  {userDocument.instagramUsername} (Instagram)
                </option>
              )}
            </Select>
          </div>
        </div>

        {!isAdvertising ? (
          <div id="buttons">
            <NavButton
              onClick={() => navigate("/social-manager/stats")}
              $isFilled={location.pathname === "/social-manager/stats"}
            >
              Stats
            </NavButton>
            <NavButton
              onClick={() => navigate("/social-manager/posts")}
              $isFilled={location.pathname === "/social-manager/posts"}
            >
              Posts
            </NavButton>
            <NavButton
              onClick={() => navigate("/social-manager/generate")}
              $isFilled={location.pathname === "/social-manager/generate"}
            >
              Generate
            </NavButton>
          </div>
        ) : (
          <NavButton
            onClick={() => navigate("/social-manager/test")}
            $isFilled={location.pathname === "/social-manager/test"}
          >
            Test
          </NavButton>
        )}
      </div>

      <ControlsContainer>
        {!isAdvertising && (
          <PostStatusSelection>
            <StatusItem
              $isSelected={selectedStatus === "all"}
              onClick={() => handleStatusChange("all")}
              type="button"
            >
              <p>All</p>
            </StatusItem>
            <StatusItem
              $isSelected={selectedStatus === "published"}
              onClick={() => handleStatusChange("published")}
              type="button"
            >
              <p>Published</p>
            </StatusItem>
            <StatusItem
              $isSelected={selectedStatus === "scheduled"}
              onClick={() => handleStatusChange("scheduled")}
              type="button"
            >
              <p>Scheduled</p>
            </StatusItem>
            <StatusItem
              $isSelected={selectedStatus === "boosted"}
              onClick={() => handleStatusChange("boosted")}
              type="button"
            >
              <p>Boosted</p>
            </StatusItem>
            <StatusItem
              $isSelected={selectedStatus === "drafts"}
              onClick={() => handleStatusChange("drafts")}
              type="button"
            >
              <p>Drafts</p>
            </StatusItem>
          </PostStatusSelection>
        )}
        <NavButton
          onClick={handleRefresh}
          disabled={
            refreshing ||
            !pageId ||
            Date.now() - lastRefreshTime < REFRESH_COOLDOWN ||
            selectedStatus === "drafts" // Disable refresh for drafts
          }
        >
          {getRefreshButtonText()}
        </NavButton>
      </ControlsContainer>
      {selectedStatus === "drafts" ? (
        <PostDesignList
          postDesigns={postDesigns}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loadingDrafts}
          fetchPostDesigns={fetchPostDesigns}
          onPost={handlePostDraft}
          onSchedule={handleScheduleDraft}
          onDelete={handleDeletePost}
        />
      ) : (
        <PostsContainer>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie
                animationData={processingAnimation}
                loop={true}
                style={{ width: 100, height: 100 }}
              />
            </div>
          ) : (
            <>
              {currentPosts.map((post, index) => (
                <PostBlock
                  testAdModal={openTestAdModal}
                  setOpenTestAdModal={setOpenTestAdModal}
                  key={post.id}
                  post={post}
                  isPost={true}
                  index={index}
                  setSelectedPostId={setSelectedPostId}
                  onBoostSubmit={handleBoostSubmit}
                  onPostUpdate={handlePostUpdate}
                />
              ))}

              {!loading && currentPosts.length > 0 && (
                <div className="pagination">
                  <Button
                    style={{ borderRadius: "8px" }}
                    icon="chevron-left"
                    disabled={currentPage === 1}
                    onClick={handlePrevPage}
                  />

                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <Button
                        style={{ borderRadius: "8px", border: "none" }}
                        key={page}
                        active={currentPage === page}
                        onClick={() => setCurrentPage(page)}
                      >
                        {page}
                      </Button>
                    )
                  )}

                  <Button
                    style={{ borderRadius: "8px" }}
                    icon="chevron-right"
                    disabled={currentPage === totalPages}
                    onClick={handleNextPage}
                  />
                </div>
              )}
            </>
          )}

          {!loading && currentPosts.length === 0 && (
            <EmptyState>
              {selectedStatus === "all"
                ? "No posts found"
                : `No ${selectedStatus} posts found`}
            </EmptyState>
          )}
        </PostsContainer>
      )}
    </MainContainer>
  );
};

export default Posts;
