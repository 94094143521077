import styled from "styled-components";
import theme from "../../pages/styles/global/theme";

export const PostDesignListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
`;

export const PostDesignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  width: 100%;

  button {
    min-width: 32px;
    height: 32px;
    padding: 0 8px;

    &[disabled] {
      opacity: 0.5;
    }

    &[active="true"] {
      background: #106ba3;
      color: white;
    }
  }
`;
