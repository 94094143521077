import styled from "styled-components";
import theme from "./global/theme";
import { CloseButton } from "../../components/styles/PostModal.styled";

export const CommunityContainer = styled.div`
  width: 75%;
  min-width: 800px;
  max-width: 1500px;
  display: flex;
  gap: 10%;
  justify-content: center;

  position: relative;

  #posts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-bottom: 40px;
  }

  #create-post {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
  }
`;

export const CommunityPostCreateBlock = styled.div`
  display: flex;
  height: fit-content;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
  width: 100%;
  position: sticky;
  top: 0px;
  border-radius: 8px;
  border: 1px solid ${theme.color.gray[300]};
  background: #fff;
  box-shadow: ${theme.shadow.boxShadow};

  img {
    border-radius: 8px;
  }

  .actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const AttachmentPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${theme.color.gray[300]};

  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
  position: relative;

  span {
    font-size: 14px;
    color: ${theme.color.gray[700]};
    margin-bottom: 8px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid ${theme.color.gray[300]};
  }
`;

export const FileRemoveButton = styled(CloseButton)`
  top: 10px;
  right: 10px;
`;
