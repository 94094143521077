import styled from "styled-components";

export const CompleteStepContainer = styled.form`
  display: flex;
  align-items: center;
  max-width: 750px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 65%;
  border-radius: 0px 9.887px 9.887px 0px;
  padding: 20px;
  background: linear-gradient(180deg, #0385ff 0%, #025099 100%);
  border-radius: 0px 8px 8px 0px;
  width: 100%;
  height: 350px;
  box-sizing: border-box;
  justify-content: center;

  @media (max-width: 768px) {
    width: 95%;
    border-radius: 0px 0px 8px 8px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 35%;
  background: #fff;
  border-radius: 8px;
  height: 350px;
  width: 80%;
  padding: 20px;
  border-radius: 8px 0px 0px 8px;
  box-sizing: border-box;
  padding-top: 44px;
  padding-bottom: 44px;

  .header {
    color: var(--Black, #0a0b1f);
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 39px; /* 130% */
    span {
      background: linear-gradient(90deg, #0385ff 0%, #025099 31.49%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 39px;
    }
  }

  .description {
    color: var(--EB-Icon, #a3a3a3);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: auto;

    span {
      background: linear-gradient(90deg, #0385ff 57.22%, #025099 77.87%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    gap: 10px;
    width: 100%;

    span {
      color: var(--EB-Icon, #a3a3a3);
      text-align: center;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media (max-width: 768px) {
    width: 95%;
    border-radius: 8px;
    z-index: 1;
    margin-bottom: -20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
  }

  #feature-name {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  #feature-description {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
