import React from "react";
import {
  SkeletonText,
  SkeletonValue,
  SkeletonSmallBlock,
  SkeletonLargeBlock,
} from "./styles/SkeletonComponents";

const SkeletonBlock = ({
  type = "stat",
  textCount = 1,
  valueCount = 1,
  width,
}) => {
  const Block = type === "stat" ? SkeletonSmallBlock : SkeletonLargeBlock;

  const renderSkeletonTexts = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <SkeletonText key={`text-${index}`} />
    ));
  };

  const renderSkeletonValues = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <SkeletonValue key={`value-${index}`} />
    ));
  };

  return (
    <Block $width={width}>
      {renderSkeletonTexts(textCount)}
      {renderSkeletonValues(valueCount)}
    </Block>
  );
};

export default SkeletonBlock;
