import styled from "styled-components";
import theme from "../../pages/styles/global/theme";
import { CloseButton } from "./PostModal.styled";

export const CommunityPostBlockContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
  width: 100%;
  height: fit-content;

  border-radius: 8px;
  border: 1px solid ${theme.color.gray[300]};
  background: #fff;
`;

export const CommunityPostBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  #post-author {
    color: ${theme.color.black};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #post-date {
    color: #a3a3a3;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .post-author-date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const CommunityPostBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    background: ${theme.color.gray[100]};
  }
`;

export const CommunityPostBlockFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .upvote-downvote-interaction {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const CommunityPostBlockInteraction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.color.gray[100]};
  }

  img {
    width: 20px;
    height: 20px;
    opacity: ${(props) => (props.active === "true" ? "1" : "0.6")};
    transition: opacity 0.2s;
  }

  h3 {
    color: ${(props) =>
      props.type === "like"
        ? props.active === "true"
          ? theme.color.primary[500]
          : theme.color.gray[500]
        : props.active === "true"
        ? theme.color.primary[500]
        : theme.color.gray[500]};
    transition: color 0.2s;
  }
`;

export const FileRemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  color: ${theme.color.gray[500]};
  opacity: 0.7;
  transition: opacity 0.2s;
  font-size: 18px;

  &:hover {
    opacity: 1;
  }
`;
