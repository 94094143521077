import styled, { css } from "styled-components";

import theme from "./global/theme";

export const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 0;
  border-radius: 12px;
  background: none;
  width: 100%;
  box-sizing: border-box;

  ${(props) =>
    props.$isContacts &&
    css`
      gap: 20px;
      height: fit-content;
      background: #f3f4f6;
      flex-direction: column;
      justify-content: center;
      padding: 28px 20px;
    `}

  .pagination {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 24px auto;
    justify-content: center;
    width: 100%;

    button {
      min-width: 32px;
      height: 32px;
      padding: 0 8px;

      &[disabled] {
        opacity: 0.5;
      }

      &[active="true"] {
        background: #106ba3;
        color: white;
      }
    }
  }
`;

export const PostStatusSelection = styled.div`
  display: flex;
  width: fit-content;
  height: 48px;
  padding: 12px 0px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #f3f4f6;
  box-sizing: border-box;
`;

export const StatusItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  width: fit-content;
  height: 48px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;

  ${(props) =>
    props.$isSelected &&
    css`
      background: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    `}

  p {
    color: ${(props) => (props.$isSelected ? "#000" : "rgba(0, 0, 0, 0.35)")};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
`;

export const PostBlock = styled.div`
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: #e8eaef;
  width: 100%;

  .engagement-metrics {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      color: ${theme.color.gray[900]};
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .post-metrics {
    display: flex;
    gap: 8px;

    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .no-image {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: ${theme.color.gray[300]};
  }

  .media-container {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .video-placeholder {
    background: ${theme.color.gray[300]};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .play-overlay {
    color: white;
    font-size: 16px;
    background: ${theme.color.primary};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PostImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
`;

export const PostLink = styled.a`
  text-decoration: none;
  color: ${theme.color.primary};
`;

export const ControlsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const EmptyState = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px;
  color: ${theme.color.gray[500]};
  font-family: Poppins;
  font-size: 16px;
`;
