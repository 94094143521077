import axios from "axios";
import { auth } from "../config/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { serializeUser } from "../utils/auth";
import {
  setUser,
  setUserDocument,
  clearUser,
  setToken,
  setLoading,
} from "../store/slices/userSlice";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

// Configure axios with default headers
export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add token to requests if it exists
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const register = async (email, password) => {
  try {
    // First create user in backend
    const response = await api.post("/api/auth/register", {
      email,
      password,
    });

    // Then sign in with Firebase client SDK to get ID token
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const idToken = await userCredential.user.getIdToken();

    // Store the ID token
    localStorage.setItem("token", idToken);

    return {
      ...response.data,
      token: idToken,
    };
  } catch (error) {
    console.error("Registration error:", error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const idToken = await userCredential.user.getIdToken();

    const response = await api.post("/api/auth/login", {
      email,
      password,
    });

    // Store the Firebase ID token
    localStorage.setItem("token", idToken);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    if (response.data.profile) {
      localStorage.setItem(
        "userDocument",
        JSON.stringify(response.data.profile)
      );
    }

    return {
      ...response.data,
      token: idToken,
    };
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const getUserProfile = async () => {
  const response = await api.get("/api/user/profile");
  return response.data;
};

export const updateUserProfile = async (profileData) => {
  const response = await api.put("/api/user/profile", profileData);
  return response.data;
};

export const verifyAndLoadUser = async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) return false;

  try {
    // Set loading state
    dispatch(setLoading(true));

    // Force token refresh on app initialization
    const newToken = await refreshToken();

    // Get user data from backend with new token
    const response = await api.get("/api/auth/me", {
      headers: { Authorization: `Bearer ${newToken}` },
    });

    // Update Redux store
    dispatch(setUserDocument(response.data));
    dispatch(
      setUser({
        email: response.data.email,
        emailVerified: response.data.emailVerified,
        displayName: response.data.displayName,
        photoURL: response.data.photoURL,
      })
    );

    // Set token in Redux
    dispatch(setToken(newToken));

    return true;
  } catch (error) {
    console.error("Error verifying token:", error);
    // Clear everything if verification fails
    localStorage.removeItem("token");
    dispatch(clearUser());
    return false;
  } finally {
    dispatch(setLoading(false));
  }
};

// Add token refresh function
export const refreshToken = async () => {
  try {
    // Get current Firebase user
    const user = auth.currentUser;
    if (!user) {
      throw new Error("No user logged in");
    }

    // Force token refresh
    const newToken = await user.getIdToken(true);
    localStorage.setItem("token", newToken);
    return newToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

// Add axios interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if error is due to expired token
    if (
      (error?.response?.status === 401 ||
        error?.response?.data?.error === "Invalid token" ||
        error?.response?.data?.code === "auth/id-token-expired") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        // Force token refresh
        const newToken = await refreshToken();

        // Update the failed request with new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Retry the original request
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, clear token and redirect to login
        localStorage.removeItem("token");
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Refresh token before it expires
export const setupTokenRefresh = () => {
  // Refresh every 45 minutes (tokens expire after 1 hour)
  const REFRESH_INTERVAL = 45 * 60 * 1000;

  setInterval(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const newToken = await user.getIdToken(true);
        localStorage.setItem("token", newToken);
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      // Force logout if refresh fails
      window.location.href = "/login";
    }
  }, REFRESH_INTERVAL);
};
