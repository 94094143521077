import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  H1,
  H3,
  NavButton,
  H4,
  GlobalStyle,
} from "./styles/global/main.style";
import { PricingPlans } from "../data/pricing-data";
import {
  PricingContainer,
  PricingCard,
  PlanName,
  PlanPrice,
  MessagesLimit,
  FeaturesList,
  FeatureItem,
  PricingButton,
  PricingHeader,
  ShowMoreButton,
} from "./styles/Pricing.styled";
import { motion, AnimatePresence } from "framer-motion";
import {
  listVariants,
  itemVariants,
  pricingCardVariants,
  pricingButtonVariants,
  pricingContainerVariants,
} from "./styles/global/framer-motion-variants";
import checkMark from "../assets/images/packageCheckMark.svg";
import arrowDown from "../assets/images/arrow-down.svg";
import { api } from "../services/authService";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, logout } from "../store/slices/userSlice";

const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [processingPlan, setProcessingPlan] = useState(null);
  const [expandedPlans, setExpandedPlans] = useState({});

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handlePackageSelect = async (plan) => {
    setLoading(true);
    setProcessingPlan(plan.id);
    setError("");

    try {
      if (user?.userDocument?.planStatus === "active") {
        const response = await api.get(
          "/api/stripe/portal/create-portal-session",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.url) {
          window.open(response.data.url, "_blank");
          return;
        }
      }

      const stripeId = plan.stripeId;
      const response = await api.post("/api/create-checkout-session", {
        priceId: stripeId,
        planLevel: PricingPlans.findIndex((p) => p.id === plan.id),
        planName: plan.name.toLowerCase(),
      });

      if (response.data.sessionUrl) {
        localStorage.setItem(
          "selectedPackage",
          JSON.stringify({
            ...plan,
            sessionId: response.data.sessionId,
          })
        );
        window.location.href = response.data.sessionUrl;
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      const errorMessage =
        error.response?.data?.error ||
        "Failed to process request. Please try again.";
      setError(errorMessage);
    } finally {
      setLoading(false);
      setProcessingPlan(null);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const toggleFeatures = (planId) => {
    setExpandedPlans((prev) => ({
      ...prev,
      [planId]: !prev[planId],
    }));

    if (!expandedPlans[planId]) {
      setTimeout(() => {
        const element = document.getElementById(`features-${planId}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  };

  const featureListVariants = {
    hidden: { height: "auto" },
    visible: { height: "auto" },
  };

  return (
    <>
      <GlobalStyle $isPricing />
      <MainContainer $isPricing>
        <PricingContainer>
          <PricingHeader>
            <h1>Pricing</h1>
            <div className="pricing-header-description">
              <h2>Get started with EcommerceBot today. Cancel anytime.</h2>
              <span>Growth guaranteed or your money back.</span>
            </div>
          </PricingHeader>
          {error && (
            <div
              style={{
                color: "#EF4444",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              {error}
            </div>
          )}
          {PricingPlans.map((plan) => (
            <PricingCard key={plan.id}>
              <img id="plan-image" src={plan.image} alt={plan.name} />
              <div className="plan-header">
                <span className="plan-name">{plan.name}</span>
                <span className="plan-description">{plan.description}</span>
              </div>
              <div className="plan-price">
                <PlanPrice>
                  <div className="discount-container">
                    <span className="amount">${plan.price}</span>
                    <span className="discount">$449</span>
                  </div>
                  <span className="period">Billed every month</span>
                </PlanPrice>
              </div>
              <PricingButton
                as={motion.button}
                variants={pricingButtonVariants}
                initial="initial"
                whileHover="hover"
                whileTap="tap"
                $isPopular={plan.id === "grow"}
                onClick={() => handlePackageSelect(plan)}
                disabled={loading}
              >
                {loading && processingPlan === plan.id
                  ? "Processing..."
                  : user?.userDocument?.planStatus === "active"
                  ? "Update Plan"
                  : "Upgrade"}
              </PricingButton>
              <div className="features-header">What's included</div>
              <motion.div
                id={`features-${plan.id}`}
                variants={featureListVariants}
                initial="hidden"
                animate="visible"
              >
                <FeaturesList>
                  {plan.features
                    .slice(0, expandedPlans[plan.id] ? undefined : 5)
                    .map((feature, featureIndex) => (
                      <FeatureItem key={featureIndex}>
                        <img src={checkMark} alt="" />
                        {feature}
                      </FeatureItem>
                    ))}
                </FeaturesList>
              </motion.div>
              {plan.features.length > 5 && (
                <ShowMoreButton
                  onClick={() => toggleFeatures(plan.id)}
                  $isExpanded={expandedPlans[plan.id]}
                >
                  {expandedPlans[plan.id]
                    ? "Show Less"
                    : `Show More (${plan.features.length})`}
                  <img src={arrowDown} alt="toggle" />
                </ShowMoreButton>
              )}
            </PricingCard>
          ))}
        </PricingContainer>
      </MainContainer>
    </>
  );
};

export default Pricing;
