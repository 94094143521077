import {
  MainContainer,
  H1,
  NavButton,
  H3,
  H4,
} from "../styles/global/main.style";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import StatBlock from "../../components/StatBlock";
import { auth } from "../../config/firebase";
import {
  ChannelsContainer,
  StatsContainer,
} from "../styles/SocialManager.styled";
import ChannelBlock from "../../components/ChannelBlock";
import instagramLogo from "../../assets/images/ig-logo.png";
import facebookLogo from "../../assets/images/fb-logo.png";
import uploadIcon from "../../assets/images/upload-icon.svg";
import ConnectChannelBlock from "../../components/ConnectChannelBlock";
import { setUserDocument } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  listVariants,
  itemVariants,
  modalOverlayVariants,
} from "../styles/global/framer-motion-variants";
import SkeletonBlock from "../../components/SkeletonBlock";
import PostModal from "../../components/PostModal";
import { showSuccess, showError } from "../../utils/toast";
import RegisterModal from "../../components/RegisterModal";
import styled from "styled-components";
import CompleteStep from "../../components/registration/CompleteStep";

// Add styled component for dark overlay
const DarkOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
`;

const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes cache

// Add StatsSkeleton component
const StatsSkeleton = () => (
  <>
    <StatsContainer as={motion.div} variants={listVariants}>
      {Array.from({ length: 7 }).map((_, index) => (
        <SkeletonBlock
          key={index}
          as={motion.div}
          variants={itemVariants}
          type="stat"
          textCount={2}
          valueCount={1}
          width="calc(25% - 12px)"
        />
      ))}
    </StatsContainer>
    <ChannelsContainer as={motion.div} variants={listVariants}>
      {Array.from({ length: 3 }).map((_, index) => (
        <SkeletonBlock
          key={index}
          as={motion.div}
          variants={itemVariants}
          type="channel"
          textCount={4}
          valueCount={0}
          width="calc(33.33% - 11px)"
        />
      ))}
    </ChannelsContainer>
  </>
);

const Stats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stats, setStats] = useState(null);
  const [previousStats, setPreviousStats] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [loading, setLoading] = useState(true);

  const { userDocument } = useSelector((state) => state.user);
  const initialMount = useRef(true);
  const connectedChannels = useSelector(
    (state) => state.user.userDocument?.connectedChannels || []
  );
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [showReconnectConfirm, setShowReconnectConfirm] = useState(false);
  const [showShopifyInput, setShowShopifyInput] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [connectingPlatform, setConnectingPlatform] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [caption, setCaption] = useState("");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [postType, setPostType] = useState("post");
  const [boost, setBoost] = useState(false);
  const [days, setDays] = useState(0);
  const [budget, setBudget] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [postFile, setPostFile] = useState(null);
  const [storyFile, setStoryFile] = useState(null);
  const [activeStep, setActiveStep] = useState(userDocument?.signupStep || 0);
  const [loadingPlatform, setLoadingPlatform] = useState(null);

  var isConnected = connectedChannels.includes("facebook");
  let isSignupDone = userDocument?.signupStep === "done";
  console.log("IS SIGNUP DONE", userDocument);
  const getValidToken = async () => {
    try {
      // Add a small delay to ensure Firebase is ready
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Wait for Firebase to initialize
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      const currentUser = auth.currentUser;
      if (currentUser) {
        // Force token refresh
        const token = await currentUser.getIdToken(true);
        localStorage.setItem("token", token);
        return token;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  // Load from cache on mount
  useEffect(() => {
    const loadStats = async () => {
      // Skip if not connected or no user
      if (!isConnected || !userDocument?.uid) {
        setLoading(true);
        return;
      }

      // Wait for Firebase to initialize and get token
      const token = await getValidToken();
      if (!token) {
        console.error("No valid token available");
        setLoading(false);
        return;
      }

      const cachedData = localStorage.getItem("socialStats");
      const isFirstVisit = !sessionStorage.getItem("hasVisitedStats");

      // If we have valid cache and it's not first visit, use it
      if (cachedData && !isFirstVisit) {
        const {
          stats: cachedStats,
          previousStats: cachedPrevStats,
          timestamp,
        } = JSON.parse(cachedData);
        const isCacheValid = Date.now() - timestamp < CACHE_DURATION;

        if (isCacheValid) {
          setStats(cachedStats);
          setPreviousStats(cachedPrevStats);
          setLastFetchTime(timestamp);
          setLoading(false);
          return;
        }
      }

      // Mark as visited
      if (isFirstVisit) {
        sessionStorage.setItem("hasVisitedStats", "true");
      }

      // Fetch fresh data
      await fetchStats();
    };

    loadStats();
  }, [userDocument?.uid, isConnected]);

  // Define getTrend function
  const getTrend = useMemo(
    () => (current, previous) => {
      if (!previous) return null;
      return current >= previous ? "positive" : "negative";
    },
    []
  );

  const fetchStats = async () => {
    if (!userDocument?.uid || !isConnected) return;

    setLoading(true);

    try {
      // Get fresh token before making requests
      const token = await getValidToken();
      if (!token) {
        throw new Error("No valid token available");
      }

      // Current period stats
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/social-manager/${userDocument?.uid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Previous period stats
      const previousResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/social-manager/${userDocument?.uid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            period: "previous",
          },
        }
      );

      let instagramStats = {};
      if (connectedChannels.includes("instagram")) {
        try {
          const instagramResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/instagram-stats/${userDocument.uid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          instagramStats = { instagram: instagramResponse.data.stats };
        } catch (error) {
          console.error("Error fetching Instagram stats:", error);
        }
      }

      const combinedStats = {
        ...response.data,
        ...instagramStats,
      };

      // Cache the results with both current and previous stats
      const timestamp = Date.now();
      localStorage.setItem(
        "socialStats",
        JSON.stringify({
          stats: combinedStats,
          previousStats: previousResponse.data,
          timestamp,
        })
      );

      setLastFetchTime(timestamp);
      setStats(combinedStats);
      setPreviousStats(previousResponse.data);
    } catch (error) {
      console.error("Error fetching stats:", error);
      if (error.response?.status === 401) {
        // If still getting 401 after token refresh, there might be a bigger issue
        console.error("Authentication error even after token refresh");
      }
    } finally {
      setLoading(false);
    }
  };

  // Memoize metrics calculations
  const Metrics = useMemo(
    () => [
      {
        metricName: "Total Impression",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.impressions)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.impressions || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.impressions)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.impressions || 0),
      },
      {
        metricName: "Total Followers",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.followers)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.followers || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.followers)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.followers || 0),
      },
      {
        metricName: "Following",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.following)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.following || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.following)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.following || 0),
      },
      {
        metricName: "New Followers",
        metricValue:
          stats?.stats
            ?.map((stat) => stat?.stats.newFollowers)
            .reduce((a, b) => a + b, 0) || 0,
        previousValue:
          previousStats?.stats
            ?.map((stat) => stat?.stats.newFollowers)
            .reduce((a, b) => a + b, 0) || 0,
      },
      {
        metricName: "Engagement Rate",
        metricValue: (() => {
          // Calculate total engagements across all channels
          const totalEngagements =
            stats?.stats
              ?.map((stat) => stat?.stats.engagements)
              .reduce((a, b) => a + b, 0) || 0;

          // Calculate total impressions across all channels including Instagram
          const totalImpressions =
            (stats?.stats
              ?.map((stat) => stat?.stats.impressions)
              .reduce((a, b) => a + b, 0) || 0) +
            (stats?.instagram?.[0]?.stats?.impressions || 0);

          return (totalEngagements / (totalImpressions || 1)) * 100;
        })(),
        previousValue: (() => {
          // Calculate previous total engagements across all channels
          const prevTotalEngagements =
            previousStats?.stats
              ?.map((stat) => stat?.stats.engagements)
              .reduce((a, b) => a + b, 0) || 0;

          // Calculate previous total impressions across all channels including Instagram
          const prevTotalImpressions =
            (previousStats?.stats
              ?.map((stat) => stat?.stats.impressions)
              .reduce((a, b) => a + b, 0) || 0) +
            (previousStats?.instagram?.[0]?.stats?.impressions || 0);

          return (prevTotalEngagements / (prevTotalImpressions || 1)) * 100;
        })(),
      },
      {
        metricName: "Total Engagement",
        metricValue:
          stats?.stats
            ?.map((stat) => stat?.stats.engagements)
            .reduce((a, b) => a + b, 0) || 0,
        previousValue:
          previousStats?.stats
            ?.map((stat) => stat?.stats.engagements)
            .reduce((a, b) => a + b, 0) || 0,
      },
      {
        metricName: "Total Reach",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.totalReach)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.reach || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.totalReach)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.reach || 0),
      },
    ],
    [stats, previousStats]
  );

  // Memoize percentage change calculations
  const getPercentageChange = useMemo(
    () => (current, previous) => {
      if (!previous) return 0;
      const change = ((current - previous) / previous) * 100;
      return Number(change.toFixed(2));
    },
    []
  );

  const handleRefresh = async () => {
    await fetchStats();
  };

  // Show cache status
  const cacheAge = useMemo(() => {
    if (!lastFetchTime) return null;
    const minutes = Math.floor((Date.now() - lastFetchTime) / (1000 * 60));
    return `${minutes}m ago`;
  }, [lastFetchTime]);

  const handleUpload = async (event, type) => {
    try {
      const file = event.target.files[0];

      if (!file) return;

      if (!file.type.startsWith("image/")) {
        showError("Please upload an image file");
        return;
      }

      if (file.size > 4.75 * 1024 * 1024) {
        showError("File size must be less than 4.75MB");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", userDocument.uid);

      // Include the bucket path and token information
      const timestamp = Date.now();
      const storagePath = `social-posts/${userDocument.uid}/${type}/${timestamp}-${file.name}`;
      formData.append(
        "metadata",
        JSON.stringify({
          contentType: file.type,
          storagePath: storagePath,
          bucketName: "ecommercebot-a076f.firebasestorage.app",
        })
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Construct the Firebase Storage URL with the token
      const fileUrl = `https://firebasestorage.googleapis.com/v0/b/ecommercebot-a076f.firebasestorage.app/o/${encodeURIComponent(
        storagePath
      )}?alt=media&token=${response.data.token}`;

      if (type === "post") {
        setPostFile(fileUrl);
      } else {
        setStoryFile(fileUrl);
      }
      setPreviewUrl(fileUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
      showError("Failed to upload file");
    }
  };

  const handleSubmit = async (requestPayload) => {
    try {
      console.log("Request payload:", JSON.stringify(requestPayload, null, 2));

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/social-manager/post`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        data: requestPayload,
      };

      console.log("Request configuration:", JSON.stringify(config, null, 2));

      const response = await axios(config);

      console.log("Server response:", response.data);

      showSuccess("Post created successfully!");
      setIsModalOpen(false);
      // Reset form state
      setPreviewUrl(null);
      setPostFile(null);
      setStoryFile(null);
      setCaption("");
      setChannelId("");
      setScheduledDate(null);
      setPostType("post");
      setBoost(false);
      setDays(0);
      setBudget(0);
    } catch (error) {
      console.error("Error creating post:", error);
      showError("Failed to create post. Please try again.");
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setPreviewUrl(null);
    setPostFile(null);
    setStoryFile(null);
    setCaption("");
    setChannelId("");
    setScheduledDate(null);
    setPostType("post");
    setBoost(false);
    setDays(0);
    setBudget(0);
  };

  const Channels = [
    {
      img_url: facebookLogo,
      name: "Facebook",
      description: "Connect your Facebook page to start sending messages.",
      isOutlined: true,
    },
  ];

  const handleStartTrial = async () => {
    console.log("Starting trial");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/stripe/trial`,
        {
          priceId: process.env.REACT_APP_STRIPE_STARTER_PRICE_ID,
          email: userDocument.email,
          name: userDocument.name,
          userId: userDocument.uid,
        }
      );
      console.log(response.data);

      // Fetch updated user data
      const userResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/me`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Update Redux store with new user data
      dispatch(setUserDocument(userResponse.data));
    } catch (error) {
      console.error("Error starting trial:", error);
    }
  };

  return (
    <MainContainer>
      {/* Add RegisterModal with dark overlay when not signed up */}
      <AnimatePresence>
        {!isSignupDone && (
          <DarkOverlay
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalOverlayVariants}
          >
            {activeStep === 4 ? (
              <CompleteStep
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                onStartTrial={handleStartTrial}
              />
            ) : (
              <RegisterModal
                activeStep={userDocument?.signupStep || location.state?.step}
                setActiveStep={setActiveStep}
              />
            )}
          </DarkOverlay>
        )}
      </AnimatePresence>

      {isModalOpen && (
        <PostModal
          open={isModalOpen}
          onClose={handleModalClose}
          onCancel={handleModalClose}
          channelId={channelId}
          setChannelId={setChannelId}
          scheduledDate={scheduledDate}
          setScheduledDate={setScheduledDate}
          caption={caption}
          setCaption={setCaption}
          postType={postType}
          setPostType={setPostType}
          onSubmit={handleSubmit}
          previewUrl={previewUrl}
          boost={boost}
          setBoost={setBoost}
          days={days}
          setDays={setDays}
          budget={budget}
          setBudget={setBudget}
          renderPreview={() => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {postType === "post" ? (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUpload(e, "post")}
                    style={{ display: "none" }}
                    id="post-upload"
                  />
                  <label
                    htmlFor="post-upload"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {postFile ? (
                      <img
                        src={postFile}
                        alt="preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <>
                        <img
                          src={uploadIcon}
                          alt="upload"
                          style={{ width: 48, height: 48, marginBottom: 16 }}
                        />
                        <span>Upload Post</span>
                        <span style={{ fontSize: 12, color: "#666" }}>
                          Max Size: 4.75MB
                        </span>
                      </>
                    )}
                  </label>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUpload(e, "story")}
                    style={{ display: "none" }}
                    id="story-upload"
                  />
                  <label
                    htmlFor="story-upload"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {storyFile ? (
                      <img
                        src={storyFile}
                        alt="preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <>
                        <img
                          src={uploadIcon}
                          alt="upload"
                          style={{ width: 48, height: 48, marginBottom: 16 }}
                        />
                        <span>Upload Story</span>
                        <span style={{ fontSize: 12, color: "#666" }}>
                          Max Size: 4.75MB
                        </span>
                      </>
                    )}
                  </label>
                </>
              )}
            </div>
          )}
        />
      )}

      <div id="header">
        <H1>Stats</H1>
        {isConnected && (
          <div className="cache-info">
            {cacheAge && <H4>Last updated: {cacheAge}</H4>}
            <NavButton onClick={handleRefresh} disabled={loading}>
              {loading ? "Refreshing..." : "Refresh"}
            </NavButton>
          </div>
        )}
        <div id="buttons">
          <NavButton
            disabled={!isSignupDone}
            onClick={() => navigate("/social-manager/stats")}
            $isFilled={location.pathname === "/social-manager/stats"}
          >
            Stats
          </NavButton>
          <NavButton
            disabled={!isSignupDone}
            onClick={() => navigate("/social-manager/posts")}
            $isFilled={location.pathname === "/social-manager/posts"}
          >
            Posts
          </NavButton>
          <NavButton
            disabled={!isSignupDone}
            onClick={() => navigate("/social-manager/generate")}
            $isFilled={location.pathname === "/social-manager/generate"}
          >
            Generate
          </NavButton>

          <NavButton
            disabled={!isSignupDone}
            onClick={() => setIsModalOpen(true)}
            $isFilled={false}
          >
            Create Post
          </NavButton>
        </div>
      </div>
      {loading ? (
        <StatsSkeleton />
      ) : (
        <>
          <AnimatePresence>
            <StatsContainer
              as={motion.div}
              variants={listVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {Metrics.map((metric) => (
                <StatBlock
                  as={motion.div}
                  variants={itemVariants}
                  $isBlurred={!isConnected}
                  key={metric.metricName}
                  statName={metric.metricName}
                  statValue={metric.metricValue}
                  percent={metric.metricName === "Engagement Rate"}
                  percentageChange={getPercentageChange(
                    metric.metricValue,
                    metric.previousValue
                  )}
                  trend={getTrend(metric.metricValue, metric.previousValue)}
                />
              ))}
            </StatsContainer>
          </AnimatePresence>

          <AnimatePresence>
            <ChannelsContainer
              as={motion.div}
              variants={listVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {stats?.instagram?.map((channel) => (
                <ChannelBlock
                  as={motion.div}
                  variants={itemVariants}
                  stats={channel.stats}
                  name={channel.pageName}
                  key={`instagram-${channel.pageId}`}
                  channelType="Instagram"
                  channelId={channel.pageId}
                />
              ))}
              {stats?.stats?.map((channel) => (
                <ChannelBlock
                  as={motion.div}
                  variants={itemVariants}
                  stats={channel.stats}
                  name={channel.pageName}
                  key={`facebook-${channel.pageId}`}
                  channelType="Facebook"
                  channelId={channel.pageId}
                />
              ))}
            </ChannelsContainer>
          </AnimatePresence>
        </>
      )}
    </MainContainer>
  );
};

export default Stats;
