import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  writeBatch,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import {
  ChatSection,
  ChatHeader,
  MessageList,
  Message,
  MessageBubble,
  MessageTime,
  ChatInput,
  Input,
  SendButton,
  UserInfo,
  UserInfoSection,
  Avatar,
  Name,
  EmptyState,
  MessageItem,
  MessageChannelIcon,
  MessagesContainer,
  InfoLabel,
  InfoValue,
  FilterButton,
  FilterContainer,
  FilterMenu,
  FilterMenuButton,
  Checkbox,
  FilterLabel,
  ContactName,
  MessagePreview,
  UnreadCount,
  ChannelSelectItem,
  ChannelSelectionContainer,
  ChannelModalContent,
  ChattingAsText,
  Button,
} from "../styles/Inbox.styled";
import { ModalOverlay } from "../styles/ConnectChannels.styled";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import widget from "../../assets/images/livechat.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import sendIcon from "../../assets/images/send.svg";
import filterIcon from "../../assets/images/filter-icon.svg";
import defaultAvatar from "../../assets/images/profile-user.svg";
import {
  H1,
  H2,
  H3,
  H4,
  MainContainer,
  NavButton,
} from "../styles/global/main.style";
import {
  listVariants,
  itemVariants,
  filterMenuVariants,
  messageContainerVariants,
  messageVariants,
  postModalSelectItemVariants,
} from "../styles/global/framer-motion-variants";
import { AnimatePresence } from "framer-motion";
import { PostStatusSelection, StatusItem } from "../styles/Posts.styled";
import editIcon from "../../assets/images/edit-icon.svg";

const formatMessageTimestamp = (timestamp) => {
  if (!timestamp) return "";

  const messageDate = timestamp.toDate();
  const now = new Date();
  const diffInHours = (now - messageDate) / (1000 * 60 * 60);

  if (diffInHours < 24) {
    // Less than 24 hours old - show time only
    return messageDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    // More than 24 hours old - show date
    return messageDate.toLocaleDateString([], {
      month: "short",
      day: "numeric",
    });
  }
};

const Inbox = () => {
  const { userDocument } = useSelector((state) => state.user);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messageInput, setMessageInput] = useState("");
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("all");
  const [selectedChannelId, setSelectedChannelId] = useState("all");
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showChannelIdModal, setShowChannelIdModal] = useState(false);

  const userInfo = [
    { Label: "Name", value: selectedConversation?.customerInfo?.name || "N/A" },
    {
      Label: "Email",
      value: selectedConversation?.customerInfo?.email || "N/A",
    },
    {
      Label: "Phone",
      value: selectedConversation?.customerInfo?.phone || "N/A",
    },
  ];

  const channelIcons = {
    widget: widget,
    instagram: instagram,
    facebook: facebook,
  };

  const navigate = useNavigate();
  const location = useLocation();
  // Listen for conversations in real-time
  useEffect(() => {
    if (!userDocument?.uid) return;

    const q = query(
      collection(db, "conversations"),
      where("merchant_id", "==", userDocument.uid),
      where("status", "==", "active"),
      orderBy("updatedAt", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const conversationsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConversations(conversationsData);

        // Find first valid conversation (one that would show in the MessageList)
        const firstValidConversation = conversationsData.find(
          (conv) => conv.lastMessage?.content
        );

        // Only set if we don't have a selected conversation yet
        if (!selectedConversation && firstValidConversation) {
          setSelectedConversation(firstValidConversation);
        }

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching conversations:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userDocument?.uid]);

  // Create a function to handle conversation selection
  const handleConversationSelect = (conversation) => {
    // Clear messages immediately before switching
    setMessages([]);
    setSelectedConversation(conversation);
    console.log("CONVERSATION SELECTED", conversation);
  };

  // Add this function to handle new messages in selected conversation
  const markNewMessageAsRead = async (newMessage, conversationRef) => {
    if (
      newMessage.senderId !== userDocument.uid && // Message is from other person
      newMessage.status === "unread" && // Message is unread
      newMessage.recipientId === userDocument.uid // Message is for current user
    ) {
      const batch = writeBatch(db);

      // Update message status to read
      const messageRef = doc(db, "messages", newMessage.id);
      batch.update(messageRef, { status: "read" });

      // Only update the unread count and seen status, preserve the existing lastMessage content
      batch.update(conversationRef, {
        "stats.unreadCount": 0,
        "lastMessage.seen": true,
      });

      await batch.commit().catch((error) => {
        console.error("Error marking new message as read:", error);
      });
    }
  };

  // Update the messages useEffect
  useEffect(() => {
    if (!selectedConversation?.id) return;

    const q = query(
      collection(db, "messages"),
      where("conversationId", "==", selectedConversation.id),
      orderBy("timestamp", "asc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      // Get all messages including new ones
      const messagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);

      // Handle new messages
      snapshot
        .docChanges()
        .filter((change) => change.type === "added")
        .forEach((change) => {
          const newMessage = { id: change.doc.id, ...change.doc.data() };
          const conversationRef = doc(
            db,
            "conversations",
            selectedConversation.id
          );
          markNewMessageAsRead(newMessage, conversationRef);
        });
    });

    return () => unsubscribe();
  }, [selectedConversation?.id, userDocument?.uid]);

  useEffect(() => {
    const messagesContainer = document.querySelector(".messages-container");
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]); // Scroll whenever messages update

  const handleSendMessage = async () => {
    if (!messageInput.trim() || !selectedConversation?.id) return;
    setMessageInput("");
    try {
      // Send message through API with manualResponse flag
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/messages/send`,
        {
          conversationId: selectedConversation.id,
          content: messageInput,
          manualResponse: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const markConversationAsRead = async (conversationId) => {
    if (!userDocument?.uid || !conversationId) return;

    try {
      const batch = writeBatch(db);

      // Update conversation unread count
      const conversationRef = doc(db, "conversations", conversationId);
      batch.update(conversationRef, {
        "stats.unreadCount": 0,
        lastMessage: {
          ...selectedConversation?.lastMessage,
          seen: true,
        },
      });

      // Get unread messages for this conversation
      const unreadMessagesQuery = query(
        collection(db, "messages"),
        where("conversationId", "==", conversationId),
        where("status", "==", "unread"),
        where("recipientId", "==", userDocument.uid)
      );

      const unreadSnapshot = await getDocs(unreadMessagesQuery);

      // Mark each message as read
      unreadSnapshot.docs.forEach((doc) => {
        batch.update(doc.ref, { status: "read" });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error marking conversation as read:", error);
    }
  };

  // Add this useEffect to mark messages as read when conversation is selected
  useEffect(() => {
    if (selectedConversation?.id) {
      markConversationAsRead(selectedConversation.id);
    }
  }, [selectedConversation?.id]);

  // Filter conversations based on selected channel and channelId

  console.log("CONVERSATIONS", conversations);
  console.log("SELECTED CHANNEL", selectedChannel);
  console.log("SELECTED CHANNEL ID", selectedChannelId);

  // Log all Facebook conversations
  console.log(
    "Facebook conversations:",
    conversations.filter(
      (conversation) =>
        conversation.channelType === "facebook" ||
        conversation?.metadata?.channel?.platform === "facebook"
    )
  );
  const filteredConversations = conversations.filter((conversation) => {
    if (selectedChannel === "all" && selectedChannelId === "all") return true;
    if (selectedChannelId !== "all")
      return conversation?.metadata?.channel?.pageId === selectedChannelId;
    return conversation.channelType === selectedChannel;
  });

  // Add effect to handle selected conversation when changing filters
  useEffect(() => {
    // If the selected conversation is not in the filtered list, select the first available conversation
    if (
      selectedConversation &&
      !filteredConversations.find((conv) => conv.id === selectedConversation.id)
    ) {
      const firstValidConversation = filteredConversations.find(
        (conv) => conv.lastMessage?.content
      );
      setSelectedConversation(firstValidConversation || null);
    }
  }, [selectedChannel, selectedChannelId, filteredConversations]);

  // Render loading state
  if (loading) {
    return (
      <MainContainer $isInbox>
        <div id="header">
          <H1>Training</H1>
          <div id="buttons">
            <NavButton onClick={() => navigate("/inbox/training")}>
              Inbox
            </NavButton>
            <NavButton onClick={() => navigate("/inbox/settings")}>
              Settings
            </NavButton>
            <NavButton onClick={() => navigate("/inbox")}>Inbox</NavButton>
          </div>
        </div>
        <EmptyState>Loading conversations...</EmptyState>
      </MainContainer>
    );
  }

  // Render empty state
  if (!loading && conversations.length === 0) {
    return (
      <MainContainer $isInbox>
        <div id="header">
          <H1>Inbox</H1>
          <div id="buttons">
            <NavButton onClick={() => navigate("/inbox/training")}>
              Training
            </NavButton>
            <NavButton onClick={() => navigate("/inbox/settings")}>
              Settings
            </NavButton>
            <NavButton onClick={() => navigate("/inbox")}>Inbox</NavButton>
          </div>
        </div>
        <EmptyState>No conversations yet</EmptyState>
      </MainContainer>
    );
  }

  console.log(filteredConversations);

  console.log("MESSAGES", messages);

  return (
    <MainContainer $isInbox $noScroll>
      <div id="header">
        <H1>Inbox</H1>
        <div id="buttons">
          <NavButton
            onClick={() => navigate("/inbox/training")}
            $isFilled={location.pathname === "/inbox/training"}
          >
            Training
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox/settings")}
            $isFilled={location.pathname === "/inbox/settings"}
          >
            Settings
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox")}
            $isFilled={location.pathname === "/inbox"}
          >
            Inbox
          </NavButton>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
          marginBottom: "20px",
        }}
      >
        <PostStatusSelection>
          <StatusItem
            $isSelected={selectedChannel === "all"}
            onClick={() => {
              setSelectedChannel("all");
              setSelectedChannelId("all");
            }}
            type="button"
          >
            <p>All Platforms</p>
          </StatusItem>
          <StatusItem
            $isSelected={selectedChannel === "widget"}
            onClick={() => {
              setSelectedChannel("widget");
              setSelectedChannelId("all");
            }}
            type="button"
          >
            <p>Widget</p>
          </StatusItem>
          <StatusItem
            $isSelected={selectedChannel === "instagram"}
            onClick={() => {
              setSelectedChannel("instagram");
              setSelectedChannelId("all");
            }}
            type="button"
          >
            <p>Instagram</p>
          </StatusItem>
          <StatusItem
            $isSelected={selectedChannel === "facebook"}
            onClick={() => {
              setSelectedChannel("facebook");
              setSelectedChannelId("all");
            }}
            type="button"
          >
            <p>Facebook</p>
          </StatusItem>
        </PostStatusSelection>

        {selectedChannel !== "all" && (
          <ChannelSelectItem
            variants={postModalSelectItemVariants}
            initial="hidden"
            animate="visible"
            $isSelected={true}
            onClick={() => setShowChannelIdModal(true)}
          >
            <div className="select-platform-name">
              <img
                src={channelIcons[selectedChannel] || channelIcons.widget}
                alt={selectedChannel}
              />
              <span>
                {selectedChannelId === "all"
                  ? "All Channels"
                  : selectedChannel === "facebook"
                  ? userDocument?.facebookPages?.find(
                      (page) => page.id === selectedChannelId
                    )?.name || "Unknown Page"
                  : userDocument?.instagramUsername || "Unknown Account"}
              </span>
            </div>
            <img
              src={editIcon}
              alt="Edit"
              style={{
                position: "absolute",
                right: "20px",
                width: "20px",
                cursor: "pointer",
              }}
            />
          </ChannelSelectItem>
        )}
      </div>

      {/* Channel ID Selection Modal */}
      {showChannelIdModal && (
        <ModalOverlay onClick={() => setShowChannelIdModal(false)}>
          <ChannelModalContent onClick={(e) => e.stopPropagation()}>
            <H2>Select Channel</H2>
            <div className="channel-list">
              <ChannelSelectItem
                variants={postModalSelectItemVariants}
                initial="hidden"
                animate="visible"
                $isSelected={selectedChannelId === "all"}
                onClick={() => {
                  setSelectedChannelId("all");
                  setShowChannelIdModal(false);
                }}
              >
                <div className="select-platform-name">
                  <img src={channelIcons[selectedChannel]} alt="All Channels" />
                  <span>All Channels</span>
                </div>
              </ChannelSelectItem>
              {selectedChannel === "facebook" &&
                userDocument?.facebookPages?.map((page) => (
                  <ChannelSelectItem
                    key={page.id}
                    variants={postModalSelectItemVariants}
                    initial="hidden"
                    animate="visible"
                    $isSelected={selectedChannelId === page.id}
                    onClick={() => {
                      setSelectedChannelId(page.id);
                      setShowChannelIdModal(false);
                    }}
                  >
                    <div className="select-platform-name">
                      <img src={channelIcons.facebook} alt={page.name} />
                      <span>{page.name}</span>
                    </div>
                  </ChannelSelectItem>
                ))}
              {selectedChannel === "instagram" &&
                userDocument?.instagramBusinessAccountId && (
                  <ChannelSelectItem
                    variants={postModalSelectItemVariants}
                    initial="hidden"
                    animate="visible"
                    $isSelected={
                      selectedChannelId ===
                      userDocument.instagramBusinessAccountId
                    }
                    onClick={() => {
                      setSelectedChannelId(
                        userDocument.instagramBusinessAccountId
                      );
                      setShowChannelIdModal(false);
                    }}
                  >
                    <div className="select-platform-name">
                      <img
                        src={channelIcons.instagram}
                        alt={userDocument.instagramUsername}
                      />
                      <span>{userDocument.instagramUsername}</span>
                    </div>
                  </ChannelSelectItem>
                )}
            </div>
          </ChannelModalContent>
        </ModalOverlay>
      )}

      <div id="sections-container">
        <MessageList variants={listVariants} initial="hidden" animate="visible">
          {filteredConversations.map(
            (conversation) =>
              conversation.lastMessage?.content && (
                <MessageItem
                  key={conversation.id}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  $hasUnread={conversation.stats?.unreadCount > 0}
                  onClick={() => {
                    if (selectedConversation?.id !== conversation.id) {
                      handleConversationSelect(conversation);
                    }
                  }}
                  $isSelected={selectedConversation?.id === conversation.id}
                >
                  <div id="channel-icon-text">
                    <MessageChannelIcon>
                      <img
                        src={channelIcons[conversation.channelType] || widget}
                        alt={`${conversation.channelType} Icon`}
                      />
                    </MessageChannelIcon>

                    <div id="contact-name">
                      <ContactName>
                        {conversation.customerInfo?.name || "New Lead"}
                      </ContactName>

                      <MessagePreview>
                        {conversation.lastMessage?.content
                          ? conversation.lastMessage.content
                              .split(" ")
                              .reduce((acc, word) => {
                                if (acc.length + word.length <= 25) {
                                  return acc + (acc ? " " : "") + word;
                                }
                                return acc;
                              }, "") +
                            (conversation.lastMessage.content.length > 25
                              ? "..."
                              : "")
                          : "No message"}
                      </MessagePreview>
                    </div>
                  </div>
                  <div className="timestamp-container">
                    <H4>
                      {formatMessageTimestamp(
                        conversation.lastMessage?.timestamp
                      )}
                    </H4>
                    {conversation.stats?.unreadCount > 0 && (
                      <UnreadCount>
                        {conversation.stats.unreadCount}
                      </UnreadCount>
                    )}
                  </div>
                </MessageItem>
              )
          )}
        </MessageList>

        <ChatSection variants={listVariants} initial="hidden" animate="visible">
          {selectedConversation ? (
            <>
              <ChatHeader>
                {selectedConversation.customerInfo && (
                  <>
                    <div className="customer-info">
                      <Avatar
                        src={
                          selectedConversation.customerInfo.avatar ||
                          defaultAvatar
                        }
                        alt={
                          selectedConversation.customerInfo.name || "Customer"
                        }
                      />
                      <Name>
                        {selectedConversation.customerInfo.name ||
                          "Unknown Customer"}
                      </Name>
                    </div>
                    <span className="channel-name">
                      To{" "}
                      {selectedConversation.channelType === "facebook"
                        ? userDocument?.facebookPages?.find(
                            (page) =>
                              page.id ===
                              selectedConversation.merchantInfo.pageId
                          )?.name
                        : selectedConversation.channelType === "instagram"
                        ? userDocument?.instagramUsername
                        : selectedConversation.channelType === "widget"
                        ? userDocument?.webshopUrl
                        : "Unknown Channel"}
                    </span>
                  </>
                )}
              </ChatHeader>
              <AnimatePresence mode="wait">
                <MessagesContainer
                  className="messages-container"
                  key={selectedConversation.id}
                  variants={messageContainerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  {messages.map((message) => (
                    <Message
                      isRecommendation={message?.productTitle}
                      key={`${selectedConversation.id}-${message.id}`}
                      variants={messageVariants}
                      initial="hidden"
                      animate="visible"
                      $isUser={message.metadata?.direction === "outbound"}
                    >
                      {!message?.productTitle ? (
                        <>
                          <MessageBubble
                            $isUser={message.metadata?.direction === "outbound"}
                          >
                            {message.content}
                          </MessageBubble>
                          <MessageTime>
                            {message.timestamp
                              ?.toDate()
                              ?.toLocaleTimeString() || ""}
                          </MessageTime>
                        </>
                      ) : (
                        <>
                          <MessageBubble
                            $isUser={message.metadata?.direction === "outbound"}
                            $isProduct
                          >
                            <img src={message.productImage} alt="Product" />
                            <span className="product-title">
                              {message.productTitle}
                            </span>
                            <span className="product-price">
                              {message.productPrice}
                            </span>
                            <NavButton
                              className="view-product-button"
                              onClick={() => {
                                const shopifyUrl =
                                  userDocument?.webshopUrl.includes("http")
                                    ? userDocument.webshopUrl
                                    : `https://${userDocument?.webshopUrl}`;
                                const externalUrl = `${shopifyUrl}${message.productUrl}`;
                                window.open(externalUrl, "_blank");
                              }}
                            >
                              View Product
                            </NavButton>
                          </MessageBubble>
                          <MessageTime>
                            {message.timestamp
                              ?.toDate()
                              ?.toLocaleTimeString() || ""}
                          </MessageTime>
                        </>
                      )}
                    </Message>
                  ))}
                </MessagesContainer>
              </AnimatePresence>
              <ChatInput>
                <div style={{ width: "100%" }}>
                  <Input
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    placeholder="Enter your message here..."
                    onKeyDown={(e) => e.key === "Enter" && handleSendMessage(e)}
                    whileFocus={{ scale: 1.01 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  />

                  <ChattingAsText>
                    Chatting as{" "}
                    {selectedConversation.channelType === "facebook"
                      ? userDocument?.facebookPages?.find(
                          (page) =>
                            page.id === selectedConversation.merchantInfo.pageId
                        )?.name
                      : selectedConversation.channelType === "instagram"
                      ? userDocument?.instagramUsername
                      : selectedConversation.channelType === "widget"
                      ? userDocument?.shopifyShopName
                      : "Unknown Channel"}
                  </ChattingAsText>
                </div>
                <SendButton
                  onClick={handleSendMessage}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                >
                  Send <img src={sendIcon} alt="Send Icon" />
                </SendButton>
              </ChatInput>
            </>
          ) : (
            <EmptyState>
              {selectedConversation
                ? "Loading messages..."
                : "Select a conversation to start chatting"}
            </EmptyState>
          )}
        </ChatSection>
        {/* <UserInfoSection>
          {userInfo.map((info) => (
            <UserInfo key={info.Label}>
              <InfoLabel>{info.Label}</InfoLabel>
              <InfoValue>{info.value}</InfoValue>
            </UserInfo>
          ))}
        </UserInfoSection> */}
      </div>
    </MainContainer>
  );
};

export default Inbox;
