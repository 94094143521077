import styled, { css } from "styled-components";
import theme from "../../pages/styles/global/theme";

export const ProductDropdownContainer = styled.div`
  margin: 20px 0;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 14px;

  font-family: ${theme.font.fontFamilyPrimary};
`;

export const ProductSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  background: white;
  height: 90%;

  /* Customize scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 2px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 2px;
  }

  /* Customize scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 transparent;

  ${(props) =>
    props.$isTestAd &&
    css`
      max-height: 300px;
    `}
`;

export const ProductOption = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const SelectedProductContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: left;
  height: fit-content;

  #product-image {
    height: 100%;
    max-height: 400px;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid ${theme.color.gray[300]};
  }

  #product-title {
    color: ${theme.color.gray[900]};
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #product-price {
    color: ${theme.color.gray[900]};
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #product-description {
    display: none;
    color: ${theme.color.gray[500]};
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 200px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 2px;
    }
  }

  .product-title-price {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
  }

  #switch-product-button {
    margin-top: 20px;
  }
`;

export const TestAdMetricContainer = styled.div`
  display: flex;
  width: 48%;
  padding: 24px 20px;
  justify-content: space-between;
  align-items: center;
  position: relative;

  border-radius: 12px;
  border: ${(props) =>
    props.good
      ? `1px solid ${theme.color.success}`
      : `1px solid ${theme.color.danger}`};
  background: ${(props) =>
    props.good ? `${theme.color.successLight}` : `${theme.color.dangerLight}`};

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;

    .metric-name {
      color: ${(props) =>
        props.good ? theme.color.success : theme.color.danger};
      font-family: ${theme.font.fontFamilyPrimary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    .metric-value {
      color: ${(props) =>
        props.good ? theme.color.success : theme.color.danger};
      font-family: ${theme.font.fontFamilyPrimary};
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
  }

  img {
    position: absolute;
    right: 7px;
    bottom: 7px;
    width: 32px;
    height: 32px;
  }
`;

// Update the slider components
export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  margin: 10px 0;
  min-height: 80px;
  height: 80px;
  flex-shrink: 0;
`;

export const SliderWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0 40px;
  gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  min-height: 80px;
  align-items: center;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SliderArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${theme.color.gray[300]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease;

  &:hover {
    background: ${theme.color.gray[100]};
    border-color: ${theme.color.gray[400]};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${(props) => (props.$left ? "left: 0;" : "right: 0;")}

  svg {
    width: 20px;
    height: 20px;
    fill: ${theme.color.gray[500]};
  }
`;

export const SwiperContainer = styled.div`
  position: relative;
  padding: 0 50px;
  margin-bottom: 20px;

  .swiper {
    position: static;
  }

  .swiper-slide {
    height: auto;
    width: calc(50% - 10px) !important; /* Force equal width for 2 slides */
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    transition: all 0.2s ease;

    &:hover {
      background: ${theme.color.gray[100]};
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &:after {
      font-size: 18px;
      font-weight: bold;
      color: ${theme.color.gray[900]};
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: auto !important;
    cursor: not-allowed;

    &:hover {
      background: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;
