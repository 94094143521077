import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import homeIcon from "../assets/images/home-2.svg";
import inboxIcon from "../assets/images/sms.svg";
import socialIcon from "../assets/images/Discord.svg";
import tasksIcon from "../assets/images/document.svg";
import contactsIcon from "../assets/images/call-calling.svg";
import advertisingIcon from "../assets/images/paperclip.svg";
import calendarIcon from "../assets/images/calendar.svg";
import profileIcon from "../assets/images/profile.svg";
import walletIcon from "../assets/images/wallet-2.svg";
import helpIcon from "../assets/images/info-circle.svg";
import channelsIcon from "../assets/images/add-square.svg";
import editorIcon from "../assets/images/editorIcon.svg";
import communityIcon from "../assets/images/people.svg";
import boltIcon from "../assets/images/bolt.svg";
import {
  SidebarContainer,
  SidebarHeader,
  NavItem,
  NavText,
  TrialEndingBlock,
  MobileToggle,
  MobileOverlay,
} from "./styles/Sidebar.styled";
import axios from "axios";
import { NavButton } from "../pages/styles/global/main.style";
import { AuthButton } from "../pages/styles/Auth.styled";

const Sidebar = ({ isDisabled }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isBlocked } = useSelector((state) => state.navigation);
  const userDocument = useSelector((state) => state.user.userDocument);
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigation = async (path) => {
    if (isBlocked || isDisabled) {
      return; // Prevent navigation while blocked
    }

    if (path === "/subscriptions") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/stripe/portal/create-portal-session`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.url) {
          window.open(response.data.url, "_blank");
          return;
        }
      } catch (error) {
        console.error("Failed to open subscription portal:", error);
        // Fallback to subscription page if portal fails
        navigate("/subscriptions");
      }
    } else {
      navigate(path);
    }
    // Close sidebar on mobile after navigation
    setIsOpen(false);
  };

  // Helper function to check if a path is active
  const isPathActive = (path) => {
    if (path === "/inbox") {
      return location.pathname.startsWith("/inbox");
    }

    if (path === "/social-manager/stats") {
      return location.pathname.startsWith("/social-manager");
    }

    return location.pathname === path;
  };

  const navItems = [
    { path: "/channels", label: "Connect Channel", icon: channelsIcon },
    { path: "/dashboard", label: "Home", icon: homeIcon },
    { path: "/inbox", label: "Inbox", icon: inboxIcon },
    {
      path: "/social-manager/stats",
      label: "Social Manager",
      icon: socialIcon,
    },
    { path: "/tasks", label: "Tasks", icon: tasksIcon },
    { path: "/contacts", label: "Contacts", icon: contactsIcon },
    { path: "/community", label: "Community", icon: communityIcon },
    { path: "/advertising", label: "Advertising", icon: advertisingIcon },
    // { path: "/editor", label: "Editor", icon: editorIcon },
    { path: "/account", label: "My Account", icon: profileIcon },
    { path: "/subscriptions", label: "Subscriptions", icon: walletIcon },
    { path: "/help", label: "Help", icon: helpIcon },
  ];

  // Convert Unix timestamp (seconds) to milliseconds for date calculation
  const daysLeft = Math.ceil(
    (userDocument?.trialEndsAt * 1000 - Date.now()) / (1000 * 60 * 60 * 24)
  );

  return (
    <>
      <MobileToggle $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6L15 12L9 18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </MobileToggle>
      <MobileOverlay $isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <SidebarContainer $isOpen={isOpen}>
        <SidebarHeader>
          <h1>EcommerceBot</h1>
        </SidebarHeader>

        {navItems.map((item) => (
          <NavItem
            key={item.path}
            $isActive={isPathActive(item.path)}
            onClick={() => handleNavigation(item.path)}
            style={{ cursor: isBlocked ? "not-allowed" : "pointer" }}
          >
            <img src={item.icon} alt={item.label} />
            <NavText $isActive={isPathActive(item.path)}>{item.label}</NavText>
          </NavItem>
        ))}
        {userDocument?.planStatus === "trial" && (
          <TrialEndingBlock>
            <div className="header">
              <img src={boltIcon} alt="bolt" />
              <p className="text">Trial ends in {daysLeft} days</p>
            </div>
            <p className="text">
              You are on a trial of the <span>Success</span> plan
            </p>
            <AuthButton onClick={() => navigate("/pricing")}>
              Upgrade
            </AuthButton>
          </TrialEndingBlock>
        )}
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
