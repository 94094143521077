import successPlanImage from "../assets/images/successplan.png";

export const PricingPlans = [
  {
    id: "success",
    name: "Success",
    image: successPlanImage,
    price: 149,
    stripeId: process.env.REACT_APP_STRIPE_STARTER_PRICE_ID,
    description:
      "The most popular plan if you are serious about getting started",
    billingType: "monthly",
    buttonText: "Upgrade",
    subText: "THEN $149 PER MONTH CANCEL ANYTIME",
    features: [
      "Test ad creatives before spending",
      "Design in Canva (Coming 02/01)",
      "Unified customer message inbox",
      "Smart task organization",
      "AI learns your store & products",
      "Unlimited AI support replies",
      "Social account performance stats",
      "Post performance tracking",
      "Save top content for ads",
      "Ad growth step-by-step guide",
      "Revenue growth support team",
    ],
  },
];
