import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import theme from "../../pages/styles/global/theme";

export const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;

  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled(motion.div)`
  display: flex;
  width: 80%;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  gap: 10px;
  margin-top: 10px;
  max-height: 90vh;

  ${({ $isTestAd }) =>
    $isTestAd &&
    css`
      height: fit-content;
      border: 1px solid ${theme.color.gray[300]};
    `}

  ${({ $isBoostModal }) =>
    $isBoostModal &&
    css`
      justify-content: center;
    `}

  ${({ $isTestAd }) =>
    $isTestAd &&
    css`
      width: 100%;
      height: fit-content;
      overflow-y: visible;
      max-height: fit-content;
      overflow: visible;
      position: relative;
    `}
`;

export const ModalLeft = styled.div`
  width: 60%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 95vh;
  background: #fff;
  border-radius: 8px;

  position: relative;
  .button-wrapper {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: auto;
    padding-top: 16px;
  }

  .boost-section {
    display: flex;
    gap: 50px;
    width: 100%;
    margin-bottom: 16px;
  }

  .boost-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .metric-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    margin-bottom: auto;
  }

  ${({ $isTestAd }) =>
    $isTestAd &&
    css`
      height: fit-content;
      height: 100%;
      overflow-y: visible;
      max-height: fit-content;

      .swiper-container {
        width: 100%;
        height: 100%;

        max-width: 500px;
      }
    `}
`;

export const ModalRight = styled.div`
  width: 35%;
  height: fit-content;
  background: ${theme.color.white};
  padding: 24px;

  background: #fff;
  border-radius: 8px;

  ${({ $isTestAd }) =>
    $isTestAd &&
    css`
      width: fit-content;
      height: fit-content;
      min-width: 300px;
      box-sizing: border-box;
    `}
`;

export const ImagePreview = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 20px;
  color: #666;
  font-family: ${theme.font.fontFamilyPrimary};
`;

export const CaptionInput = styled.textarea`
  width: 100%;
  min-height: 175px;
  padding: 24px 16px;
  border-radius: 8px;
  border: 1px solid ${theme.color.gray[300]};
  background: #fff;
  margin-bottom: 16px;
  font-family: ${theme.font.fontFamilyPrimary};
  resize: vertical;
  transition: all 0.2s;

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: ${theme.font.fontFamilyPrimary};
    color: ${theme.color.gray[500]};
  }

  color: ${theme.color.black};
  font-size: 14px;
  font-weight: 500;
`;

export const Button = styled.button`
  display: flex;
  width: 100%;
  height: 37px;
  padding: 4px 52px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family: ${theme.font.fontFamilyPrimary};
  border: none;

  &:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      background: ${theme.color.gray[300]};
      color: ${theme.color.gray[800]};
    `}

  ${({ primary }) =>
    primary &&
    css`
      background: ${theme.color.primary};
      color: white;
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      &:hover {
        filter: none;
        cursor: not-allowed;
      }
    `}
`;

export const DatePickerWrapper = styled.div`
  .MuiTextField-root {
    width: 100%;
    margin-bottom: 16px;
    font-family: ${theme.font.fontFamilyPrimary};

    .MuiOutlinedInput-root {
      border-radius: 4px;
      font-family: ${theme.font.fontFamilyPrimary};

      &:hover fieldset {
        border-color: #0066ff;
      }

      &.Mui-focused fieldset {
        border-color: #0066ff;
      }
    }

    .MuiInputLabel-root {
      font-family: ${theme.font.fontFamilyPrimary};
    }
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 9px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
  box-sizing: border-box;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const SelectItem = styled(motion.div)`
  display: flex;
  width: max-content;
  height: 40px;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${theme.color.gray[300]};
  background: #fff;
  justify-content: space-between;
  cursor: pointer;
  gap: 9px;
  outline: none;

  ${({ selected }) =>
    selected &&
    css`
      border-radius: 8px;
      border: 0.5px solid ${theme.color.success};
      background: ${theme.color.successLight};
    `}

  ${({ $isTestAd }) =>
    $isTestAd &&
    css`
      padding: 10px;
      height: 60px;
      width: 100%;

      .select-item-container {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 24px;
          height: 24px;
        }

        .product-info {
          display: flex;
          flex-direction: column;
          gap: 4px;

          #product-title {
            font-size: 14px;
            font-weight: 500;
            font-family: ${theme.font.fontFamilyPrimary};
          }

          #product-price {
            font-size: 12px;
            font-weight: 400;
            font-family: ${theme.font.fontFamilyPrimary};
            color: ${theme.color.gray[600]};
          }
        }
      }
    `}
    
    .select-platform-name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
  }
`;
export const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${theme.color.gray[400]};
  border-radius: 8px;
  background-color: ${theme.color.white};
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 1rem;

  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
  }
`;

export const PreviewSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 8px;

  max-height: 600px;
  max-width: 600px;

  #preview-image {
    width: 100%;
    height: 100%;
  }

  ${({ $isTestAd }) =>
    $isTestAd &&
    css`
      border: 1px solid ${theme.color.gray[300]};
      width: fit-content;
      max-width: 300px;
      width: 100%;
    `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 1001;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

export const PreviewEditButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.46);
  padding: 4px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditButton = styled(motion.button)`
  padding: 8px 16px;
  background: ${theme.color.primary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: ${theme.font.fontFamilyPrimary};
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    background: ${theme.color.primaryDark};
  }
`;

export const Toggle = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const ToggleInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const ToggleSlider = styled.label`
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: ${({ checked }) =>
    checked ? theme.color.primary : theme.color.gray[400]};
  display: block;
  border-radius: 100px;
  position: relative;
  transition: all 0.2s;

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: ${({ checked }) => (checked ? "26px" : "2px")};
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transition: all 0.2s;
  }
`;

export const SliderSection = styled.div`
  width: 90%;
  margin-bottom: 16px;
  transition: all 0.2s;
  box-sizing: border-box;

  .MuiSlider-root {
    color: ${theme.color.primary};
    height: 4px;
    padding: 13px 0;
  }

  .MuiSlider-rail {
    background-color: ${theme.color.gray[300]};
    height: 4px;
  }

  .MuiSlider-track {
    height: 4px;
  }

  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 2px solid currentColor;
    box-shadow: 0 0 0 4px rgba(0, 102, 255, 0.16);
  }

  .MuiSlider-mark {
    width: 3px;
    height: 3px;
    border-radius: 2px;
    background-color: ${theme.color.gray[400]};
  }

  .MuiSlider-markActive {
    background-color: #fff;
  }

  .MuiSlider-markLabel {
    color: ${theme.color.gray[600]};
    font-size: 12px;
    font-weight: 500;
    font-family: ${theme.font.fontFamilyPrimary};
    margin-top: 4px;
  }

  .slider-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .slider-value {
    color: ${theme.color.gray[600]};
    font-size: 14px;
    font-weight: 500;
    min-width: 45px;
    text-align: right;
  }
`;
