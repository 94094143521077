import {
  PostBlockContainer,
  PostBlockImg,
  PostBlockTitle,
  PostBlockButton,
  PostBlockButtonSmall,
  PostBlockDateText,
  PostBlockMoreOptions,
  PostBlockMoreOptionsItem,
  PostBlockBoost,
  LinkPreview,
} from "./styles/PostBlock.styled";
import downloadIcon from "../assets/images/downloadIcon.svg";
import moreOptionsIcon from "../assets/images/more-options-dots.svg";
import editIcon from "../assets/images/edit-icon.svg";
import trashIcon from "../assets/images/trash.svg";
import likesIcon from "../assets/images/mini-heart.svg";
import commentsIcon from "../assets/images/mini-comment.svg";
import sharesIcon from "../assets/images/mini-send.svg";
import impressionsIcon from "../assets/images/eye-mini.svg";
import linkIcon from "../assets/images/link-icon.svg";
import { useState } from "react";
import { itemVariants as fadeInUpVariants } from "../pages/styles/global/framer-motion-variants";
import circlePulse from "../assets/lottie/circle-pulse.json";
import Lottie from "lottie-react";
import axios from "axios";
import { showError, showSuccess } from "../utils/toast";
import BoostModal from "./BoostModal";

const PostBlock = (props) => {
  const {
    post,
    index,
    onEdit,
    onDelete,
    onPost,
    isPost,
    setOpenTestAdModal,
    setSelectedPostId,
  } = props;
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isSeeResultOpen, setIsSeeResultOpen] = useState(false);
  const [isBoostModalOpen, setIsBoostModalOpen] = useState(false);
  const [days, setDays] = useState(7);
  const [budget, setBudget] = useState(100);
  console.log(post);

  const getDisplayDate = () => {
    if (post.status === "scheduled" && post.scheduledDate) {
      return new Date(
        post.scheduledDate._seconds
          ? post.scheduledDate._seconds * 1000
          : post.scheduledDate
      );
    }

    // Use createdAt for published posts
    return new Date(
      post.createdAt._seconds ? post.createdAt._seconds * 1000 : post.createdAt
    );
  };

  const displayDate = getDisplayDate();

  const currentDate = new Date();

  const formatTimeLeft = (endDate) => {
    const timeLeft = new Date(endDate) - currentDate;
    if (timeLeft <= 0) return "0h 0m";

    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  console.log("Parsed scheduledDate:", displayDate);
  console.log("PROPS", props);
  console.log(
    "isBoosted",
    post.boost && new Date(post.boost.endDate) > currentDate
  );
  console.log(
    "isDisabled",
    post.boost && new Date(post.boost.endDate) > currentDate
  );

  const handleDownload = async () => {
    try {
      const imageUrl = post.imageUrl || post.mediaUrl;
      if (!imageUrl) {
        console.error("No image URL found");
        return;
      }

      // Get the image through our backend API
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ url: imageUrl }),
        }
      );

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      const fileName = `post-${index + 1}${getFileExtension(imageUrl)}`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  // Helper function to get file extension from URL
  const getFileExtension = (url) => {
    const extension = url.split(/[#?]/)[0].split(".").pop().trim();
    return extension ? `.${extension}` : ".jpg";
  };

  const isBoosted = post.boost && new Date(post.boost.endDate) > currentDate;

  const onSeeResult = (postId) => {
    console.log("onSeeResult called with postId:", postId);
    setSelectedPostId(postId);
    setOpenTestAdModal(true);
  };

  const renderMedia = () => {
    const mediaUrl = post.imageUrl || post.mediaUrl;

    // If no media URL at all
    if (!mediaUrl) {
      return (
        <LinkPreview onClick={() => window.open(post.permalinkUrl, "_blank")}>
          <div>
            <img src={linkIcon} alt="Link post" />
          </div>
          <h2>Post does not contain image</h2>
        </LinkPreview>
      );
    }

    // Check if it's a video
    const isVideo =
      /\.(mp4|mov|wmv|avi|mkv)$/i.test(mediaUrl) ||
      post.mediaType === "VIDEO" ||
      mediaUrl.includes("video");

    if (isVideo) {
      return (
        <div
          onClick={() => window.open(post.permalinkUrl || mediaUrl, "_blank")}
          style={{
            cursor: "pointer",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
            color: "#fff",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              fontSize: "32px",
              opacity: 0.8,
            }}
          >
            ▶️
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              fontSize: "12px",
            }}
          >
            Click to watch video
          </div>
        </div>
      );
    }

    // For regular images
    return (
      <img
        src={mediaUrl}
        alt={post.title || "Post media"}
        onClick={() => window.open(post.permalinkUrl || mediaUrl, "_blank")}
        style={{
          cursor: "pointer",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0iI2YwZjBmMCIvPjx0ZXh0IHg9IjUwIiB5PSI1MCIgZm9udC1mYW1pbHk9IkFyaWFsIiBmb250LXNpemU9IjE0IiBmaWxsPSIjOTk5IiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBkeT0iLjNlbSI+TWVkaWEgbm90IGF2YWlsYWJsZTwvdGV4dD48L3N2Zz4=";
        }}
      />
    );
  };

  const handleBoostSubmit = async (boostData) => {
    try {
      // Create the test ad using the permanent imageUrl instead of mediaUrl
      const boostResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post/boost-existing`,
        {
          postId: post.id,
          boostDays: days,
          boostBudget: budget,
          imageUrl: post.imageUrl || post.mediaUrl, // Try permanent URL first, fall back to mediaUrl
          adAccountId: boostData.adAccountId,
          productUrl: boostData.productUrl, // Add productUrl to the request
          product: boostData.product,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!boostResponse.data.success) {
        throw new Error("Failed to create test ad");
      }

      showSuccess("Test ad created successfully!");
      setIsBoostModalOpen(false);

      // Update the post in the parent component
      if (props.onPostUpdate) {
        props.onPostUpdate(post.id);
      }
    } catch (error) {
      console.error("Error creating test ad:", error);
      showError("Failed to create test ad. Please try again.");
    }
  };

  return (
    <>
      <PostBlockContainer variants={fadeInUpVariants} $isBoosted={isBoosted}>
        <PostBlockImg>{renderMedia()}</PostBlockImg>
        <div className="post-block-title-boost">
          <PostBlockTitle>Post {index + 1}</PostBlockTitle>
          {isBoosted && (
            <div className="post-block-title-boost-right">
              <Lottie
                animationData={circlePulse}
                loop={true}
                autoplay={true}
                style={{ width: 20, height: 20 }}
              />
              <PostBlockBoost>Testing</PostBlockBoost>
            </div>
          )}
        </div>
        <PostBlockDateText>
          {displayDate.toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </PostBlockDateText>
        {isPost && (
          <div className="post-block-stats">
            <div className="post-block-stats-item">
              <img src={likesIcon} alt="likes" />
              {post.likes}
            </div>
            <div className="post-block-stats-item">
              <img src={commentsIcon} alt="comments" />
              {post.comments}
            </div>
            <div className="post-block-stats-item">
              <img src={sharesIcon} alt="shares" />
              {post.shares}
            </div>
            <div className="post-block-stats-item">
              <img src={impressionsIcon} alt="impressions" />
              {post.impressions}
            </div>
          </div>
        )}
        <div className="post-block-buttons">
          <PostBlockButton
            onClick={() => {
              if (isPost) {
                if (post.boost && new Date(post.boost.endDate) < currentDate) {
                  onSeeResult(post.id);
                } else if (!post.boost) {
                  setIsBoostModalOpen(true);
                }
              } else {
                onPost(post.id);
              }
            }}
            $isDisabled={isBoosted}
            $isBoosted={isBoosted}
            $isPost={isPost}
          >
            {isPost
              ? post.boost
                ? new Date(post.boost.endDate) < currentDate
                  ? "See Results"
                  : `${formatTimeLeft(post.boost.endDate)}`
                : "Test Ad"
              : "Post"}
          </PostBlockButton>
          <PostBlockButtonSmall onClick={handleDownload}>
            <img src={downloadIcon} alt="download" />
          </PostBlockButtonSmall>
          {!isPost && (
            <PostBlockButtonSmall
              onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
            >
              <img src={moreOptionsIcon} alt="more options" />
            </PostBlockButtonSmall>
          )}
        </div>

        {isMoreOptionsOpen && (
          <PostBlockMoreOptions>
            <PostBlockMoreOptionsItem>
              <img src={trashIcon} alt="delete" />
              Delete
            </PostBlockMoreOptionsItem>
          </PostBlockMoreOptions>
        )}
      </PostBlockContainer>

      <BoostModal
        open={isBoostModalOpen}
        onClose={() => setIsBoostModalOpen(false)}
        onSubmit={handleBoostSubmit}
        days={days}
        setDays={setDays}
        budget={budget}
        setBudget={setBudget}
        post={post}
      />
    </>
  );
};

export default PostBlock;
