import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { itemVariants as fadeInUpVariants } from "../pages/styles/global/framer-motion-variants";
import {
  ModalOverlay,
  ModalContent,
  ModalLeft,
  CloseButton,
  Button,
  SliderSection,
  SelectWrapper,
  SelectItem,
  ModalRight,
} from "./styles/PostModal.styled";
import { H2, H4 } from "../pages/styles/global/main.style";
import { Slider } from "@mui/material";
import { motion } from "framer-motion";
import {
  boostSectionVariants,
  boostSliderVariants,
  postModalSelectItemVariants,
  postModalCheckIconVariants,
} from "../pages/styles/global/framer-motion-variants";
import { useSelector } from "react-redux";
import axios from "axios";
import plusIcon from "../assets/images/plus-circle-icon.svg";
import plusIconGreen from "../assets/images/plus-circle-icon-green.svg";
import {
  ProductSelect,
  SearchInput,
  SelectedProductContainer,
} from "./styles/TestAdModal.styled";
import editIcon from "../assets/images/edit-icon.svg";
import { showError } from "../utils/toast";
import { Input } from "./styles/ChatBot.styled";
import Lottie from "lottie-react";
import processingAnimation from "../assets/lottie/processing.json";

const BoostModal = ({
  open,
  onClose,
  onSubmit,
  days,
  setDays,
  budget,
  setBudget,
}) => {
  const userDocument = useSelector((state) => state.user.userDocument);
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productUrl, setProductUrl] = useState("");
  const [isBoosting, setIsBoosting] = useState(false);

  // Get ad accounts from userDocument
  const adAccounts = userDocument?.facebookAdAccounts || [];

  // Add product fetching
  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/products`,
        {
          params: {
            shop: userDocument.shopifyShop,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open && userDocument?.shopifyShop) {
      fetchProducts();
    }
  }, [open, userDocument]);

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const parseHtmlContent = (html) => {
    if (!html) return "";
    const temp = document.createElement("div");
    temp.innerHTML = html;
    const text = temp.textContent || temp.innerText;
    return text
      .replace(/\s+/g, " ")
      .trim()
      .split(/\n+/)
      .filter((line) => line.trim())
      .join("\n");
  };

  const handleSubmit = () => {
    setIsBoosting(true);
    if (!selectedAdAccount) {
      showError("Please select an ad account");
      return;
    }
    const submitData = {
      days,
      budget,
      adAccountId: selectedAdAccount,
      product: selectedProduct,
      productUrl: productUrl,
    };
    console.log("Full submit data:", submitData);
    onSubmit(submitData);
    setIsBoosting(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <ModalOverlay
          variants={fadeInUpVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={onClose}
        >
          <ModalContent onClick={(e) => e.stopPropagation()} $isBoostModal>
            <ModalLeft>
              <CloseButton onClick={onClose}>×</CloseButton>

              {/* Add Product Selection */}
              <H2>Select Product</H2>
              {!selectedProduct ? (
                <>
                  <SearchInput
                    type="text"
                    placeholder="Search products..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  <ProductSelect>
                    {filteredProducts.map((product) => (
                      <SelectItem
                        $isTestAd
                        variants={postModalSelectItemVariants}
                        initial="hidden"
                        whileHover="hover"
                        whileTap="tap"
                        animate={{
                          backgroundColor:
                            selectedProduct?.id === product.id
                              ? "#E8F5E9"
                              : "#ffffff",
                          transition: { duration: 0.2 },
                        }}
                        selected={selectedProduct?.id === product.id}
                        key={product.id}
                        onClick={() => setSelectedProduct(product)}
                      >
                        <div className="select-item-container">
                          <img src={product.image.src} alt={product.title} />
                          <div className="product-info">
                            <span id="product-title">{product.title}</span>
                            <span id="product-price">
                              ${product.variants[0]?.price}
                            </span>
                          </div>
                        </div>

                        <motion.img
                          variants={postModalCheckIconVariants}
                          initial="hidden"
                          animate="visible"
                          custom={selectedProduct?.id === product.id}
                          src={
                            selectedProduct?.id === product.id
                              ? plusIconGreen
                              : plusIcon
                          }
                          alt="Check"
                        />
                      </SelectItem>
                    ))}
                    {filteredProducts.length === 0 && (
                      <SelectItem>No products found</SelectItem>
                    )}
                  </ProductSelect>
                </>
              ) : (
                <SelectedProductContainer>
                  <img
                    id="product-image"
                    src={selectedProduct?.image?.src}
                    alt={selectedProduct?.title}
                  />
                  <div className="product-title-price">
                    <span id="product-title">{selectedProduct?.title}</span>
                    <span id="product-price">
                      ${selectedProduct?.variants[0]?.price}
                    </span>
                  </div>
                  <span id="product-description">
                    {parseHtmlContent(selectedProduct?.body_html)}
                  </span>
                  {selectedProduct && (
                    <Button
                      id="switch-product-button"
                      secondary
                      onClick={() => setSelectedProduct(null)}
                    >
                      <img src={editIcon} alt="Switch Product" /> Switch Product
                    </Button>
                  )}
                </SelectedProductContainer>
              )}

              <motion.div
                variants={boostSectionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="boost-section"
              >
                <motion.div
                  variants={boostSliderVariants}
                  className="boost-slider"
                >
                  <SliderSection>
                    <div className="slider-label">
                      <H4>Days</H4>
                      <span className="slider-value">{days}</span>
                    </div>
                    <Slider
                      value={days}
                      onChange={(_, value) => setDays(value)}
                      min={0}
                      max={14}
                      marks={[
                        { value: 0, label: "0" },
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" },
                        { value: 6, label: "6" },
                        { value: 7, label: "7" },
                        { value: 8, label: "8" },
                        { value: 9, label: "9" },
                        { value: 10, label: "10" },
                        { value: 11, label: "11" },
                        { value: 12, label: "12" },
                        { value: 13, label: "13" },
                        { value: 14, label: "14" },
                      ]}
                      valueLabelDisplay="on"
                    />
                  </SliderSection>
                </motion.div>

                <motion.div
                  variants={boostSliderVariants}
                  className="boost-slider"
                >
                  <SliderSection>
                    <div className="slider-label">
                      <H4>Budget</H4>
                      <span className="slider-value">${budget}</span>
                    </div>
                    <Slider
                      value={budget}
                      onChange={(_, value) => setBudget(value)}
                      min={0}
                      max={150}
                      step={1}
                      marks={[
                        { value: 0, label: "$0" },
                        { value: 50, label: "$50" },
                        { value: 100, label: "$100" },
                        { value: 150, label: "$150" },
                      ]}
                      valueLabelDisplay="off"
                    />
                  </SliderSection>
                </motion.div>
              </motion.div>

              <div className="button-wrapper">
                <Button
                  primary
                  onClick={handleSubmit}
                  disabled={!selectedProduct || !days || !budget || isBoosting}
                >
                  {isBoosting ? (
                    <Lottie
                      animationData={processingAnimation}
                      loop={true}
                      style={{
                        width: 40,
                        height: 40,
                      }}
                    />
                  ) : (
                    "Start Test Ad"
                  )}
                </Button>
              </div>
            </ModalLeft>
            <ModalRight>
              {" "}
              {/* Add Product URL Input */}
              <H2>Product URL (Optional)</H2>
              <Input
                type="url"
                placeholder="Enter product URL"
                value={productUrl}
                onChange={(e) => setProductUrl(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
              {/* Add Ad Account Selection */}
              <H2>Select Ad Account</H2>
              <SelectWrapper>
                {adAccounts.map((account) => (
                  <SelectItem
                    variants={postModalSelectItemVariants}
                    initial="hidden"
                    whileHover="hover"
                    whileTap="tap"
                    animate={{
                      backgroundColor:
                        selectedAdAccount === account.account_id
                          ? "#E8F5E9"
                          : "#ffffff",
                      transition: { duration: 0.2 },
                    }}
                    selected={selectedAdAccount === account.account_id}
                    key={account.id}
                    onClick={() => setSelectedAdAccount(account.account_id)}
                  >
                    <span>{account.name}</span>

                    <motion.img
                      variants={postModalCheckIconVariants}
                      initial="hidden"
                      animate="visible"
                      custom={selectedAdAccount === account.account_id}
                      src={
                        selectedAdAccount === account.account_id
                          ? plusIconGreen
                          : plusIcon
                      }
                      alt="Check"
                    />
                  </SelectItem>
                ))}
              </SelectWrapper>
            </ModalRight>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default BoostModal;
