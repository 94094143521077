import React from "react";
import {
  AuthInput,
  AuthButton,
  ErrorMessage,
} from "../../pages/styles/Auth.styled";

// Component for connecting Shopify store in registration
const ConnectShopifyStep = ({
  shopUrl,
  setShopUrl,
  isLoading,
  error,
  success,
  handleSubmit,
}) => {
  return (
    <>
      <div className="form-input">
        <p>Shopify Store URL</p>
        <AuthInput
          type="text"
          placeholder="Enter Shopify store URL"
          value={shopUrl}
          onChange={(e) => setShopUrl(e.target.value)}
          required
        />
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <AuthButton
        type="submit"
        disabled={isLoading || !shopUrl}
        onClick={handleSubmit}
      >
        {isLoading ? "Processing..." : "Connect Store"}
      </AuthButton>
    </>
  );
};

export default ConnectShopifyStep;
