import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const ProtectedRoute = ({ children }) => {
  const [userState, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const db = getFirestore();

      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        setUserState(userDoc.data());
      }
      setLoading(false);
    };

    fetchUserData();
  }, [location.pathname]);

  if (loading) {
    return null; // Or a loading spinner
  }

  // Public routes that don't require authentication
  const publicRoutes = ["/login", "/register", "/shopify"];
  if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
    // Preserve the current URL as state when redirecting to login
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Handle authenticated routes
  if (isAuthenticated && userState) {
    const signupStep = userState.signupStep;
    const currentPath = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    const stepParam = urlParams.get("step");
    const shopifyParam = urlParams.get("shopify");

    // If on stats page during registration
    if (currentPath === "/social-manager/stats" && signupStep !== "done") {
      // If no step param in URL, add it based on user's current step
      if (!stepParam && signupStep !== "done") {
        const newUrl = `/social-manager/stats?step=${signupStep || 1}`;
        // Preserve shopify error if it exists
        if (shopifyParam) {
          return <Navigate to={`${newUrl}&shopify=${shopifyParam}`} />;
        }
        return <Navigate to={newUrl} />;
      }
      return children;
    }

    // If signup isn't complete and trying to access other pages, redirect to stats with step
    if (signupStep !== "done" && currentPath !== "/social-manager/stats") {
      const newUrl = `/social-manager/stats?step=${signupStep || 1}`;
      // Preserve shopify error if it exists
      if (shopifyParam) {
        return <Navigate to={`${newUrl}&shopify=${shopifyParam}`} />;
      }
      return <Navigate to={newUrl} />;
    }
  }

  return children;
};

export default ProtectedRoute;
