import { AnimatePresence } from "framer-motion";
import { itemVariants as fadeInUpVariants } from "../pages/styles/global/framer-motion-variants";
import {
  ModalOverlay,
  ModalContent,
  ModalLeft,
  CloseButton,
  SelectWrapper,
  SelectItem,
  ModalRight,
  Button,
} from "./styles/PostModal.styled";
import { H2, H4 } from "../pages/styles/global/main.style";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";
import plusIcon from "../assets/images/plus-circle-icon.svg";
import plusIconGreen from "../assets/images/plus-circle-icon-green.svg";
import {
  postModalSelectItemVariants,
  postModalCheckIconVariants,
} from "../pages/styles/global/framer-motion-variants";
import {
  ProductSelect,
  SearchInput,
  SelectedProductContainer,
} from "./styles/TestAdModal.styled";
import editIcon from "../assets/images/edit-icon.svg";
import processingAnimation from "../assets/lottie/processing.json";
import Lottie from "lottie-react";
import TestAdMetric from "./TestAdMetric";
import jsPDF from "jspdf";
import { Input } from "./styles/ChatBot.styled";

const parseHtmlContent = (html) => {
  if (!html) return "";

  // Create a temporary div to parse HTML
  const temp = document.createElement("div");
  temp.innerHTML = html;

  // Get text content and preserve line breaks for paragraphs and breaks
  const text = temp.textContent || temp.innerText;

  // Clean up extra whitespace while preserving intentional line breaks
  return text
    .replace(/\s+/g, " ")
    .trim()
    .split(/\n+/)
    .filter((line) => line.trim())
    .join("\n");
};

const TestAdModal = ({ open, postId, setOpenTestAdModal }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [post, setPost] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(post?.product || null);
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customProfit, setCustomProfit] = useState("");
  const [customCvr, setCustomCvr] = useState("");
  const userDocument = useSelector((state) => state.user.userDocument);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  let metricArray = [
    {
      metric: "CTR",
      rawValue: post?.metrics?.ctrTest ? parseFloat(post.metrics.ctrTest) : 0,
      value: post?.metrics?.ctrTest
        ? `${parseFloat(post.metrics.ctrTest).toFixed(2)}%`
        : "0%",
      goodThreshold: 2.5, // Above 2.5% CTR is good
      badThreshold: 1.0, // Below 1% CTR is bad
    },
    {
      metric: "CPC",
      rawValue: post?.metrics?.cpcTest ? parseFloat(post.metrics.cpcTest) : 0,
      value: post?.metrics?.cpcTest
        ? `$${parseFloat(post.metrics.cpcTest).toFixed(2)}`
        : "$0",
      goodThreshold: 0.5, // Below $0.50 CPC is good
      badThreshold: 1.5, // Above $1.50 CPC is bad,
      isInverse: true, // Lower is better
    },
    {
      metric: "Impressions",
      rawValue: post?.metrics?.impressions
        ? parseInt(post.metrics.impressions)
        : 0,
      value: post?.metrics?.impressions
        ? parseInt(post.metrics.impressions).toLocaleString()
        : "0",
      goodThreshold: 1000, // Above 1000 impressions is good
      badThreshold: 100, // Below 100 impressions is bad
    },
    {
      metric: "Reach",
      rawValue: post?.metrics?.reach ? parseInt(post.metrics.reach) : 0,
      value: post?.metrics?.reach
        ? parseInt(post.metrics.reach).toLocaleString()
        : "0",
      goodThreshold: 800, // Above 800 reach is good
      badThreshold: 100, // Below 100 reach is bad
    },
    {
      metric: "Minimum CVR",
      rawValue: post?.metrics?.minimumConversionRate
        ? parseFloat(post.metrics.minimumConversionRate)
        : 0,
      value: post?.metrics?.minimumConversionRate
        ? `${parseFloat(post.metrics.minimumConversionRate).toFixed(2)}%`
        : "0%",
      goodThreshold: 4, // Below 4% minimum required CVR is good
      badThreshold: 8, // Above 8% minimum required CVR is bad
      isInverse: true, // Lower is better
    },
    {
      metric: "Estimated CPA",
      rawValue: post?.metrics?.estimatedCPA
        ? parseFloat(post.metrics.estimatedCPA)
        : 0,
      value: post?.metrics?.estimatedCPA
        ? `$${parseFloat(post.metrics.estimatedCPA).toFixed(2)}`
        : "$0",
      goodThreshold: 20, // Below $20 CPA is good
      badThreshold: 40, // Above $40 CPA is bad,
      isInverse: true, // Lower is better
    },
  ];

  useEffect(() => {
    if (postId) {
      getPost();
    }
  }, [postId]);

  const getPost = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post-data/post/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const postData = response?.data;
      setPost(postData);
    } catch (error) {
      console.error("Error fetching post:", error);
    }
  };

  const runAnalysis = async () => {
    setIsAnalyzing(true);
    if (!post.product) return;

    setIsLoading(true);
    try {
      const testResults = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post-data/test-results`,
        {
          post: post,
          customInputs: {
            profit: parseFloat(customProfit),
            cvr: parseFloat(customCvr) / 100, // Convert percentage to decimal
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Update post with new metrics and AI analysis
      const updatedPost = {
        ...post,
        metrics: testResults.data.results,
        aiAnalysis: testResults.data.aiAnalysis,
      };
      setPost(updatedPost);
      setMetrics(testResults.data);
    } catch (error) {
      console.error("Error fetching test results:", error);
    } finally {
      setIsLoading(false);
      setIsAnalyzing(false);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/products`,
        {
          params: {
            shop: userDocument.shopifyShop,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Shopify products:", response.data);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (open && userDocument?.shopifyShop) {
      fetchProducts();
    }
  }, [open, userDocument]);

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const onSelectProduct = async (product) => {
    setIsLoading(true);
    //update state
    setSelectedProduct(product);
    //set selected product in post document
    const updatePost = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/social-manager/post-data/update-post-product`,
      {
        postId: post.id,
        product: product,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/social-manager/post-data/test-results`,
      {
        post: post,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    console.log("Response", response?.data);

    setMetrics(response?.data);
    setIsLoading(false);
  };

  console.log("Selected Post", post);
  console.log("Metrics", metrics);

  const handleDownloadReport = () => {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(20);
    doc.text("Ad Test Results Report", 20, 20);

    // Product Info
    doc.setFontSize(16);
    doc.text("Product Information", 20, 40);
    doc.setFontSize(12);
    doc.text(`Product: ${post?.product?.title || "N/A"}`, 20, 50);
    doc.text(`Price: $${post?.product?.variants[0]?.price || "0"}`, 20, 60);

    // Metrics Section
    doc.setFontSize(16);
    doc.text("Performance Metrics", 20, 80);
    doc.setFontSize(12);

    let yPos = 90;
    // Use the metrics from the API response
    if (post?.metrics) {
      metricArray.forEach((metric) => {
        const status = metric.isInverse
          ? metric.rawValue < metric.goodThreshold
            ? "Good"
            : metric.rawValue > metric.badThreshold
            ? "Poor"
            : "Average"
          : metric.rawValue > metric.goodThreshold
          ? "Good"
          : metric.rawValue < metric.badThreshold
          ? "Poor"
          : "Average";

        // Format the metric line with proper spacing
        const metricLine = `${metric.metric.padEnd(15)}: ${metric.value.padEnd(
          10
        )} (${status})`;
        doc.text(metricLine, 20, yPos);
        yPos += 10;
      });
    }

    // AI Analysis
    if (post?.aiAnalysis) {
      yPos += 10; // Add extra spacing before AI Analysis
      doc.setFontSize(16);
      doc.text("AI Analysis", 20, yPos);
      doc.setFontSize(12);
      yPos += 10;

      // Recommendation
      doc.text(
        `Decision: ${post.aiAnalysis.recommendation.decision}`,
        20,
        yPos + 10
      );
      doc.text(
        `Confidence: ${post.aiAnalysis.recommendation.confidence}`,
        20,
        yPos + 20
      );
      doc.text(
        `Probability: ${post.aiAnalysis.recommendation.probability}%`,
        20,
        yPos + 30
      );

      // Word wrap the reasoning
      const reasoning = doc.splitTextToSize(
        `Reasoning: ${post.aiAnalysis.recommendation.reasoning}`,
        170 // Slightly narrower to ensure good margins
      );
      doc.text(reasoning, 20, yPos + 40);
    }

    // Add date and page number
    doc.setFontSize(10);
    doc.text(
      `Generated on ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
      20,
      doc.internal.pageSize.height - 10
    );

    // Save the PDF
    doc.save(`ad_test_report_${post?.id || "unknown"}.pdf`);
  };

  return (
    <AnimatePresence>
      <ModalOverlay
        variants={fadeInUpVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={() => setOpenTestAdModal(false)}
      >
        <ModalContent $isTestAd onClick={(e) => e.stopPropagation()}>
          <ModalLeft>
            <CloseButton onClick={() => setOpenTestAdModal(false)}>
              ×
            </CloseButton>

            {isLoading && (
              <Lottie
                style={{ width: 100, height: 100 }}
                animationData={processingAnimation}
                loop={true}
                autoplay={true}
              />
            )}

            <H2>AI Analysis</H2>
            <div className="ai-analysis-container">
              <div className="ai-analysis-text">
                {post?.aiAnalysis?.recommendation?.reasoning}
              </div>
            </div>
            <H2>Test Results</H2>
            <div className="metric-container">
              {!isLoading &&
                metricArray.map((metric) => (
                  <TestAdMetric
                    metric={metric}
                    key={metric.metric}
                    good={
                      metric.isInverse
                        ? metric.rawValue < metric.goodThreshold
                        : metric.rawValue > metric.goodThreshold
                    }
                  />
                ))}
            </div>

            <div
              className="custom-inputs-container"
              style={{ marginBottom: "20px" }}
            >
              <div
                style={{ display: "flex", gap: "15px", marginBottom: "15px" }}
              >
                <div style={{ flex: 1 }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Product Profit ($)
                  </label>
                  <Input
                    type="number"
                    value={customProfit}
                    onChange={(e) => setCustomProfit(e.target.value)}
                    placeholder="Enter profit"
                    style={{ padding: "10px" }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Conversion Rate (%)
                  </label>
                  <div style={{ position: "relative" }}>
                    <Input
                      type="number"
                      value={customCvr}
                      onChange={(e) => setCustomCvr(e.target.value)}
                      placeholder="Enter CVR (e.g. 2 for 2%)"
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>
              </div>
              <Button
                primary
                onClick={runAnalysis}
                disabled={!customProfit || !customCvr || isAnalyzing}
              >
                {isAnalyzing ? (
                  <Lottie
                    animationData={processingAnimation}
                    loop={true}
                    style={{ width: 40, height: 40 }}
                  />
                ) : post?.metrics ? (
                  "Reanalyze"
                ) : (
                  "Analyze"
                )}
              </Button>
            </div>

            {!isLoading && post?.metrics && (
              <Button
                onClick={handleDownloadReport}
                style={{ marginTop: "20px" }}
              >
                Download Report
              </Button>
            )}
          </ModalLeft>
          <ModalRight $isTestAd>
            {post?.product && (
              <SelectedProductContainer>
                <img
                  id="product-image"
                  src={post.product?.image?.src}
                  alt={post.product?.title}
                />
                <div className="product-title-price">
                  <span id="product-title">{post.product?.title}</span>
                  <span id="product-price">
                    ${post.product?.variants[0]?.price}
                  </span>
                </div>
                <span id="product-description">
                  {parseHtmlContent(post.product?.body_html)}
                </span>
              </SelectedProductContainer>
            )}
          </ModalRight>
        </ModalContent>
      </ModalOverlay>
    </AnimatePresence>
  );
};

export default TestAdModal;
