import React from "react";
import { AuthButton, ErrorMessage } from "../../pages/styles/Auth.styled";
import RegisterConnectBlock from "../RegisterConnectBlock";
import instagramLogo from "../../assets/images/ig-logo.png";
import facebookLogo from "../../assets/images/fb-logo.png";
import gmailLogo from "../../assets/images/gmail-logo.png";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/processing.json";

// Component for connecting social channels in registration
const ConnectChannelsStep = ({
  handleConnect,
  loading,
  error,
  handleContinue,
}) => {
  const { userDocument } = useSelector((state) => state.user);
  const connectedChannels = userDocument?.connectedChannels || [];
  const instagramPage = userDocument?.instagramUsername
    ? {
        id: userDocument.instagramBusinessAccountId,
        name: userDocument.instagramUsername,
      }
    : null;

  // Available channels for connection
  const Channels = [
    {
      img_url: facebookLogo,
      name: "Facebook",
      description: "Connect your Facebook page to start sending messages.",
      isOutlined: true,
    },
    {
      img_url: instagramLogo,
      name: "Instagram",
      description: "Connect your Instagram account to start sending messages.",
      isOutlined: false,
    },
    {
      img_url: gmailLogo,
      name: "Gmail",
      description: "Connect your Gmail account to start sending messages.",
      isOutlined: false,
      isComingSoon: true,
    },
  ];

  console.log("USER DOCUMENT", userDocument);

  return (
    <>
      <div className="channels-container">
        {Channels.map((channel) => (
          <RegisterConnectBlock
            key={channel.name}
            {...channel}
            handleConnect={(e) => handleConnect(e, channel.name)}
            isConnected={connectedChannels.includes(channel.name.toLowerCase())}
          />
        ))}
      </div>

      <div className="connected-channels-container">
        {userDocument?.facebookAccessToken &&
          userDocument?.facebookPages?.map((page) => (
            <RegisterConnectBlock
              key={page.id}
              img_url={facebookLogo}
              name={page.name}
              isOutlined={true}
              isConnected={true}
            />
          ))}

        {instagramPage && (
          <RegisterConnectBlock
            key={instagramPage.id}
            img_url={instagramLogo}
            name={instagramPage.name}
            isOutlined={true}
            isConnected={true}
          />
        )}
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      <AuthButton type="submit" disabled={loading} onClick={handleContinue}>
        {loading ? (
          <Lottie
            style={{ width: "40px", height: "40px" }}
            animationData={loadingAnimation}
          />
        ) : (
          "Continue"
        )}
      </AuthButton>
    </>
  );
};

export default ConnectChannelsStep;
