import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import ChatBot from "../../components/ChatBot";
import {
  Section,
  UploadArea,
  UploadIcon,
  UploadText,
  FormGroup,
  Input,
  Toggle,
  ToggleInput,
  ToggleSlider,
  ColorInputGroup,
  ColorInput,
} from "../styles/Settings.styled";

import {
  ScriptContainer,
  CodeContainer,
  Code,
} from "../../components/styles/ScriptSection.styled";
import { H1, H2, H4, NavButton } from "../styles/global/main.style";
import { MainContainer } from "../styles/global/main.style";
import { useLocation } from "react-router-dom";
import uploadIcon from "../../assets/images/upload-icon.svg";
import axios from "axios";
import {
  settingsSectionVariants,
  settingsItemVariants,
  uploadAreaVariants,
} from "../styles/global/framer-motion-variants";
import { useNavigate } from "react-router-dom";

import { showSuccess, showError } from "../../utils/toast";
const Settings = () => {
  const { userDocument } = useSelector((state) => state.user);
  const [chatBotEnabled, setChatBotEnabled] = useState(false);
  const [formData, setFormData] = useState({
    agentName: "",
    brandColor: "#808080",
    avatar: "",
    logoUrl: "",
  });
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch chatbot settings
  useEffect(() => {
    const fetchChatBot = async () => {
      if (!userDocument?.uid) return;

      const chatbotRef = doc(db, "chatbots", userDocument.uid);
      const chatbotDoc = await getDoc(chatbotRef);

      if (chatbotDoc.exists()) {
        const data = chatbotDoc.data();
        setFormData({
          agentName: data.agentName,
          brandColor: data.brandColor,
          avatar: data.avatar,
          logoUrl: data.logoUrl || "",
        });
        setChatBotEnabled(data.enabled);
      } else {
        // Create default chatbot if it doesn't exist
        await setDoc(chatbotRef, {
          userId: userDocument.uid,
          enabled: false,
          agentName: "Sales Assistant",
          avatar: "",
          brandColor: "#808080",
          logoUrl: "",
          welcomeMessage: "Hi! How can I help you today?",
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    };

    fetchChatBot();
  }, [userDocument?.uid]);

  // Handle settings update
  const handleSave = async () => {
    if (!userDocument?.uid) return;

    const chatbotRef = doc(db, "chatbots", userDocument.uid);

    // Create update object excluding undefined values
    const updateData = {
      agentName: formData.agentName || "",
      brandColor: formData.brandColor || "#808080",
      logoUrl: formData.logoUrl || "",
      enabled: chatBotEnabled,
      updatedAt: new Date(),
    };

    // Only include avatar if it exists
    if (formData.avatar) {
      updateData.avatar = formData.avatar;
    }

    await updateDoc(chatbotRef, updateData);
    showSuccess("Settings updated successfully");
  };

  const handleCopyScript = () => {
    const script = `<!-- EcommerceBot Chat Widget -->
<script src="${process.env.REACT_APP_API_URL}/api/chatbot/widget/script/${userDocument?.uid}" async></script>`;

    navigator.clipboard.writeText(script);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleUpload = async (event) => {
    try {
      const file = event.target.files[0];

      if (!file) return;

      if (!file.type.startsWith("image/")) {
        alert("Please upload an image file");
        return;
      }

      if (file.size > 4.75 * 1024 * 1024) {
        alert("File size must be less than 4.75MB");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", userDocument.uid);

      // Include the bucket path and token information
      const storagePath = `chat-images/${userDocument.uid}/logo`;
      formData.append(
        "metadata",
        JSON.stringify({
          contentType: file.type,
          storagePath: storagePath,
          bucketName: "ecommercebot-a076f.firebasestorage.app",
        })
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chatbot/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Construct the Firebase Storage URL with the token
      const fileUrl = `https://firebasestorage.googleapis.com/v0/b/ecommercebot-a076f.firebasestorage.app/o/${encodeURIComponent(
        storagePath
      )}?alt=media&token=${response.data.token}`;

      setFormData((prev) => ({
        ...prev,
        logoUrl: fileUrl,
      }));

      const chatbotRef = doc(db, "chatbots", userDocument.uid);
      await updateDoc(chatbotRef, {
        logoUrl: fileUrl,
        updatedAt: new Date(),
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file");
    }
  };

  return (
    <MainContainer>
      <div id="header">
        <H1>Settings</H1>
        <div id="buttons">
          <NavButton
            onClick={() => navigate("/inbox/training")}
            $isFilled={location.pathname === "/inbox/training"}
          >
            Training
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox/settings")}
            $isFilled={location.pathname === "/inbox/settings"}
          >
            Settings
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox")}
            $isFilled={location.pathname === "/inbox"}
          >
            Inbox
          </NavButton>
        </div>
      </div>
      <div id="sections-container">
        <Section
          variants={settingsSectionVariants}
          initial="hidden"
          animate="visible"
        >
          <H2>Customize</H2>
          <UploadArea initial="hidden" animate="visible">
            <input
              type="file"
              accept="image/*"
              onChange={handleUpload}
              style={{ display: "none" }}
              id="logo-upload"
            />
            <label htmlFor="logo-upload" style={{ cursor: "pointer" }}>
              <UploadIcon>
                {formData.logoUrl ? (
                  <img
                    variants={uploadAreaVariants}
                    src={formData.logoUrl}
                    alt="logo"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img src={uploadIcon} alt="upload" />
                )}
              </UploadIcon>
              <UploadText>
                {formData.logoUrl ? "Change Logo" : "Upload Logo"}
              </UploadText>
              <UploadText $isSmall>Max Size: 4.75MB</UploadText>
            </label>
          </UploadArea>

          <FormGroup variants={settingsItemVariants}>
            <H4>Agent Name</H4>
            <Input
              type="text"
              placeholder="Enter name here"
              value={formData.agentName}
              onChange={(e) =>
                setFormData({ ...formData, agentName: e.target.value })
              }
              whileFocus={{ scale: 1.01 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            />
          </FormGroup>

          <FormGroup variants={settingsItemVariants}>
            <H4>Brand Color</H4>
            <ColorInputGroup>
              {/* <ColorPreview color={formData.brandColor} /> */}
              <ColorInput
                type="color"
                id="brand-color-input-preview"
                value={formData.brandColor}
                onChange={(e) =>
                  setFormData({ ...formData, brandColor: e.target.value })
                }
              />
              <ColorInput
                type="text"
                placeholder="#000000"
                value={formData.brandColor}
                maxLength={7}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || /^#[0-9A-Fa-f]{0,6}$/.test(value)) {
                    setFormData({ ...formData, brandColor: value });
                  }
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value && !/^#[0-9A-Fa-f]{6}$/.test(value)) {
                    setFormData({ ...formData, brandColor: "#000000" });
                  }
                }}
              />
            </ColorInputGroup>
          </FormGroup>

          <Toggle variants={settingsItemVariants}>
            <H4>Turn chat bot On/Off</H4>
            <ToggleInput
              type="checkbox"
              checked={chatBotEnabled}
              onChange={() => setChatBotEnabled(!chatBotEnabled)}
            />
            <ToggleSlider checked={chatBotEnabled} />
          </Toggle>
          <NavButton
            $isFilled
            onClick={handleSave}
            variants={settingsItemVariants}
          >
            Save Changes
          </NavButton>

          <ScriptContainer>
            <H2>Install Chat Widget</H2>
            <H4>
              Copy and paste this code into your Shopify theme&apos;s
              theme.liquid file just before the closing &lt;/head&gt; tag.
            </H4>
            <CodeContainer>
              <Code>
                {`<!-- EcommerceBot Chat Widget -->
<script src="${process.env.REACT_APP_API_URL}/api/chatbot/widget/script/${userDocument?.uid}" async></script>`}
              </Code>
              <NavButton $isCopy onClick={handleCopyScript}>
                {copied ? "Copied!" : "Copy Code"}
              </NavButton>
            </CodeContainer>
            <H4>
              Need help? Follow these steps: 1. Go to your Shopify admin 2.
              Click on &quot;Online Store&quot; &gt; &quot;Themes&quot; 3. Click
              &quot;Actions&quot; &gt; &quot;Edit code&quot; 4. Find
              theme.liquid in the Layout folder 5. Paste the code just before
              &lt;/head&gt; 6. Click &quot;Save&quot;
            </H4>
          </ScriptContainer>
        </Section>

        <ChatBot
          agentName={formData.agentName}
          brandColor={formData.brandColor}
          avatar={formData.avatar}
          enabled={chatBotEnabled}
          logoUrl={formData.logoUrl}
        />
      </div>
    </MainContainer>
  );
};

export default Settings;
