import styled, { css } from "styled-components";
import theme from "../../pages/styles/global/theme";
import { motion } from "framer-motion";

export const PostBlockContainer = styled(motion.div)`
  display: flex;
  width: 256px;
  height: fit-content;
  padding: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  box-sizing: border-box;
  margin-top: 12px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: ${theme.shadow.boxShadow};

  .post-block-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }

  .post-block-stats {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    color: ${theme.color.gray[500]};
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .post-block-stats-item {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .post-block-title-boost {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .post-block-title-boost-right {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  ${(props) =>
    props.$isBoosted &&
    css`
      box-shadow: 0px 0px 10px 0px rgba(3, 133, 255, 0.1);
    `}
`;

export const PostBlockImg = styled.div`
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid ${theme.color.gray[300]};
  }
`;

export const PostBlockTitle = styled.span`
  color: #000;
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 14px;
  font-style: normal;
  line-height: normal;
`;

export const PostBlockBoost = styled.span`
  color: ${theme.color.primary};
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PostBlockDateText = styled.span`
  color: ${theme.color.gray[500]};
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const PostBlockButton = styled.button`
  display: flex;
  width: 152px;
  padding: 4px 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #0385ff;
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    filter: brightness(0.9);
  }

  ${(props) =>
    props.$isBoosted &&
    css`
      padding: 4px 20px;
    `}
  ${(props) =>
    props.$isDisabled &&
    css`
      background: ${theme.color.gray[300]};
      color: ${theme.color.gray[500]};
      cursor: not-allowed;
    `}

  ${(props) =>
    props.$isPost &&
    css`
      width: 100%;
    `}
`;

export const PostBlockButtonSmall = styled(PostBlockButton)`
  display: flex;
  width: 37px;
  height: 37px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #e8eaef;
  border: none;
  box-sizing: border-box;
`;

export const PostBlockMoreOptions = styled.div`
  display: flex;
  width: 150px;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: 5%;
  bottom: 15%;
  border-radius: 8px;
  background: #fff;
  height: fit-content;
  box-sizing: border-box;

  /* Shadow */
  box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
    0px 1px 3px -1px rgba(0, 0, 0, 0.3);
`;

export const PostBlockMoreOptionsItem = styled(PostBlockButton)`
  display: flex;
  width: 100%;
  padding: 4px 10px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  border: none;
  box-sizing: border-box;
  color: ${theme.color.black};
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const LinkPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #f3f4f6;
  cursor: pointer;

  h2 {
    color: ${theme.color.gray[500]};
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
