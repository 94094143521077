import React, { useState, useRef } from "react";
import styled from "styled-components";
import {
  VideoContainer,
  VideoPlayer,
  Controls,
  VolumeControl,
  AuthButton,
} from "../../pages/styles/Auth.styled";
import introVideo from "../../assets/videos/ecommercebotVideo.mp4";

const IntroVideoStep = ({ handleContinue }) => {
  // State for volume control
  const [volume, setVolume] = useState(1);
  const videoRef = useRef(null);

  // Handle volume change
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  return (
    <VideoContainer>
      <VideoPlayer ref={videoRef} autoPlay controls>
        <source src={introVideo} type="video/mp4" />
        Browser does not support video playback.
      </VideoPlayer>
      <AuthButton onClick={handleContinue}>Continue</AuthButton>
    </VideoContainer>
  );
};

export default IntroVideoStep;
