import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthForm, AuthHeader } from "../pages/styles/Auth.styled";
import { H1, H3 } from "../pages/styles/global/main.style";
import { Stepper, Step, StepLabel } from "@mui/material";
import ecomLogo from "../assets/images/ecomBorderRadius8.png";
import arrows from "../assets/images/arrows.svg";
import shopifyLogo from "../assets/images/shopifyBorderRadius8.png";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../services/authService";
import { setUser, setToken, setProfile } from "../store/slices/userSlice";
import CreateAccountStep from "./registration/CreateAccountStep";
import ConnectShopifyStep from "./registration/ConnectShopifyStep";
import ConnectChannelsStep from "./registration/ConnectChannelsStep";
import { setUserDocument } from "../store/slices/userSlice";

import axios from "axios";
import IntroVideoStep from "./registration/IntroVideoStep";

// Main registration modal component that manages the multi-step registration process
const RegisterModal = () => {
  const [storeUrl, setStoreUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [shopUrl, setShopUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDocument = useSelector((state) => state.user.userDocument);

  // State for managing platform connection UI and status
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [loadingPlatform, setLoadingPlatform] = useState(null);
  const [showShopifyInput, setShowShopifyInput] = useState(false);
  const [showReconnectConfirm, setShowReconnectConfirm] = useState(false);
  const [connectedChannels, setConnectedChannels] = useState([]);

  // Handle registration for the first step
  const handleRegister = async () => {
    setError("");
    setLoading(true);

    try {
      const { user, token, profile } = await register(
        formData.email,
        formData.password
      );

      dispatch(setUser(user));
      dispatch(setToken(token));
      if (profile) {
        dispatch(setProfile(profile));
      }

      // First update the step in the backend
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/auth/register/step`,
        { step: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Navigate to stats page with step query param
      navigate("/social-manager/stats?step=1");
    } catch (error) {
      console.error("Registration error:", error);
      setError(error.message || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  // Handle connecting to platforms
  const handleConnect = async (e, platform) => {
    e.preventDefault();
    setSelectedPlatform(platform);
    console.log("Selected platform:", platform);
    const platformLower = platform.toLowerCase();
    setLoadingPlatform(platformLower);

    if (platformLower === "shopify") {
      if (connectedChannels.includes("shopify")) {
        setShowReconnectConfirm(true);
        setLoadingPlatform(null);
        return;
      }
      setShowShopifyInput(true);
      setLoadingPlatform(null);
      return;
    }

    setLoading(true);
    setError("");

    try {
      if (platformLower === "facebook") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/facebook/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "gmail") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/gmail/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "instagram") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/instagram/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.success) {
          const updatedChannels = new Set([
            ...(userDocument.connectedChannels || []),
            "instagram",
          ]);

          dispatch(
            setUserDocument({
              ...userDocument,
              instagramBusinessAccountId: response.data.instagramAccount.id,
              instagramUsername: response.data.instagramAccount.username,
              connectedChannels: Array.from(updatedChannels),
            })
          );
        }
      }
    } catch (error) {
      setError(
        error.response?.data?.error || `Failed to connect to ${platform}`
      );
      console.error(`${platform} connection error:`, error);
    } finally {
      setLoading(false);
      setSelectedPlatform(null);
      setLoadingPlatform(null);
    }
  };

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const shopifyError = params.get("shopify");
      const step = params.get("step");

      if (shopifyError === "already-connected" && step === "1") {
        setError(
          "This Shopify store is already connected to another account. Please use a different store."
        );
        return;
      }

      if (shopifyError === "success") {
        try {
          // First update the step in the backend to move to channels step (2)
          await axios.put(
            `${process.env.REACT_APP_API_URL}/api/auth/register/step`,
            { step: 2 },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          // Get updated user data after Shopify connection
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/auth/me`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          // Update Redux store with new user document
          dispatch(setUserDocument(response.data));

          // Navigate to channels step
          navigate("/social-manager/stats?step=2");
        } catch (error) {
          setError(error.response?.data?.error || "Failed to update user data");
        }
        return;
      }

      if (shopifyError === "error") {
        setError("Failed to connect Shopify store. Please try again.");
        return;
      }
    };

    handleCallback();
  }, [location, navigate, dispatch]);

  const handleShopifyConnect = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      if (!shopUrl) {
        setError("Please enter your Shopify store URL");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/connect`,
        {
          params: { shop: shopUrl },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.authUrl) {
        setSuccess(`Connecting to ${response.data.myshopifyDomain}...`);
        window.location.href = response.data.authUrl;
      }
    } catch (error) {
      setError(error.response?.data?.error || "Failed to connect to Shopify");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle continuing to next step
  const handleContinue = async () => {
    setLoading(true);
    setError("");
    const nextStep =
      Number(new URLSearchParams(location.search).get("step") || 0) + 1;

    try {
      // Update signup step in backend
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/auth/register/step`,
        { step: nextStep },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Fetch latest user data before proceeding
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/me`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Update Redux store with latest user data
      dispatch(setUserDocument(response.data));

      // Navigate to next step
      navigate(`/social-manager/stats?step=${nextStep}`);
    } catch (error) {
      console.error("Error updating signup step:", error);
      setError(error.response?.data?.message || "Failed to update signup step");
    } finally {
      setLoading(false);
    }
  };

  // Get current step from URL query params
  const currentStep = Number(
    new URLSearchParams(location.search).get("step") || 0
  );

  // Render step content based on current step from URL
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <CreateAccountStep
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            error={error}
            handleSubmit={handleRegister}
          />
        );
      case 1:
        return (
          <ConnectShopifyStep
            shopUrl={shopUrl}
            setShopUrl={setShopUrl}
            isLoading={isLoading}
            error={error}
            success={success}
            handleSubmit={handleShopifyConnect}
          />
        );
      case 2:
        return (
          <ConnectChannelsStep
            handleConnect={handleConnect}
            loading={loading}
            error={error}
            handleContinue={handleContinue}
          />
        );
      case 3:
        return <IntroVideoStep handleContinue={handleContinue} />;
      default:
        return (
          <CreateAccountStep
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            error={error}
            handleSubmit={handleRegister}
          />
        );
    }
  };

  const isRegister = !currentStep || currentStep === 0;

  return (
    <AuthForm>
      <AuthHeader $isRegister={isRegister}>
        <Stepper
          id="stepper"
          orientation="horizontal"
          alternativeLabel
          activeStep={currentStep}
        >
          <Step>
            <StepLabel>Create</StepLabel>
          </Step>
          <Step>
            <StepLabel>Sync</StepLabel>
          </Step>
          <Step>
            <StepLabel>Succeed</StepLabel>
          </Step>
        </Stepper>
        {currentStep === 1 && (
          <div className="arrow-container">
            <img src={ecomLogo} alt="EcommerceBot Logo" />
            <img id="arrows" src={arrows} alt="Arrows" />
            <img src={shopifyLogo} alt="Shopify Logo" />
          </div>
        )}
        <H1
          style={{
            fontSize: isRegister ? "32px" : "24px",
            color: isRegister ? "#4B5563" : "#000",
          }}
        >
          {isRegister
            ? "Get Access"
            : currentStep === 1
            ? "Connect to Shopify"
            : currentStep === 2
            ? "Connect Your Channels"
            : currentStep === 3
            ? "Intro Video"
            : "Setup Complete"}
        </H1>
        <H3>
          {isRegister
            ? ""
            : currentStep === 1
            ? "Connect to Shopify to sync your store information."
            : currentStep === 2
            ? "Integrate your favorite platforms"
            : currentStep === 3
            ? "Watch a quick video to learn how to use EcommerceBot"
            : ""}
        </H3>
      </AuthHeader>
      {renderStepContent()}
    </AuthForm>
  );
};

export default RegisterModal;
