import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../pages/styles/global/theme";

// Custom styles for toast notifications
const customStyles = {
  style: {
    fontFamily: theme.font.fontFamilyPrimary,
    fontSize: "14px",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    color: "white",
  },
};

// Toast configuration options with custom styling
const toastConfig = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  ...customStyles,
  className: {
    success: "custom-toast-success",
    error: "custom-toast-error",
    warning: "custom-toast-warning",
    info: "custom-toast-info",
  },
  style: {
    ...customStyles.style,
  },
};

// Custom styles for different toast types
const successStyle = {
  ...toastConfig,
  style: {
    ...customStyles.style,
    background: theme.color.white,
    color: theme.color.success,
  },
};

const errorStyle = {
  ...toastConfig,
  style: {
    ...customStyles.style,
    background: theme.color.white,
    color: theme.color.danger,
  },
};

const warningStyle = {
  ...toastConfig,
  style: {
    ...customStyles.style,
    background: theme.color.white,
    color: theme.color.warning,
  },
};

const infoStyle = {
  ...toastConfig,
  style: {
    ...customStyles.style,
    background: theme.color.white,
    color: theme.color.info,
  },
};

// Toast utility functions with custom styling
export const showSuccess = (message) => toast.success(message, successStyle);
export const showWarning = (message) => toast.warning(message, warningStyle);
export const showError = (message) => toast.error(message, errorStyle);
export const showInfo = (message) => toast.info(message, infoStyle);

// Export the styles for use in ToastContainer configuration
export const toastContainerProps = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "light",
  toastStyle: customStyles.style,
};

// CSS to be included in your app
export const toastCSS = `
  .Toastify__toast-theme--light {
    color: ${theme.color.text};
    background: ${theme.color.white};
  }
  .Toastify__close-button {
    color: ${theme.color.text};
    opacity: 0.7;
  }
  .Toastify__close-button:hover {
    opacity: 1;
  }
  .Toastify__progress-bar {
    background: rgba(0, 0, 0, 0.1);
  }
  .custom-toast-success {
    background: ${theme.color.white} !important;
    .Toastify__toast-icon svg {
      fill: ${theme.color.success};
    }
  }
  .custom-toast-error {
    background: ${theme.color.white} !important;
    .Toastify__toast-icon svg {
      fill: ${theme.color.danger};
    }
  }
  .custom-toast-warning {
    background: ${theme.color.white} !important;
    .Toastify__toast-icon svg {
      fill: ${theme.color.warning};
    }
  }
  .custom-toast-info {
    background: ${theme.color.white} !important;
    .Toastify__toast-icon svg {
      fill: ${theme.color.info};
    }
  }
  .Toastify__toast-icon {
    margin-right: 12px;
  }
`;
