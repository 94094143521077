//frontend

//fetch files with userID
//MAp
//Fetch faqs with userID

//Training percentage display
//Success count
//Failure count
//Total count
//Training percentage
//fast trainer mode toggle

//Store files with userID in fireStorage

import {
  ChatBotContainer,
  FAQandFilesContainer,
  TrainingLayout,
  FAQContainer,
  FilesContainer,
  FAQInput,
  ChatBotHeader,
  FAQItem,
  TrainerToggleWrapper,
} from "../styles/Training.styled";
import cancelIcon from "../../assets/images/cancel-icon.svg";
import {
  H1,
  MainContainer,
  NavButton,
  H2,
  H3,
  H4,
} from "../styles/global/main.style";

import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  ChatInput,
  Input,
  Message,
  MessageBubble,
  MessageTime,
  SendButton,
  MessagesContainer,
  MessageInput,
} from "../styles/Inbox.styled";
import sendIcon from "../../assets/images/send.svg";
import { db } from "../../config/firebase"; // Make sure this import exists
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import { Toggle, ToggleInput, ToggleSlider } from "../styles/Settings.styled";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import processingAnimation from "../../assets/lottie/processing.json";
import Lottie from "lottie-react";
import { motion, AnimatePresence } from "framer-motion";

const Training = () => {
  const [faqs, setFaqs] = useState([]);
  const [files, setFiles] = useState([]);
  const [trainingPercentage, setTrainingPercentage] = useState(0);
  const [question, setQuestion] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [seeAllFAQs, setSeeAllFAQs] = useState(false);
  const [answer, setAnswer] = useState("");
  const [addFAQClicked, setAddFAQClicked] = useState(false);
  const { userDocument } = useSelector((state) => state.user);
  const userId = userDocument?.uid;
  const navigate = useNavigate();
  const location = useLocation();
  const [trainingQueue, setTrainingQueue] = useState([]);
  const [currentTrainingItem, setCurrentTrainingItem] = useState(null);
  const [isTraining, setIsTraining] = useState(false);
  const [showTrainMore, setShowTrainMore] = useState(false);
  const [autoTrainer, setAutoTrainer] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatbotSettings, setChatbotSettings] = useState({
    logoUrl: "",
    agentName: "Fast Legal Bike",
  });
  const chatContainerRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (userId) {
      fetchFaqs();
      fetchFiles();
      // Set up real-time listener for files
      const q = query(
        collection(db, "trainingFiles"),
        where("userId", "==", userId)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const filesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFiles(filesList);
      });

      return () => unsubscribe(); // Cleanup listener
    }
  }, [userId]);

  useEffect(() => {
    const fetchChatbotSettings = async () => {
      if (!userId) return;

      const chatbotRef = doc(db, "chatbots", userId);
      const chatbotDoc = await getDoc(chatbotRef);

      if (chatbotDoc.exists()) {
        const data = chatbotDoc.data();
        setChatbotSettings({
          logoUrl: data.logoUrl || "",
          agentName: data.agentName || "Fast Legal Bike",
        });
      }
    };

    fetchChatbotSettings();
  }, [userId]);

  useEffect(() => {
    if (userId) {
      // Fetch chat history
      const chatHistoryRef = query(
        collection(db, "chatHistory"),
        where("userId", "==", userId),
        where("type", "==", "direct_chat"),
        orderBy("timestamp", "asc"),
        limit(50)
      );

      const unsubscribe = onSnapshot(chatHistoryRef, (snapshot) => {
        const chatMessages = snapshot.docs
          .map((doc) => {
            // Create array with response first, then message
            const data = doc.data();
            return [
              {
                id: doc.id + "-message",
                content: data.message,
                senderId: userDocument.uid,
                timestamp: data.timestamp,
              },
              // Bot response
              {
                id: doc.id + "-response",
                content: data.response,
                senderId: "bot",
                timestamp: data.timestamp,
              },
              // User message
            ];
          })
          .flat(); // Flatten the array of arrays

        setMessages(chatMessages);
      });

      return () => unsubscribe();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const chatbotRef = doc(db, "chatbots", userId);
      const unsubscribe = onSnapshot(chatbotRef, (doc) => {
        if (doc.exists()) {
          const chatbotData = doc.data();
          // If there are FAQs, minimum training percentage should be 20%
          const basePercentage = faqs.length > 0 ? 20 : 0;
          setTrainingPercentage(
            chatbotData.stats?.trainingPercentage || basePercentage
          );
        }
      });

      return () => unsubscribe();
    }
  }, [userId, faqs]);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/faq/get-faqs?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.length === 0) {
        setAutoTrainer(true);
      }
      setFaqs(response.data);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/faq/get-files?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleAddFAQ = async ({ question, answer }) => {
    const faqArray = [
      {
        text: question,
        metadata: {
          question: question,
          answer: answer,
          source: "manual",
          url: window.location.href,
          title: "Manual FAQ",
          userId: userId,
        },
      },
    ];

    try {
      // Add FAQ to Pinecone and update stats
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/faq/process`,
        faqArray,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Update local FAQs state
      setFaqs([
        ...faqs,
        {
          id: response.data.id,
          question: question,
          answer: answer,
        },
      ]);

      setQuestion("");
      setAnswer("");
      setAddFAQClicked(false); // Close the FAQ input form after successful addition
    } catch (error) {
      console.error("Error adding FAQ:", error);
    }
  };

  const handleAutoTrain = async () => {
    try {
      setIsLoadingQuestions(true);
      setMessages([
        {
          id: Date.now(),
          content: "Loading training questions...",
          senderId: "bot",
          timestamp: new Date(),
        },
      ]);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/fast-trainer/questions`,
        {
          params: { userId: userId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length > 0) {
        const questions = response.data;
        setTrainingQueue(questions);
        setIsTraining(true);
        setCurrentTrainingItem(questions[0]);
        setMessages([
          {
            id: Date.now(),
            content: questions[0].question,
            senderId: "bot",
            timestamp: new Date(),
          },
        ]);
      } else {
        // Trigger n8n if no questions found
        triggerN8nFlow();
      }
    } catch (error) {
      console.error("Error starting training:", error);
      setMessages([
        {
          id: Date.now(),
          content: "Failed to load training questions. Please try again.",
          senderId: "bot",
          timestamp: new Date(),
        },
      ]);
      setIsLoadingQuestions(false);
    }
  };

  // Separate function to trigger n8n flow
  const triggerN8nFlow = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/fast-trainer?userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length > 0) {
        setTrainingQueue(response.data);
        setIsTraining(true);
        setCurrentTrainingItem(response.data[0]);
        // Add first question to messages
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now(),
            content: response.data[0].question,
            senderId: "bot",
            timestamp: new Date(),
          },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now(),
            content:
              "Generating training questions...Please try again in 1 minute.",
            senderId: "bot",
            timestamp: new Date(),
          },
        ]);
      }
    } catch (error) {
      console.error("Error triggering n8n flow:", error);
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          content: "Failed to load training questions. Please try again.",
          senderId: "bot",
          timestamp: new Date(),
        },
      ]);
    } finally {
      setIsLoadingQuestions(false);
    }
  };

  const handleSendMessage = async () => {
    if (!messageInput.trim()) return;
    setMessageInput("");
    setIsProcessing(true);

    try {
      // Add user's message to chat immediately
      const userMessage = {
        id: Date.now(),
        content: messageInput,
        senderId: userDocument.uid,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, userMessage]);

      if (isTraining && currentTrainingItem) {
        // Existing training mode logic
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/fast-trainer/complete-question`,
          {
            questionId: currentTrainingItem.id,
            answer: messageInput,
            userId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setFaqs([
          ...faqs,
          {
            id: currentTrainingItem.id,
            question: currentTrainingItem.question,
            answer: messageInput,
          },
        ]);

        // Move to next question in queue
        const newQueue = trainingQueue.slice(1);
        setTrainingQueue(newQueue);

        if (newQueue.length > 0) {
          setCurrentTrainingItem(newQueue[0]);
          // Add next question to messages
          setMessages((prev) => [
            ...prev,
            {
              id: Date.now(),
              content: newQueue[0].question,
              senderId: "bot",
              timestamp: new Date(),
            },
          ]);
        } else {
          setIsTraining(false);
          setCurrentTrainingItem(null);
          setShowTrainMore(true); // Show train more button

          setMessages((prev) => [
            ...prev,
            {
              id: Date.now(),
              content:
                "Training complete! Click 'Fast Trainer' to continue training.",
              senderId: "bot",
              timestamp: new Date(),
            },
          ]);
        }
      } else {
        // Regular chat mode
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/fast-trainer/chat`,
          {
            userId: userId,
            message: messageInput,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Add AI response to messages
        const botMessage = {
          id: Date.now(),
          content: response.data.response,
          senderId: "bot",
          timestamp: new Date(),
        };
        setMessages((prev) => [...prev, botMessage]);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage = {
        id: Date.now(),
        content: "Sorry, there was an error processing your message.",
        senderId: "bot",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsProcessing(false);
      setMessageInput("");
    }
  };

  const handleUploadFile = async () => {
    // Create a hidden file input
    setLoading(true);
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".txt,.pdf,.doc,.docx"; // Add accepted file types

    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      try {
        // Create FormData to send file
        const formData = new FormData();
        formData.append("file", file);
        formData.append("userId", userId);

        // Send to backend
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/files/process`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Update files state with new file
        setFiles((prevFiles) => [...prevFiles, response.data]);
        fetchFaqs();
        setLoading(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoading(false);
      }
    };

    // Trigger file selection
    fileInput.click();
  };

  // Update the message rendering to handle both Firestore Timestamps and regular Dates
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return "";

    // If it's a Firestore Timestamp
    if (timestamp.toDate && typeof timestamp.toDate === "function") {
      return timestamp.toDate().toLocaleTimeString();
    }

    // If it's a JavaScript Date
    if (timestamp instanceof Date) {
      return timestamp.toLocaleTimeString();
    }

    return "";
  };

  useEffect(() => {
    const messagesContainer = document.querySelector(".messages-container");
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]); // Scroll whenever messages update

  return (
    <MainContainer>
      <div id="header">
        <H1>Training</H1>
        <div id="buttons">
          <NavButton
            onClick={() => navigate("/inbox/training")}
            $isFilled={location.pathname === "/inbox/training"}
          >
            Training
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox/settings")}
            $isFilled={location.pathname === "/inbox/settings"}
          >
            Settings
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox")}
            $isFilled={location.pathname === "/inbox"}
          >
            Inbox
          </NavButton>
        </div>
      </div>
      <TrainingLayout>
        <FAQandFilesContainer>
          <FAQContainer>
            <H2>({faqs.length}) FAQs trained on: </H2>
            {seeAllFAQs
              ? faqs.map((faq) => (
                  <FAQItem key={faq.id}>
                    <H3 style={{ margin: 0 }}>{faq.question}</H3>
                    <H4 style={{ margin: 0 }}>{faq.answer}</H4>
                  </FAQItem>
                ))
              : faqs.slice(0, 3).map((faq) => (
                  <FAQItem key={faq.id}>
                    <H3 style={{ margin: 0 }}>{faq.question}</H3>
                    <H4 style={{ margin: 0 }}>{faq.answer}</H4>
                  </FAQItem>
                ))}
            {addFAQClicked && (
              <>
                <FAQInput
                  type="text"
                  value={question}
                  required
                  placeholder="Question"
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <FAQInput
                  type="text"
                  value={answer}
                  required
                  placeholder="Answer"
                  onChange={(e) => setAnswer(e.target.value)}
                />
              </>
            )}
            <div className="add-buttons">
              <NavButton
                $isFilled={true}
                onClick={() => {
                  setAddFAQClicked(!addFAQClicked);
                  addFAQClicked &&
                    answer &&
                    question &&
                    handleAddFAQ({ question, answer });
                }}
              >
                {addFAQClicked ? "Save FAQ" : "Add FAQ"}
              </NavButton>
              {addFAQClicked && (
                <NavButton onClick={() => setAddFAQClicked(false)}>
                  <img src={cancelIcon} />
                </NavButton>
              )}
              <NavButton
                onClick={() => {
                  setSeeAllFAQs(!seeAllFAQs);
                }}
              >
                {seeAllFAQs ? "See less" : "See all FAQs"}
              </NavButton>
            </div>
          </FAQContainer>
          <FilesContainer>
            <H2>Files trained on:</H2>
            <div className="files-list">
              {files.map((file) => (
                <H3>
                  {file.url?.split("/").pop().split("-").slice(1).join("-")}
                </H3>
              ))}
            </div>
            <div className="add-buttons">
              <NavButton $isFilled={true} onClick={handleUploadFile}>
                {loading ? "Processing..." : "Add File"}
              </NavButton>
            </div>
          </FilesContainer>
        </FAQandFilesContainer>
        <div className="auto-trainer-container">
          <div className="auto-trainer-buttons">
            <TrainerToggleWrapper>
              <div className="trainer-toggle">
                <Toggle className="toggle-container">
                  <H3>Fast Trainer</H3>
                  <ToggleInput
                    type="checkbox"
                    checked={isTraining}
                    onChange={() => {
                      if (!isTraining) {
                        // Start training
                        handleAutoTrain();
                        setShowTrainMore(false);
                      } else {
                        // Stop training
                        setIsTraining(false);
                        setCurrentTrainingItem(null);
                        setTrainingQueue([]);
                        setMessages((prev) => [
                          ...prev,
                          {
                            id: Date.now(),
                            content: "Training mode disabled.",
                            senderId: "bot",
                            timestamp: new Date(),
                          },
                        ]);
                      }
                    }}
                  />
                  <ToggleSlider checked={isTraining} />
                </Toggle>
              </div>
            </TrainerToggleWrapper>

            <div id="training-percentage">{trainingPercentage}%</div>
          </div>
          <ChatBotContainer ref={chatContainerRef}>
            <ChatBotHeader>
              {chatbotSettings.logoUrl && (
                <img
                  id="header-logo"
                  src={chatbotSettings.logoUrl}
                  alt="Chat Bot Logo"
                />
              )}
              <H2>{chatbotSettings.agentName}</H2>
            </ChatBotHeader>
            <MessagesContainer className="messages-container">
              <AnimatePresence>
                {messages.map((message) => (
                  <motion.div
                    key={message.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Message $isUser={message.senderId === userDocument.uid}>
                      <MessageBubble
                        $isUser={message.senderId === userDocument.uid}
                      >
                        {message.content}
                      </MessageBubble>
                      <MessageTime>
                        {formatMessageTime(message.timestamp)}
                      </MessageTime>
                    </Message>
                  </motion.div>
                ))}
                {isProcessing && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                  >
                    <Message $isUser={false}>
                      <MessageBubble $isUser={false}>
                        <Lottie
                          animationData={processingAnimation}
                          loop={true}
                          style={{
                            width: 30,
                            height: 30,
                            filter: "brightness(0.7)",
                          }}
                        />
                      </MessageBubble>
                    </Message>
                  </motion.div>
                )}
              </AnimatePresence>
            </MessagesContainer>
            <ChatInput>
              <Input
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                placeholder="Enter your message here..."
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage(e)}
              />
              <SendButton onClick={handleSendMessage}>
                Send <img src={sendIcon} alt="Send Icon" />
              </SendButton>
            </ChatInput>
          </ChatBotContainer>
        </div>
      </TrainingLayout>
    </MainContainer>
  );
};

export default Training;
