import styled from "styled-components";
import theme from "./global/theme";
export const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  text-align: center;
  font-family: ${theme.font.fontFamilyPrimary};
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    margin-top: 10px;
  }
`;

export const PricingHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1024px) {
    width: 80%;
  }

  h1 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  h2 {
    color: var(--EB-Icon, #a3a3a3);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(90deg, #0385ff 34.28%, #025099 66.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
`;

export const PricingHeaderDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PricingCard = styled.div`
  background: #ffffff;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;
  border-radius: 8px;
  border: 0.25px solid ${theme.color.primary};
  background: #fff;
  width: 350px;
  box-sizing: border-box;
  text-align: left;

  #plan-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 16px;
  }

  .plan-header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .plan-name {
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }

    .plan-description {
      color: ${theme.color.gray[500]};
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      width: 75%;
    }
  }

  .features-header {
    color: ${theme.color.gray[700]};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 10px 0;
  }
`;

export const PlanPrice = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;

  gap: 0px;
  width: 100%;
  margin-top: 16px;

  .discount-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    width: 100%;
  }

  .amount {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;

    margin: 0;
  }

  .discount {
    color: ${theme.color.gray[500]};
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;

    text-decoration: line-through;
    margin: 0;
  }

  .period {
    color: var(--eb-dark-sidebar, #525460);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  color: ${theme.color.gray[700]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 100%;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: ${theme.color.primary};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 0;
  width: 100%;
  justify-content: center;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
    transform: ${(props) =>
      props.$isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

export const PricingButton = styled.button`
  width: 100%;
  padding: 12px 24px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.2s ease;
  margin: 24px 0 24px;

  &:hover {
    cursor: pointer;
  }

  border-radius: 9.887px;
  background: linear-gradient(180deg, #0385ff 0%, #006ace 175.7%);
  border: none;
  color: #fff;
`;
