import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  AuthContainer,
  AuthFormContainer,
  AuthForm,
  AuthInput,
  AuthButton,
  ErrorMessage,
  AuthImageContainer,
} from "../styles/Auth.styled";
import loginImage from "../../assets/images/ecombotcircle.png";
import { setUserDocument } from "../../store/slices/userSlice";

const ShopifyLogin = () => {
  const [shopUrl, setShopUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const shopifyError = params.get("shopify");

      if (shopifyError === "already-connected") {
        setError(
          "This Shopify store is already connected to another account. Please use a different store."
        );
        return;
      }

      if (shopifyError === "success") {
        try {
          // Get updated user data after Shopify connection
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/auth/me`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          // Update Redux store with new user document
          dispatch(setUserDocument(response.data));
          navigate("/pricing");
        } catch (error) {
          setError(error.response?.data?.error || "Failed to update user data");
        }
        return;
      }

      if (shopifyError === "error") {
        setError("Failed to connect Shopify store. Please try again.");
        return;
      }
    };

    handleCallback();
  }, [location, navigate, dispatch]);

  const handleConnect = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      if (!shopUrl) {
        setError("Please enter your Shopify store URL");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/connect`,
        {
          params: { shop: shopUrl },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.authUrl) {
        setSuccess(`Connecting to ${response.data.myshopifyDomain}...`);
        window.location.href = response.data.authUrl;
      }
    } catch (error) {
      setError(error.response?.data?.error || "Failed to connect to Shopify");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContainer>
      <AuthFormContainer>
        <AuthForm onSubmit={handleConnect} style={{ maxWidth: "500px" }}>
          <div className="header">
            <h2 style={{ marginBottom: "10px" }}>Connect Shopify Store</h2>
            <h3>Please enter your Shopify store URL</h3>
          </div>

          <div className="form-inputs">
            <div className="form-input">
              <p>Store URL</p>
              <AuthInput
                type="text"
                placeholder="your-store.myshopify.com"
                value={shopUrl}
                onChange={(e) => setShopUrl(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>

            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && <div className="success-message">{success}</div>}
          </div>

          <AuthButton type="submit" disabled={isLoading}>
            {isLoading ? "Connecting..." : "Connect Store"}
          </AuthButton>
        </AuthForm>
      </AuthFormContainer>
      <AuthImageContainer>
        <img src={loginImage} alt="Login" />
      </AuthImageContainer>
    </AuthContainer>
  );
};

export default ShopifyLogin;
