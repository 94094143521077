import { MainContainer, H1 } from "../styles/global/main.style";
import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  CommunityContainer,
  CommunityPostCreateBlock,
  AttachmentPreview,
  FileRemoveButton,
} from "../styles/Community.styled";

import { Button, CaptionInput } from "../../components/styles/PostModal.styled";
import { H3 } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { showError, showSuccess } from "../../utils/toast";
import CommunityPostBlock from "../../components/CommunityPostBlock";
import SkeletonBlock from "../../components/SkeletonBlock";

import paperClipIcon from "../../assets/images/paperclip.svg";
import { CommunityPostBlockHeader } from "../../components/styles/CommunityPostBlock.styled";
import ecommerceBot from "../../assets/images/EcommerceBot-Mascot.png";
import profileIcon from "../../assets/images/profile-user.svg";

import { motion, useScroll, useTransform } from "framer-motion";
import {
  floatingBotVariants,
  listVariants as staggerContainerVariants,
  itemVariants as fadeInUpVariants,
} from "../styles/global/framer-motion-variants";

// Add CommunityPostSkeleton component
const CommunityPostSkeleton = () => (
  <>
    {Array.from({ length: 7 }).map((_, index) => (
      <SkeletonBlock
        key={index}
        type="channel"
        textCount={3}
        valueCount={1}
        width="100%"
      />
    ))}
  </>
);

const Community = () => {
  const userDocument = useSelector((state) => state.user.userDocument);
  const [posts, setPosts] = useState([]);
  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastPostId, setLastPostId] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const observerTarget = useRef(null);
  const { scrollY } = useScroll();

  const getPosts = async (isInitial = false) => {
    try {
      if (!userDocument?.uid) return;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/community/get-posts`,
        {
          params: {
            userId: userDocument.uid,
            lastPostId: isInitial ? null : lastPostId,
            limit: 5,
          },
        }
      );

      const {
        posts: newPosts,
        hasMore: moreAvailable,
        lastPostId: lastId,
      } = response.data;

      if (isInitial) {
        setPosts(newPosts);
      } else {
        setPosts((prev) => [...prev, ...newPosts]);
      }

      setHasMore(moreAvailable);
      setLastPostId(lastId);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    if (userDocument?.uid) {
      getPosts(true);
    }
  }, [userDocument]);

  // Infinite scroll observer
  const lastPostElementRef = useCallback(
    (node) => {
      if (isFetchingMore) return;

      if (observerTarget.current) observerTarget.current.disconnect();

      observerTarget.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setIsFetchingMore(true);
          getPosts().finally(() => setIsFetchingMore(false));
        }
      });

      if (node) observerTarget.current.observe(node);
    },
    [hasMore, isFetchingMore]
  );

  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // 5MB limit
        showError("File size should be less than 5MB");
        return;
      }
      setAttachment(file);
      // Create preview URL for images
      if (file.type.startsWith("image/")) {
        const previewUrl = URL.createObjectURL(file);
        setAttachmentPreview(previewUrl);
      } else {
        setAttachmentPreview(null);
      }
    }
  };

  const handleRemoveAttachment = () => {
    if (attachmentPreview) {
      URL.revokeObjectURL(attachmentPreview);
    }
    setAttachment(null);
    setAttachmentPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCreatePost = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("author", userDocument.uid);
      formData.append("authorUserName", userDocument.userName);
      formData.append("content", content);

      if (attachment) {
        formData.append("attachment", attachment);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/community/create-post`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      showSuccess("Post created successfully");
      setPosts([response.data, ...posts]);
      setContent("");
      setAttachment(null);
      setAttachmentPreview(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error creating post:", error);
      showError(error.response?.data?.message || "Error creating post");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainContainer $isCommunity={true}>
      <div id="header">
        <H1 style={{ fontSize: "40px" }}>EcommerceBot Community</H1>
        <img src={ecommerceBot} alt="ecommercebot" />
      </div>
      <CommunityContainer>
        <motion.div
          id="posts"
          variants={staggerContainerVariants}
          initial="hidden"
          animate="visible"
          style={{ gap: "1.5rem", display: "flex", flexDirection: "column" }}
        >
          {isLoading ? (
            <CommunityPostSkeleton />
          ) : (
            <>
              {posts.map((post, index) => (
                <motion.div
                  key={post.id}
                  ref={index === posts.length - 1 ? lastPostElementRef : null}
                  variants={fadeInUpVariants}
                  layout
                >
                  <CommunityPostBlock
                    post={post}
                    currentUserId={userDocument?.uid}
                  />
                </motion.div>
              ))}
              {isFetchingMore && <CommunityPostSkeleton />}
            </>
          )}
        </motion.div>

        <motion.div
          id="create-post"
          variants={fadeInUpVariants}
          initial="hidden"
          animate="visible"
        >
          {isLoading ? (
            <SkeletonBlock
              type="channel"
              textCount={2}
              valueCount={0}
              width="100%"
            />
          ) : (
            <CommunityPostCreateBlock>
              <CommunityPostBlockHeader>
                <img src={profileIcon} alt="profile" />
                <div className="post-author-date">
                  <span id="post-author">{userDocument?.userName}</span>
                  <span id="post-date">
                    {new Date().toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              </CommunityPostBlockHeader>
              <CaptionInput
                style={{
                  padding: "0",
                  border: "none",
                }}
                placeholder="Share ideas, feedback, or thoughts—help us improve!"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
              {attachment && (
                <AttachmentPreview>
                  <FileRemoveButton onClick={handleRemoveAttachment}>
                    ×
                  </FileRemoveButton>
                  {attachmentPreview && (
                    <img
                      src={attachmentPreview}
                      alt="attachment preview"
                      className="preview-image"
                    />
                  )}
                </AttachmentPreview>
              )}
              <div className="actions">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleAttachmentChange}
                  style={{ display: "none" }}
                  accept="image/*,.pdf,.doc,.docx"
                />
                <Button
                  onClick={() => fileInputRef.current?.click()}
                  type="button"
                  secondary="true"
                >
                  <img src={paperClipIcon} alt="paperclip" /> Attach
                </Button>
                <Button
                  onClick={handleCreatePost}
                  disabled={!content || isLoading}
                  primary={content}
                >
                  {isLoading ? "Posting..." : "Post"}
                </Button>
              </div>
            </CommunityPostCreateBlock>
          )}
          <motion.img
            style={{
              rotate: "10deg",
              zIndex: -1000,
              opacity: 0.5,
            }}
            src={ecommerceBot}
            alt="ecommercebot"
            variants={floatingBotVariants}
            animate="animate"
          />
        </motion.div>
      </CommunityContainer>
    </MainContainer>
  );
};

export default Community;
