import {
  CommunityPostBlockContainer,
  CommunityPostBlockHeader,
  CommunityPostBlockContent,
  CommunityPostBlockFooter,
  CommunityPostBlockInteraction,
} from "./styles/CommunityPostBlock.styled";
import profileIcon from "../assets/images/profile-user.svg";
import { H3 } from "../pages/styles/global/main.style";
import { useState } from "react";
import axios from "axios";

import upvoteIcon from "../assets/images/upvote.svg";
import downvoteIcon from "../assets/images/downvote.svg";
import likeIcon from "../assets/images/like.svg";
import likedIcon from "../assets/images/like-liked.svg";

const CommunityPostBlock = ({ post, currentUserId }) => {
  const [postState, setPostState] = useState(post);
  const [isLoading, setIsLoading] = useState(false);

  // Format the date
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp._seconds
      ? new Date(timestamp._seconds * 1000)
      : new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleInteraction = async (interactionType) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/community/interact`,
        {
          postId: postState.id,
          userId: postState.author,
          interactionType,
        }
      );

      // Update the post state based on the interaction type
      setPostState((prevState) => {
        const newState = { ...prevState };
        const hasInteracted = response.data.hasInteracted;

        // Update interaction state
        const interactionKey =
          interactionType === "downvote"
            ? "hasDownvoted"
            : interactionType === "upvote"
            ? "hasUpvoted"
            : "hasLiked";

        newState.userInteractions = {
          ...prevState.userInteractions,
          [interactionKey]: hasInteracted,
        };

        // Update counts
        const change = hasInteracted ? 1 : -1;
        if (interactionType === "upvote") {
          newState.upvotes = prevState.upvotes + change;
        } else if (interactionType === "downvote") {
          newState.downvotes = prevState.downvotes + change;
        } else if (interactionType === "like") {
          newState.likes = prevState.likes + change;
        }

        console.log(newState);

        return newState;
      });
    } catch (error) {
      console.error(`Error ${interactionType}ing post:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommunityPostBlockContainer>
      <CommunityPostBlockHeader>
        <img src={profileIcon} alt="profile" />
        <div className="post-author-date">
          <span id="post-author">{postState.authorUserName}</span>
          <span id="post-date">{formatDate(postState.createdAt)}</span>
        </div>
      </CommunityPostBlockHeader>
      <CommunityPostBlockContent>
        <H3>{postState.content}</H3>
        {postState.attachmentUrl && (
          <img
            src={postState.attachmentUrl}
            alt="post attachment"
            className="post-image"
          />
        )}
      </CommunityPostBlockContent>
      <CommunityPostBlockFooter>
        <div className="upvote-downvote-interaction">
          <CommunityPostBlockInteraction
            onClick={() => handleInteraction("upvote")}
            active={postState.userInteractions?.hasUpvoted ? "true" : "false"}
            type="upvote"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M15.0586 8.47502L10.0002 3.41669L4.94189 8.47502"
                stroke={
                  postState.userInteractions?.hasUpvoted ? "#0385FF" : "#A3A3A3"
                }
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 17.5834V3.55835"
                stroke={
                  postState.userInteractions?.hasUpvoted ? "#0385FF" : "#A3A3A3"
                }
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <H3
              style={{
                color: postState.userInteractions?.hasUpvoted
                  ? "#0385FF"
                  : "#A3A3A3",
              }}
            >
              {postState.upvotes}
            </H3>
          </CommunityPostBlockInteraction>
          <CommunityPostBlockInteraction
            onClick={() => handleInteraction("downvote")}
            active={postState.userInteractions?.hasDownvoted ? "true" : "false"}
            type="downvote"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M15.0586 12.525L10.0002 17.5834L4.94189 12.525"
                stroke={
                  postState.userInteractions?.hasDownvoted
                    ? "#0385FF"
                    : "#A3A3A3"
                }
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 3.41669V17.4417"
                stroke={
                  postState.userInteractions?.hasDownvoted
                    ? "#0385FF"
                    : "#A3A3A3"
                }
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <H3
              style={{
                color: postState.userInteractions?.hasDownvoted
                  ? "#0385FF"
                  : "#A3A3A3",
              }}
            >
              {postState.downvotes}
            </H3>
          </CommunityPostBlockInteraction>
        </div>
        <div className="like-interaction">
          <CommunityPostBlockInteraction
            onClick={() => handleInteraction("like")}
            active={postState.userInteractions?.hasLiked ? "true" : "false"}
            type="like"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M13.6998 3.08331C12.1915 3.08331 10.8415 3.81665 9.99984 4.94165C9.15817 3.81665 7.80817 3.08331 6.29984 3.08331C3.7415 3.08331 1.6665 5.16665 1.6665 7.74165C1.6665 8.73331 1.82484 9.64998 2.09984 10.5C3.4165 14.6666 7.47484 17.1583 9.48317 17.8416C9.7665 17.9416 10.2332 17.9416 10.5165 17.8416C12.5248 17.1583 16.5832 14.6666 17.8998 10.5C18.1748 9.64998 18.3332 8.73331 18.3332 7.74165C18.3332 5.16665 16.2582 3.08331 13.6998 3.08331Z"
                fill={
                  postState.userInteractions?.hasLiked ? "#0385FF" : "#A3A3A3"
                }
              />
            </svg>
            <H3>{postState.likes}</H3>
          </CommunityPostBlockInteraction>
        </div>
      </CommunityPostBlockFooter>
    </CommunityPostBlockContainer>
  );
};

export default CommunityPostBlock;

//author
//content
//upvotes
//downvotes
//createdAt
//updatedAt
//likes

//TODO comments
